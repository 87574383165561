import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { appointmentModalLang } from "./language";
import { useSelector } from "react-redux";

const languageConfig = {
  orderPlaced: {
    en: "Order placed successfully",
    es: "Pedido realizado con éxito",
    fr: "Commande passée avec succès",
  },
  close: {
    en: "Close",
    es: "Cerrar",
    it: "Vicino",
  },
  goHome: {
    en: "Go Home",
    es: "Ir a casa",
    it: "Vai a casa",
  },
};

const SuccessModal = ({
  isOpen,
  setIsModalOpen = () => {},
  isModalOpen,
  setIsConsentAccepted = () => {},
  setIsPrivecyAccepted = () => {},
  setSelectedTime = () => {},
}) => {
  const navigate = useNavigate();
  const lang = useSelector((state) => state?.languageSlice?.data);
  const language = localStorage.getItem("language") || lang;
  const closeModal = () => {
    setIsModalOpen(false);
    setIsConsentAccepted(false);
    setIsPrivecyAccepted(false);
    setSelectedTime(null);
  };

  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        closeModal();
      }, 20000); // 30 seconds

      return () => clearTimeout(timer); // Cleanup the timer on component unmount
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-40">
      <div className="bg-white rounded-lg p-8 shadow-lg max-w-sm w-full text-center">
        <div className="flex justify-center mb-4">
          <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center">
            <svg
              className="w-10 h-10 text-green-500 animate-checkmark"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 13l4 4L19 7"
              ></path>
            </svg>
          </div>
        </div>
        <h2 className="text-2xl font-semibold mb-4">
          {appointmentModalLang?.title6?.[language]}
        </h2>
        <p className="text-gray-600 mb-6">
          {languageConfig?.orderPlaced?.[language]}
        </p>
        <div className="flex justify-center gap-4">
          <button
            onClick={closeModal}
            className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
          >
            {languageConfig?.close?.[language]}
          </button>
          <button
            onClick={() => navigate("/")} // Navigate to home page
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            {languageConfig?.goHome?.[language]}
          </button>
        </div>
      </div>
      <style jsx>{`
        .animate-checkmark {
          animation: checkmark-animation 1s ease-in-out forwards;
        }
        @keyframes checkmark-animation {
          0% {
            stroke-dashoffset: 70;
          }
          100% {
            stroke-dashoffset: 0;
          }
        }
      `}</style>
    </div>
  );
};

export default SuccessModal;
