import React, { useEffect, useState } from "react";
import { classNames } from "../../helpers/classNames";
import { useDispatch, useSelector } from "react-redux";
import { setTabSelection } from "../../store/slice/selectedTabSlice";
import Modal from "./Modal";
import Button from "../form/Button";

const TabsForProfile = ({
  tabs,
  design,
  divClass,
  mainClasses,
  tabContentdivClasses,
  buttonClass,
  textClasses,
  loaded = true,
  ...props
}) => {
  const [selectedTab, setSelectedTab] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const dispatch = useDispatch();
  const selected = useSelector((state) => state?.selectedTabSlice?.data);
  const profileCheck = useSelector(
    (state) => state?.profileSlice?.data?.completeProfile
  );
  const user = JSON.parse(localStorage.getItem("userDetails"));

  useEffect(() => {
    if (user && user.roleCode === "user") {
      if (tabs && tabs.length > 0 && Object.keys(selected).length === 0) {
        const { isPersonalComplete, isBillingComplete, isLanguageComplete } =
          profileCheck || {};

        if (!isPersonalComplete) {
          setSelectedTab(tabs[0]);
          dispatch(setTabSelection(tabs[0]));
        } else if (!isBillingComplete) {
          setSelectedTab(tabs[1]);
          dispatch(setTabSelection(tabs[1]));
        } else if (!isLanguageComplete) {
          setSelectedTab(tabs[4]);
          dispatch(setTabSelection(tabs[4]));
        } else {
          setSelectedTab(tabs[0]);
          dispatch(setTabSelection(tabs[0]));
        }
      } else if (Object.keys(selected).length > 0) {
        setSelectedTab(selected);
      }
    } else if (user && user.roleCode === "consultant") {
      if (tabs && tabs.length > 0 && Object.keys(selected).length === 0) {
        const {
          isPersonalComplete,
          isBillingComplete,
          isLanguageComplete,
          isDailyScheduleComplete,
          isInsuranceComplete,
          isMemberComplete,
          isServiceComplete,
          isSpecializationComplete,
          isStripeCompleted,
        } = profileCheck || {};

        if (!isPersonalComplete) {
          setSelectedTab(tabs[0]);
          dispatch(setTabSelection(tabs[0]));
        } else if (!isBillingComplete) {
          setSelectedTab(tabs[1]);
          dispatch(setTabSelection(tabs[1]));
        } else if (!isStripeCompleted) {
          setSelectedTab(tabs[2]);
          dispatch(setTabSelection(tabs[2]));
        } else if (!isMemberComplete) {
          setSelectedTab(tabs[4]);
          dispatch(setTabSelection(tabs[4]));
        }
        // else if (!isInsuranceComplete) {
        //   setSelectedTab(tabs[5]);
        //   dispatch(setTabSelection(tabs[5]));
        // }
        else if (!isLanguageComplete) {
          setSelectedTab(tabs[8]);
          dispatch(setTabSelection(tabs[8]));
        } else if (!isServiceComplete) {
          setSelectedTab(tabs[9]);
          dispatch(setTabSelection(tabs[9]));
        } else if (!isDailyScheduleComplete) {
          setSelectedTab(tabs[10]);
          dispatch(setTabSelection(tabs[10]));
        } else {
          setSelectedTab(tabs[0]);
          dispatch(setTabSelection(tabs[0]));
        }
      } else if (Object.keys(selected).length > 0) {
        setSelectedTab(selected);
      }
    }
  }, [tabs, selected, profileCheck, dispatch, user]);

  const getIncompleteTabMessage = (tab) => {
    const { isPersonalComplete, isBillingComplete, isLanguageComplete } =
      profileCheck || {};

    switch (tab) {
      case tabs[0]:
        return !isPersonalComplete ? "Please Fill Personal Info" : "";
      case tabs[1]:
        return !isPersonalComplete
          ? "Please Fill Personal Info"
          : !isBillingComplete
          ? "Please Fill Billing Info"
          : "";
      case tabs[4]:
        return !isPersonalComplete
          ? "Please Fill Personal Info"
          : !isBillingComplete
          ? "Please Fill Billing Info"
          : !isLanguageComplete
          ? "Please Fill Language Info"
          : "";
      default:
        return "";
    }
  };

  const getIncompleteTabMessageForConsultant = (tab) => {
    const {
      isPersonalComplete,
      isBillingComplete,
      isMemberComplete,
      isInsuranceComplete,
      isLanguageComplete,
      isServiceComplete,
      isDailyScheduleComplete,
      isBiographyComplete,
      isSpecializationComplete,
      isStripeCompleted,
    } = profileCheck || {};

    switch (tab) {
      case tabs[0]:
        return !isPersonalComplete ? "Please Fill Personal Info" : "";
      case tabs[1]:
        return !isPersonalComplete
          ? "Please Fill Personal Info"
          : !isBillingComplete
          ? "Please Fill Billing Info"
          : "";
      case tabs[2]:
        return !isPersonalComplete
          ? "Please Fill Personal Info"
          : !isBillingComplete
          ? "Please Fill Billing Info"
          : !isStripeCompleted
          ? "Please Complete Stripe Payment Account"
          : "";
      case tabs[4]:
        return !isPersonalComplete
          ? "Please Fill Personal Info"
          : !isBillingComplete
          ? "Please Fill Billing Info"
          : !isMemberComplete
          ? "Please Fill Member Info"
          : "";
      case tabs[5]:
        return !isPersonalComplete
          ? "Please Fill Personal Info"
          : !isBillingComplete
          ? "Please Fill Billing Info"
          : !isMemberComplete
          ? "Please Fill Member Info"
          : // : !isInsuranceComplete
            // ? "Please Fill Insurance Info"
            "";
      case tabs[6]:
        return !isPersonalComplete
          ? "Please Fill Personal Info"
          : !isBillingComplete
          ? "Please Fill Billing Info"
          : !isMemberComplete
          ? "Please Fill Member Info"
          : // : !isBiographyComplete
            // ? "Please Fill Biography Info"
            "";
      case tabs[7]:
        return !isPersonalComplete
          ? "Please Fill Personal Info"
          : !isBillingComplete
          ? "Please Fill Billing Info"
          : !isMemberComplete
          ? "Please Fill Member Info"
          : // : !isBiographyComplete
          // ? "Please Fill Biography Info"
          !isSpecializationComplete
          ? "Please Fill Intervention Info"
          : "";
      case tabs[8]:
        return !isPersonalComplete
          ? "Please Fill Personal Info"
          : !isBillingComplete
          ? "Please Fill Billing Info"
          : !isMemberComplete
          ? "Please Fill Member Info"
          : // : !isBiographyComplete
          // ? "Please Fill Biography Info"
          !isSpecializationComplete
          ? "Please Fill Intervention Info"
          : !isLanguageComplete
          ? "Please Fill Language Info"
          : "";
      case tabs[9]:
        return !isPersonalComplete
          ? "Please Fill Personal Info"
          : !isBillingComplete
          ? "Please Fill Billing Info"
          : !isMemberComplete
          ? "Please Fill Member Info"
          : // : !isBiographyComplete
          // ? "Please Fill Biography Info"
          !isSpecializationComplete
          ? "Please Fill Intervention Info"
          : !isLanguageComplete
          ? "Please Fill Language Info"
          : !isServiceComplete
          ? "Please Fill Service Info"
          : "";
      case tabs[10]:
        return !isPersonalComplete
          ? "Please Fill Personal Info"
          : !isBillingComplete
          ? "Please Fill Billing Info"
          : !isMemberComplete
          ? "Please Fill Member Info"
          : // : !isBiographyComplete
          // ? "Please Fill Biography Info"
          !isSpecializationComplete
          ? "Please Fill Intervention Info"
          : !isLanguageComplete
          ? "Please Fill Language Info"
          : !isServiceComplete
          ? "Please Fill Service Info"
          : !isDailyScheduleComplete
          ? "Please Fill Daily Schedule Info"
          : "";
      default:
        return "";
    }
  };

  const handleTabSelection = (tab = {}) => {
    // if (user && user?.roleCode === "user") {
    const { isPersonalComplete, isBillingComplete, isLanguageComplete } =
      profileCheck || {};
    const tabIndex = tabs.indexOf(tab);
    let hasPreviousIncompleteTab = false;
    let message = "";

    for (let i = 0; i < tabIndex; i++) {
      const currentTab = tabs[i];
      message =
        user && user?.roleCode === "user"
          ? getIncompleteTabMessage(currentTab)
          : getIncompleteTabMessageForConsultant(currentTab);
      if (message) {
        hasPreviousIncompleteTab = true;
        break;
      }
    }

    if (hasPreviousIncompleteTab) {
      setModalMessage(message);
      setSelectedTab(tab);
      setIsOpen(true);
      dispatch(setTabSelection(tab));
    } else {
      dispatch(setTabSelection(tab));
      setSelectedTab(tab);
      setIsOpen(false);
    }
    // }
  };

  const handleOkClick = () => {
    setIsOpen(false);
    const {
      isPersonalComplete,
      isBillingComplete,
      isMemberComplete,
      isInsuranceComplete,
      isBiographyComplete,
      isLanguageComplete,
      isServiceComplete,
      isStripeCompleted,
    } = profileCheck || {};

    if (!isPersonalComplete) {
      setSelectedTab(tabs[0]);
      dispatch(setTabSelection(tabs[0]));
    } else if (!isBillingComplete) {
      setSelectedTab(tabs[1]);
      dispatch(setTabSelection(tabs[1]));
    } else if (!isStripeCompleted) {
      setSelectedTab(tabs[2]);
      dispatch(setTabSelection(tabs[2]));
    } else if (!isMemberComplete) {
      setSelectedTab(tabs[4]);
      dispatch(setTabSelection(tabs[4]));
    }
    // else if (!isInsuranceComplete) {
    //   setSelectedTab(tabs[5]);
    //   dispatch(setTabSelection(tabs[5]));
    // }
    // else if (!isBiographyComplete) {
    //   setSelectedTab(tabs[6]);
    //   dispatch(setTabSelection(tabs[6]));
    // }
    else if (!isLanguageComplete) {
      setSelectedTab(tabs[8]);
      dispatch(setTabSelection(tabs[8]));
    } else if (!isServiceComplete) {
      setSelectedTab(tabs[9]);
      dispatch(setTabSelection(tabs[9]));
    }
  };

  return (
    <div className={classNames("relative flex md:gap-10 gap-0", mainClasses)}>
      <nav
        className={classNames(
          "w-1/5 flex overflow-auto",
          divClass,
          design === "style"
            ? "border-b border-slate-200 px-4 xl:px-8 gap-5 xl:gap-6"
            : "gap-3 xl:gap-4"
        )}
      >
        {tabs &&
          tabs.length > 0 &&
          tabs.map((item, index) => (
            <button
              type="button"
              className={classNames(
                "relative py-3 xl:py-4 rounded-full bg-transparent border border-black transition-all duration-200",
                buttonClass,
                design === "style"
                  ? "border-b-2 border-transparent px-0"
                  : "px-6 ",
                item._id === selectedTab?._id
                  ? design === "style"
                    ? "!border-prontopsy-pink"
                    : " !border-[#B32DBD] !bg-prontopsy-blue"
                  : design === "style"
                  ? "bg-prontopsy-pink"
                  : "!bg-[#F5F5F5]"
              )}
              key={index}
              onClick={() => handleTabSelection(item)}
            >
              {loaded ? (
                <div
                  className={classNames(
                    "whitespace-nowrap line-clamp-3",
                    textClasses,
                    design === "style"
                      ? "text-sm font-semibold"
                      : "text-sm xl:text-base font-medium",
                    item._id === selectedTab?._id
                      ? design === "style"
                        ? "!text-carnation-500"
                        : "text-white"
                      : "text-black"
                  )}
                >
                  {item.name}
                </div>
              ) : (
                <div role="status" className="animate-pulse">
                  <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 max-w-[640px] mb-2.5 mx-auto"></div>
                </div>
              )}
            </button>
          ))}
      </nav>
      <div
        className={classNames("w-4/5 relative bg-white", tabContentdivClasses)}
      >
        {selectedTab?.component}
      </div>

      <Modal isOpen={isOpen} bodyClass="!w-[350px]" titleShow={false}>
        <div className="flex flex-col items-center">
          <h2 className="text-xl font-bold text-center">{modalMessage}</h2>
          <Button
            buttonLabel={"Ok"}
            buttonClasses={"mt-4"}
            buttonFunction={handleOkClick}
          />
        </div>
      </Modal>
    </div>
  );
};

export default TabsForProfile;
