import React from "react";
import FacebookLogin from "react-facebook-login";
import Button from "../form/Button";
import { useSelector } from "react-redux";
const langConfig = {
  dont: {
    en: "Dont have an account?",
    es: "¿No tienes una cuenta?",
    it: "Non hai un account?",
  },
  facebook: {
    en: "Continue with facebook",
    es: "Continúa con facebook",
    it: "Continua con facebook",
  },
  google: {
    en: "Continue with google",
    es: "Continúa con google",
    it: "Continua con google",
  },
  signup: {
    en: "Signup now",
    es: "registrarse ahora",
    it: "Iscriviti ora",
  },
  forgot: {
    en: "Forgot password?",
    es: "¿Has olvidado tu contraseña?",
    it: "Ha dimenticato la password?",
  },
  remember: {
    en: "Remember me",
    es: "Acuérdate de mí",
    it: "Ricordati di me",
  },
};

const APP_ID = "3780893892172103";

const FacebookLoginComponent = () => {
  const lang = useSelector((state) => state?.languageSlice?.data);
  const language = localStorage.getItem("language") || lang;
  const responseFacebook = (response) => {
    console.log("[Facebook Login Success] currentUser:", response);
    // You can handle the response as needed, like sending it to your server
  };

  return (
    <div>
      <FacebookLogin
        appId={APP_ID}
        autoLoad={false}
        fields="name,email,picture"
        callback={responseFacebook}
        textButton="Login with Facebook"
        icon="fa-facebook"
        tag={(renderProps) => (
          <div onClick={renderProps.onClick}>
            <Button
              buttonIcon={"fa-brands fa-facebook"}
              buttonIconPosition={"left"}
              buttonLabel={`${langConfig?.facebook?.[language]}`}
              buttonLabelClasses={"!capitalize font-RobotoSlab"}
              buttonClasses={"!h-10 lg:!h-12 !bg-prontopsy-lightblue"}
            />
          </div>
        )}
      />
    </div>
  );
};

export default FacebookLoginComponent;
