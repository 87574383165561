import React, { useRef, useState } from "react";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
import { classNames } from "../../helpers/classNames";

const InputTime = ({
  label,
  labelClasses,
  isInputGroup = false,
  inputGroupIcon,
  inputGroupPosition,
  inputGroupIconClass,
  inputClasses,
  errorType,
  errorText,
  isDisabled,
  value = new Date(),
  onChange = () => {},
  ...props
}) => {
  const flatPicker = useRef(null);
  const [focused, setFocused] = useState(false);
  // console.log(value, "value within date picker");
  const profileData = JSON.parse(localStorage.getItem("userDetails"));
  const gmtOffset =
    profileData && profileData?.personal?.timeZone?.gmtOffsetName;

  const convertDateToGMTOffset = (dateString, gmtOffset) => {
    // Parse the input date string to a Date object
    const date = new Date(dateString);

    // Extract hours and minutes from the GMT offset
    const match = gmtOffset.match(/([+-]\d{2}):(\d{2})/);
    if (!match) throw new Error("Invalid GMT offset format");

    const [, sign, hours, minutes] = match;
    const offsetInMinutes = parseInt(hours) * 60 + parseInt(minutes);

    // Adjust the date by the offset (positive or negative)
    const totalOffset = sign === "+" ? offsetInMinutes : -offsetInMinutes;
    const adjustedDate = new Date(date.getTime() + totalOffset * 60 * 1000);

    return adjustedDate.toISOString().replace("Z", "");
  };

  return (
    <>
      <div className="relative space-y-1 w-full">
        {label && (
          <div
            className={classNames(
              "text-sm font-semibold text-opplio-gray tracking-none !text-[10px]",
              labelClasses
            )}
          >
            {label}
          </div>
        )}
        <div className={classNames("relative flex")}>
          {isInputGroup && inputGroupPosition === "left" && (
            <div
              className={classNames(
                "w-10 flex-shrink-0 flex items-center justify-center border rounded-md rounded-r-none bg-white text-denim-500 text-base transition-all duration-200",
                inputGroupIconClass,
                isDisabled ? "!bg-opplio-silver" : "",
                focused ? "border-opplio-graySky" : "border-opplio-border"
              )}
            >
              <i className={classNames("fa-fw", inputGroupIcon)}></i>
            </div>
          )}
          <div
            className={classNames(
              "w-full overflow-hidden rounded-md border transition-all duration-200",
              isInputGroup && inputGroupPosition === "left"
                ? "!border-l-0 !rounded-l-none"
                : "",
              isInputGroup && inputGroupPosition === "right"
                ? "!border-r-0 !rounded-r-none"
                : "",
              focused ? "border-opplio-graySky" : "border-opplio-border"
            )}
          >
            <Flatpickr
              ref={flatPicker}
              value={value}
              onChange={onChange}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              options={{
                dateFormat: "H:i", // Format to display time
                enableTime: true, // Enable time picker
                noCalendar: true, // Hide calendar
                time_24hr: true, // Use 24hr format
                minuteIncrement: 5, // Increment time by 5 minutes
              }}
              className={classNames(
                "w-full h-10 bg-white !rounded-none !border-none py-0 px-3 text-sm font-medium text-denim-800 !ring-0 !outline-0 transition-all duration-200",
                isDisabled ? "!bg-opplio-silver" : "",
                inputClasses
              )}
              placeholder={props.placeholder}
            />
          </div>
          {isInputGroup && inputGroupPosition === "right" && (
            <div
              className={classNames(
                "w-10 flex-shrink-0 flex items-center justify-center border rounded-md rounded-l-none bg-white text-denim-500 text-base transition-all duration-200",
                inputGroupIconClass,
                isDisabled ? "!bg-opplio-silver" : "",
                focused ? "border-opplio-graySky" : "border-opplio-border"
              )}
            >
              <i className={classNames("fa-fw", inputGroupIcon)}></i>
            </div>
          )}
        </div>
        {errorType && errorType === "danger" && (
          <div className="text-xs font-medium text-red-600">
            <i className="fa-regular fa-fw fa-square-exclamation text-sm mr-1"></i>
            {errorText}
          </div>
        )}
        {errorType && errorType === "warning" && (
          <div className="text-xs font-medium text-amber-500">
            <i className="fa-regular fa-fw fa-triangle-exclamation text-sm mr-1"></i>
            {errorText}
          </div>
        )}
        {errorType && errorType === "success" && (
          <div className="text-xs font-medium text-green-600">
            <i className="fa-regular fa-fw fa-circle-check text-sm mr-1"></i>
            {errorText}
          </div>
        )}
        {errorType && errorType === "info" && (
          <div className="text-xs font-medium text-sky-600">
            <i className="fa-regular fa-fw fa-circle-info text-sm mr-1"></i>
            {errorText}
          </div>
        )}
      </div>
    </>
  );
};

export default InputTime;
