import React, { useEffect, useState, useRef } from "react";
import AppointmentItem from "../../../../single/AppointmentItem";
import { useDispatch, useSelector } from "react-redux";
import { appointmentLang } from "../../../language";
import dayjs from "dayjs";
import SpinLoader from "../../../table/SpinLoader";
import {
  addClientAppointmentNote,
  addConsultantAppointmentNote,
  fetchAppointmentList,
} from "../../../../../service/appointmentService";
import { useNavigate } from "react-router-dom";
import NoDataFound from "../../../NoDataFound";
import { toast } from "react-toastify";
import { setSelectedAppointment } from "../../../../../store/slice/appointmentSlice";
import Cookies from "js-cookie";
import moment from "moment-timezone";

const langConfig = {
  payment: {
    en: "Payment amount",
    es: "Monto del pago",
    it: "Importo del pagamento",
  },
  status: {
    en: "Payment status",
    es: "Estado de pago",
    it: "Stato del pagamento",
  },
  id: {
    en: "Appointment ID",
    es: "ID de cita",
    it: "ID di appuntamento",
  },
};

const AppuntamentiPassati = () => {
  const roleCode = JSON.parse(localStorage.getItem("userDetails"))?.roleCode;
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const lang = useSelector((state) => state?.languageSlice?.data);
  const language = localStorage.getItem("language") || lang;
  const [appointmentData, setAppointmentData] = useState([]);
  const token = localStorage.getItem("auth_token") || Cookies.get("auth_token");
  const [loaded, setLoaded] = useState(true);
  const [list, setList] = useState([]);

  const [limit, setLimit] = useState(15);
  const [totalDocs, setTotalDocs] = useState(null);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [page, setPage] = useState(1);
  const [allList, setAllList] = useState([]);
  const [noteInput, setNoteInput] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [scrolledToBottom, setScrolledToBottom] = useState(false);
  const [selectedNoteData, setSelectedNoteData] = useState(null);
  const scrollableRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!token) {
      navigate("/");
      localStorage.clear();
      Cookies.remove("auth_token");
    }
  }, [token, navigate, Cookies]);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollableRef.current) {
        const { scrollHeight, scrollTop, clientHeight } = scrollableRef.current;
        const scrolledToBottom = scrollTop + clientHeight >= scrollHeight;

        setScrolledToBottom(scrolledToBottom);
      }
    };

    const scrollableElement = scrollableRef.current;
    if (scrollableElement) {
      scrollableElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollableElement) {
        scrollableElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [scrollableRef.current]);

  useEffect(() => {
    fetchAppointmentList(
      token,
      { limit: limit, "status[0]": "close", page: page },
      setList,
      setLoaded,
      setHasNextPage
    ).then((res) => {
      if (res?.data?.docs?.status === 200) {
        let metaData = res?.data?.docs?.metadata;
        setTotalDocs(metaData?.totalDocs);
        page !== 1
          ? setAllList((prevList) => [...prevList, ...res.data.docs.data])
          : setAllList(res.data.docs.data);
      }
    });
  }, [page]);

  useEffect(() => {
    if (scrolledToBottom && hasNextPage) {
      setPage((pre) => pre + 1);
    }
  }, [scrolledToBottom, hasNextPage]);

  const convertToLocal = (utcDateStr) => {
    return moment(utcDateStr).local(); // Convert to local time using moment
  };

  useEffect(() => {
    let info =
      list &&
      allList.length > 0 &&
      allList.map((elm) => ({
        id: elm._id,
        date: moment(convertToLocal(elm?.details?.appointmentDate)).format("D"),
        month: moment(convertToLocal(elm?.details?.appointmentDate)).format(
          "MMMM"
        ),
        time: `${moment(
          convertToLocal(elm?.details?.appointmentStartTime)
        ).format("h:mm")} - ${moment(
          convertToLocal(elm?.details?.appointmentEndTime)
        ).format("h:mm A")}`,
        timeZone: userDetails?.personal?.timeZone?.zoneName,
        // timeZone: moment.tz.guess(),
        // timeZone: elm?.consultantData?.personal?.timeZone?.zoneName,
        note: ` ${appointmentLang?.youHadAppointmentWith?.[language]}
           
            ${
              roleCode === "consultant"
                ? elm?.clientData?.firstName && elm?.clientData?.lastName
                  ? elm.clientData.firstName + " " + elm.clientData.lastName
                  : ""
                : elm?.consultantData?.firstName &&
                  elm?.consultantData?.lastName
                ? elm.consultantData.firstName +
                  " " +
                  elm.consultantData.lastName
                : ""
            }`,
        notes: elm?.relatedNoteData || null,
        clientData: elm?.clientData,
        sessionId: elm?.sessionId,
        sessionToken: elm?.sessionToken,
        paymentInfo: `<p>${langConfig?.payment?.[language]}: EUR ${elm?.details?.capturedAmount}</p><p>${langConfig?.status?.[language]}: ${elm?.details?.paymentStatus}</p><p>${langConfig?.id?.[language]}: ${elm?.details?.appointmentId}</p>`,
        buttonTextPink: appointmentLang?.addNote?.[language],
        buttonTextBlue: appointmentLang?.active?.[language],
        buttonIcon: "fa-solid fa-file",
        isTrue: true,
        status: elm?.status,
      }));
    setAppointmentData(info);
  }, [list, language, allList]);

  const handleNoteSubmit = async () => {
    if (!noteInput) {
      toast.error("Please enter your note!");
      return;
    }

    setLoaded(false);
    const info = {
      appointmentId: localStorage.getItem("selectedAppointment"),
      message: noteInput,
    };

    try {
      const addNote =
        roleCode === "consultant"
          ? await addConsultantAppointmentNote(info)
          : await addClientAppointmentNote(info);

      if (addNote?.data?.status === 200 || addNote?.data?.status === 201) {
        const data = {
          _id: addNote.data.data._id,
          consultantId: addNote.data.data.consultantId,
          message: addNote.data.data.message,
          userId: addNote.data.data.clientData?._id,
        };
        setNoteInput(addNote?.data?.data?.message);
        localStorage.removeItem("selectedAppointment");
        toast.success(addNote.data.message);
        setIsModalOpen(true);
        setSelectedNoteData(null);
        dispatch(setSelectedAppointment(null));
        setAllList((prevList) =>
          prevList.map((elm) =>
            elm._id === addNote.data.data.appointmentId
              ? { ...elm, relatedNoteData: data }
              : elm
          )
        );
      } else {
        toast.error(addNote.data.message);
      }
    } catch (error) {
      toast.error("Error updating note!");
    } finally {
      setLoaded(true);
    }
  };

  return (
    <div>
      {" "}
      {allList.length === 0 && !loaded ? (
        <SpinLoader />
      ) : (
        <div
          ref={scrollableRef}
          className="relative divide-y divide-neutral-300 h-[70vh] overflow-auto pr-3 pl-3 shadow-md md:pt-0 pt-5"
        >
          {allList.length == 0 && loaded && <NoDataFound />}
          <div className="flex flex-col w-full space-y-4 !pb-5">
            {appointmentData &&
              appointmentData.length > 0 &&
              appointmentData.map((appointment, index) => {
                return (
                  <AppointmentItem
                    key={index}
                    data={appointment}
                    handleSubmit={handleNoteSubmit}
                    noteInput={noteInput}
                    setNoteInput={setNoteInput}
                    isClose={isModalOpen}
                    setIsClose={setIsModalOpen}
                    loaded={loaded}
                  />
                );
              })}
          </div>
        </div>
      )}{" "}
      {!loaded && allList.length > 0 && (
        <div className="flex justify-center mt-4">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-prontopsy-blue"></div>
        </div>
      )}
    </div>
  );
};

export default AppuntamentiPassati;
