import React, { useEffect, useState } from "react";
import Tabs from "../../components/elements/Tabs";
import AppuntamentiPassati from "../../components/elements/tabContent/profile/appuntamenti/AppuntamentiPassati";
import AppointmentInProgress from "../../components/elements/tabContent/profile/appuntamenti/AppointmentInProgress";
import UpcomingEvents from "../../components/elements/tabContent/profile/appuntamenti/UpcomingEvents";
import { useDispatch, useSelector } from "react-redux";
import { appointmentLang } from "../../components/elements/language";
import { setTabSelection } from "../../store/slice/selectedTabSlice";
import { fetchAppointmentList } from "../../service/appointmentService";

const Appointments = () => {
  const lang = useSelector((state) => state?.languageSlice?.data);
  const language = localStorage.getItem("language") || lang;
  const dispatch = useDispatch();
  const [totalDocs, setTotalDocs] = useState(0);
  const token = localStorage.getItem("auth_token");

  const fetchAppointments = async () => {
    const res = await fetchAppointmentList(token, {
      "status[0]": "active",
    });

    if (res?.data?.docs?.status === 200) {
      const metaData = res?.data?.docs?.metadata;
      setTotalDocs(metaData?.totalDocs);
    }
  };

  useEffect(() => {
    dispatch(setTabSelection({}));
    fetchAppointments();
  }, []);

  const tabData = [
    {
      _id: 1,
      name: appointmentLang?.upcomingAppointments?.[language],
      component: <UpcomingEvents />,
    },
    {
      _id: 2,
      name: appointmentLang?.appointmensInProgress?.[language],
      component: <AppointmentInProgress />,
    },
    {
      _id: 3,
      name: appointmentLang?.pastAppointments?.[language],
      component: <AppuntamentiPassati />,
    },
  ];
  const tabData2 = [
    {
      _id: 1,
      name: appointmentLang?.pastAppointments?.[language],
      component: <AppuntamentiPassati />,
    },
    {
      _id: 2,
      name: appointmentLang?.appointmensInProgress?.[language],
      component: <AppointmentInProgress />,
    },
    {
      _id: 3,
      name: appointmentLang?.upcomingAppointments?.[language],
      component: <UpcomingEvents />,
    },
  ];

  return (
    <section className="relative py-5">
      <div className="relative">
        <Tabs
          tabs={tabData2}
          extra={{ type: "appointment", hasUpcoming: totalDocs > 0 }}
          tabBorder={true}
          mainClasses={"flex-col"}
          divClass={"w-full flex-nowrap py-2 !w-full"}
          textClasses={
            "font-UnisonPro !font-bold italic !text-[11px] !leading-[15px] !whitespace-normal !text-prontopsy-blue"
          }
          buttonClass={
            "max-w-48 xl:max-w-56 w-full !rounded-md !py-0 !px-5 h-14 flex items-center justify-center !border-none lg:!max-w-[30%] !text-prontpsy-blue"
          }
          tabContentdivClasses={"w-full"}
          activeTabItem={"!px-10 !bg-prontopsy-blue "}
          activeTabItemText={"!text-slate-100"}
        />
      </div>
    </section>
  );
};

export default Appointments;
