import React, { useEffect, useState, useRef } from "react";
import HowItWorkItem from "../../items/HowItWorkItem";
import { fetchHowItWorksList } from "../../../service/howItWorksService";
const Work = ({ role, props, ref }) => {
  const [data, setData] = useState([]);
  const [loaded, setLoaded] = useState(true);
  const [filteredData, setFilteredData] = useState([]);

  console.log(data, "data");
  useEffect(() => {
    fetchHowItWorksList(setData, setLoaded);
  }, []);

  useEffect(() => {
    let info =
      role &&
      data.length > 0 &&
      data.filter((elm) => elm?.role.includes(role?._id));
    setFilteredData(info);
  }, [data, role]);

  return (
    <>
      {filteredData &&
        filteredData.length > 0 &&
        filteredData.map((elm, index) => (
          <HowItWorkItem key={elm._id} data={elm} index={index} ref={ref} />
        ))}
    </>
  );
};

export default Work;
