import { useEffect, useRef, useState } from "react";
import { useSocket } from "../components/socket/SocketProvider";
import { appointmentClose } from "../service/videoCallService";

const OFFLINE_TIMEOUT = 1 * 60 * 1000; // 1 minute
const INACTIVITY_TIMEOUT = 30 * 60 * 1000; // 30 minutes

function usePageVisibility(token, socket, userId) {
  const offlineTimeoutRef = useRef(null);
  const inactivityTimeoutRef = useRef(null);
  const [isOnline, setIsOnline] = useState(false);

  const appointment = JSON.parse(
    localStorage.getItem("appointmentData") || "null"
  );
  const userDetails = JSON.parse(localStorage.getItem("userDetails") || "null");

  useSocket("userStatusResponse", (data) => {
    if (userId === data?.userId) {
      setIsOnline(data.online);
    }
  });

  useEffect(() => {
    const emitUserOffline = () => {
      if (socket && userId && !appointment) {
        socket.emit("userOffline", userId);
      }
    };

    const handleAppointmentClose = () => {
      if (!appointment?.appointmentId) return;

      appointmentClose({ appointmentId: appointment.appointmentId }).then(
        () => {
          const socketEvents = {
            chat: "chatDisconnect",
            default: "videoSessionDisconnect",
          };

          const eventName =
            appointment.mode === "chat"
              ? socketEvents.chat
              : socketEvents.default;

          socket?.emit(eventName, {
            disconnect: true,
            appointmentId: appointment.appointmentId,
          });

          socket?.emit("consultantBusy", {
            consultantId: appointment.consultantId,
            userId: appointment.callerData?.user?.id,
            isBusy: false,
          });

          localStorage.removeItem("joined");

          const redirectPath =
            userDetails?.roleCode === "user"
              ? `/review-appointment/${appointment.appointmentId}`
              : "/appointments";

          window.location.href = redirectPath;
          localStorage.removeItem("appointmentData");
        }
      );
    };

    const handleVisibilityChange = () => {
      if (document.hidden) {
        offlineTimeoutRef.current = setTimeout(
          handleAppointmentClose,
          OFFLINE_TIMEOUT
        );
      } else {
        clearTimeout(offlineTimeoutRef.current);
        offlineTimeoutRef.current = null;
      }
    };

    const resetInactivityTimeout = () => {
      clearTimeout(inactivityTimeoutRef.current);
      inactivityTimeoutRef.current = setTimeout(
        emitUserOffline,
        INACTIVITY_TIMEOUT
      );
    };

    const eventListeners = [
      { event: "visibilitychange", handler: handleVisibilityChange },
      { event: "mousemove", handler: resetInactivityTimeout },
      { event: "keydown", handler: resetInactivityTimeout },
    ];

    eventListeners.forEach(({ event, handler }) => {
      document.addEventListener(event, handler);
    });

    resetInactivityTimeout();

    return () => {
      eventListeners.forEach(({ event, handler }) => {
        document.removeEventListener(event, handler);
      });

      [offlineTimeoutRef, inactivityTimeoutRef].forEach((ref) => {
        if (ref.current) clearTimeout(ref.current);
      });
    };
  }, [token, socket, userId, appointment?.consultantId]);

  return null;
}

export default usePageVisibility;
