import React, { useEffect, useState } from "react";
import BlogItem from "../../single/BlogItem";
import { fetchBlogList } from "../../../service/blogService";
import { useSelector } from "react-redux";

const BlogList = () => {
  const [blogList, setBlogList] = useState([]);
  const [loaded, setloaded] = useState(true);
  const lang = useSelector((state) => state?.languageSlice?.data);
  const language = localStorage.getItem("language") || lang;

  useEffect(() => {
    fetchBlogList(setBlogList, setloaded);
  }, []);

  return (
    <section className="relative py-10 md:py-12 lg:py-16 xl:py-20">
      <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-x-5 gap-y-10">
          <>
            {blogList.length > 0 &&
              blogList.map((elm) => (
                <BlogItem key={elm?._id} data={elm} language={language} />
              ))}
          </>
        </div>
      </div>
    </section>
  );
};

export default BlogList;
