import React from "react";
import Button from "../../form/Button";
import { useSelector } from "react-redux";
const FollowUs = ({ language }) => {
  const data = useSelector((state) => state.siteSettings.data.socialMedia);
  const title = {
    en: "Follow Us",
    es: "Síganos",
    it: "Seguici",
  };
  const followUsData = {
    title: title,
  };
  const socialData =
    data &&
    data.length > 0 &&
    data.map((elm) => ({
      link: elm?.url,
      icon: elm?.icon,
    }));

  return (
    <div className="w-full relative bg-white  py-10 md:py-12 lg:py-16 xl:py-20  ">
      <div className="space-y-4 relative w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto">
        <div className="flex items-center justify-center">
          {followUsData?.title && (
            <h2
              className="uppercase text-prontopsy-blue text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl font-UnisonPro italic font-bold !leading-[1.1]"
              dangerouslySetInnerHTML={{
                __html: followUsData?.title?.[language],
              }}
            ></h2>
          )}
        </div>
        <div className="flex items-center justify-center gap-1">
          {socialData?.map((social, index) => (
            <div key={index} className="relative">
              <Button
                buttonHasLink={true}
                buttonLink={social.link}
                buttonIcon={social.icon}
                buttonIconPosition={"left"}
                buttonClasses={
                  "!w-12 !h-12 xl:!w-14  xl:!h-14 !rounded-full flex justify-center !text-black !bg-transparent border border-black"
                }
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FollowUs;
