import React, { useEffect, useRef, useState } from "react";
import Image from "../../elements/Image";
import User from "../../../assets/images/user-01.webp";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../form/Button";
import {
  addNoteService,
  deleteNote,
  fetchClientNotesList,
} from "../../../service/clientService";
import SpinLoader from "../../elements/table/SpinLoader";
import NoDataFound from "../../elements/NoDataFound";
import Modal from "../../elements/Modal";
import TextAreaAutoSize from "../../form/TextareaAutoSize";
import { toast } from "react-toastify";
import { submitBtn } from "../../elements/language";
import { useSelector } from "react-redux";
import DeleteModal from "../../elements/DeleteModal";
import Cookies from "js-cookie";
// Ensure you have a SpinLoader component

const addnoteLang = {
  en: "Add Note",
  es: "Agregar nota",
  it: "Aggiungi nota",
};
const backLang = {
  en: "Back",
  es: "Atrás",
  it: "Indietro",
};

const ClientNoteList = ({ data }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const lang = useSelector((state) => state?.languageSlice?.data);
  const language = localStorage.getItem("language") || lang;
  const token = localStorage.getItem("auth_token") || Cookies.get("auth_token");
  const [list, setList] = useState([]);
  const [allList, setAllList] = useState([]);
  const [loaded, setLoaded] = useState(true);
  const [deleteLoaded, setDeleteLoaded] = useState(true);
  const [noteAddLoaded, setNoteAddLoaded] = useState(true);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalDocs, setTotalDocs] = useState(null);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [page, setPage] = useState(1);
  const [scrolledToBottom, setScrolledToBottom] = useState(false);
  const scrollableRef = useRef(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [noteInput, setNoteInput] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedForDelete, setSelectedForDelete] = useState(null);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (scrollableRef.current) {
        const { scrollHeight, scrollTop, clientHeight } = scrollableRef.current;
        const scrolledToBottom = scrollTop + clientHeight >= scrollHeight;
        setScrolledToBottom(scrolledToBottom);
      }
    };

    const scrollableElement = scrollableRef.current;
    if (scrollableElement) {
      scrollableElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollableElement) {
        scrollableElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [allList]);

  useEffect(() => {
    if (scrolledToBottom && hasNextPage && loaded) {
      setPage((pre) => pre + 1);
    }
  }, [scrolledToBottom, hasNextPage, loaded]);

  useEffect(() => {
    setLoaded(false);
    fetchClientNotesList(
      { limit: 5, clientId: id, page: page },
      token,
      setList,
      setLoaded,
      setHasNextPage
    ).then((res) => {
      if (res?.data?.docs?.status === 200) {
        let metaData = res?.data?.docs?.metadata;
        setTotalDocs(metaData?.totalDocs);
        page !== 1
          ? setAllList((prevList) => [...prevList, ...res?.data?.docs?.data])
          : setAllList(res?.data?.docs?.data);
      }
      setLoaded(true);
    });
  }, [page]);

  const handleDelete = () => {
    selectedForDelete &&
      deleteNote(
        { ids: [selectedForDelete?._id] },
        token,
        setDeleteLoaded
      ).then((res) => {
        if (res?.data?.status == 200) {
          let data = allList.filter(
            (elm) => elm?._id !== selectedForDelete?._id
          );
          setAllList(data);
          setIsDeleteModalOpen(false);
          toast.success("Note deleted successfully!");
        } else {
          toast.error("Something went wrong!");
        }
      });
  };

  const handleSubmit = () => {
    if (!noteInput || noteInput.trim().length === 0) {
      toast.error("Note is required!");
      return;
    }
    addNoteService(
      { userId: id, message: noteInput },
      token,
      setNoteAddLoaded
    ).then((res) => {
      if (res.data.status == 200) {
        setNoteInput("");
        closeModal();
        setAllList([res?.data?.data]);
      }
    });
  };

  useEffect(() => {
    allList && allList.length > 0 && setNoteInput(allList[0]?.message);
  }, [allList]);

  return (
    <>
      {allList.length === 0 && !loaded ? (
        <SpinLoader />
      ) : (
        <div className="w-full bg-white pr-12 shadow-xl h-[calc(100vh-8rem)] overflow-auto pl-12 ">
          <div className="flex justify-end gap-2 items-center sticky top-0  bg-white pb-3 z-10">
            <Button
              buttonLabel={`${addnoteLang?.[language]}`}
              buttonFunction={openModal}
              buttonIconPosition={"left"}
              buttonIcon={"fa-regular fa-plus"}
            />
            <Button
              buttonLabel={`${backLang?.[language]}`}
              buttonIconPosition={"left"}
              buttonIcon={"fa-regular fa-backward"}
              buttonFunction={() => navigate("/clients")}
            />
          </div>

          <div className="h-[70vh] overflow-auto" ref={scrollableRef}>
            {allList.map((elm) => (
              <div
                className="relative shadow-md px-5 md:py-4 pt-7 pb-4 mt-5 mr-3 border"
                key={elm?._id}
              >
                <div className="flex items-start w-full gap-3">
                  <div className="items-center w-full">
                    <div className="md:flex items-center gap-3">
                      <div className="md:w-auto w-full flex justify-center">
                        <div className="!flex md:!justify-start !justify-center !w-12 !min-h-12 md:mb-0 mb-1">
                          <Image
                            src={elm?.clientData?.image || User}
                            alt=""
                            effect="blur"
                            className="flex w-full h-full object-cover rounded-full flex-shrink-0"
                          />
                        </div>
                      </div>
                      <h2 className="md:text-lg text-md font-medium text-gray-800">{`${elm?.clientData?.firstName} ${elm?.clientData?.lastName}`}</h2>
                    </div>
                    <div className=" md:pt-2 pt-1 w-full">
                      {/* <h2 className="text-lg">{`${elm?.clientData?.firstName} ${elm?.clientData?.lastName}`}</h2> */}
                      <h3 className="text-xs">{`${elm?.message}`}</h3>
                    </div>
                  </div>

                  <div className="absolute top-4 right-4">
                    <Button
                      buttonClasses={
                        "!p-0 !w-6 !h-6 !aspect-square !flex !justify-center !items-center !text-sm !rounded-full"
                      }
                      // buttonLabel={deleteBtn?.[language]}
                      // buttonFunction={() => handleDelete(elm?._id)}
                      buttonFunction={() => {
                        setIsDeleteModalOpen(true);
                        setSelectedForDelete(elm);
                      }}
                      isDisable={!deleteLoaded}
                      buttonIconPosition={"left"}
                      buttonIcon={"fa-regular fa-xmark"}
                    />
                  </div>
                </div>
              </div>
            ))}
            {allList.length == 0 && <NoDataFound />}
            {!loaded && (
              <div className="flex justify-center mt-4">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-prontopsy-blue"></div>
              </div>
            )}
          </div>
        </div>
      )}
      {/* M     O     D     A     L */}
      <Modal isOpen={isModalOpen} setIsOpen={setIsModalOpen} titleShow={false}>
        <div className="relative mt-2">
          <div className="absolute right-0 -top-6">
            {/* <button onClick={closeModal}>sl</button> */}
            <Button
              buttonLabel={""}
              buttonLabelClasses={
                "!uppercase !text-" + data &&
                data?.buttonColor +
                  " !text-base !whitespace-nowrap !font-medium"
              }
              buttonIconPosition={"left"}
              buttonIcon={"fa-regular fa-xmark"}
              buttonClasses={
                "!bg-transparent  !px-0 !py-0 !h-4 !text-prontopsy-pink  !text-2xl mt-2  "
              }
              buttonFunction={closeModal}
            />
          </div>

          {/* Search Psychologist by Name*/}
          <div className="col-span-12 lg:col-span-6">
            <div className="relative">
              <TextAreaAutoSize
                label={"*Write Note"}
                iconClasses={"hidden"}
                inputPlaceholder={"Write your note here .."}
                inputType={Text}
                divClasses={"h-12 bg-transparent !border-black rounded-none"}
                labelClasses={
                  "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab"
                }
                inputClasses={"!bg-transparent !h-12"}
                inputName={"name"}
                onChange={(e) => setNoteInput(e.target.value)}
                inputValue={noteInput}
              />
            </div>

            <div className="relative w-full mt-3">
              {/* <button onClick={closeModal}>sl</button> */}
              <Button
                buttonLabel={submitBtn?.[language]}
                buttonLabelClasses={
                  "!uppercase !text-" + data &&
                  data?.buttonColor +
                    " !text-base !whitespace-nowrap !font-medium"
                }
                buttonClasses={
                  "!py-5 !px-6 !h-5 !text-white  !text-xl  !bg-prontopsy-pink text-white "
                }
                buttonFunction={handleSubmit}
                isDisable={!noteAddLoaded}
              />
            </div>
          </div>
        </div>
      </Modal>

      <DeleteModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onDelete={handleDelete}
        loaded={deleteLoaded}
      />
    </>
  );
};

export default ClientNoteList;
