import React, { useEffect, useState } from "react";
import SmartPortal from "../SmartPortal";
import Register from "../Register";
import { useSelector } from "react-redux";
import PageBanner from "../PageBanner";
import { useParams } from "react-router-dom";
import { fetchBlogDetails } from "../../../service/blogService";
import Image from "../../elements/Image";
import img from "../../../assets/images/blog-02.webp";

const BlogDetails = () => {
  const [bannerInfo, setbannerInfo] = useState(null);
  const data = useSelector((state) => state?.pageSlice?.data);
  const lang = useSelector((state) => state?.languageSlice?.data);
  const language = localStorage.getItem("language") || lang;
  const { id } = useParams();
  const [details, setDetails] = useState(null);
  const [loaded, setLoaded] = useState(true);

  useEffect(() => {
    fetchBlogDetails(id, setDetails, setLoaded);
  }, []);

  useEffect(() => {
    let info =
      data.length > 0 && data.filter((elm) => elm?.title?.en == "Blog");
    setbannerInfo(info[0]);
  }, [data]);

  const bannerData = {
    image:
      bannerInfo &&
      bannerInfo?.banner &&
      bannerInfo?.banner.length > 0 &&
      bannerInfo?.banner[0]?.image?.url,
    title:
      bannerInfo &&
      bannerInfo?.banner &&
      bannerInfo?.banner.length > 0 &&
      bannerInfo?.banner[0]?.title?.[language],
  };

  return (
    <div className=" mt-[100px]">
      <PageBanner bannerData={bannerData} />
      <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto xl:pt-20 pt-10 xl:pb-24 pb-20">
        <div className="xl:text-xl md:text-lg text-center pb-4 text-prontopsy-pink">
          Top 16 Areas Of Psychological Intervention
        </div>
        <div className="xl:text-3xl md:text-xl text-center text-prontopsy-blue font-UnisonPro font-bold xl:pb-12 pb-8">
          Top 16 Areas Of Psychological Intervention
        </div>

        <div className="flex flex-col rounded-2xl">
          <div className="w-full aspect-[2/1] overflow-hidden rounded-xl">
            <Image
              src={img}
              alt={""}
              className={"!w-full !h-full !object-cover"}
            />
          </div>
          <div className="left-0 bottom-2 rounded-2xl xl:mt-8 mt-7">
            <div className="xl:text-xl md:text-lg text-base text-gray-800">
              <p>
                How about waking up with a chaotic mind and despite trying
                everything, you are unable to do daily tasks? If you are facing
                the same situation, then you are in the right place. Here you
                will find all the information regarding a healthy mind and
                online psychological counseling.
              </p>
              <p className="mt-4">
                The human mind is deep and has a secret chamber to feed the
                memories. These memories overall affect daily life and influence
                the emotional and cognitive behavior of an individual.
                Therefore, psychological counseling is essential for mental,
                emotional, and physical well-being. In this article, you will
                find 16 different areas of psychology and related interventions.
                So, without further due, let's get started.
              </p>
            </div>
            <div className="flex gap-3 xl:mt-5 md:mt-3 mt-5 font-RobotoSlab items-center justify-end">
              <div className="xl:text-lg text-md font-bold text-prontopsy-pink">
                Publish Date:
              </div>
              <div className="xl:text-lg text-md text-gray-600">12/11/24</div>
            </div>
          </div>
        </div>
      </div>

      <SmartPortal />
      <Register />
    </div>
  );
};

export default BlogDetails;
