import React from "react";
import RatingStars from "./RatingStars";
import { useSelector } from "react-redux";

const languageConfig = {
  basedOn: {
    en: "Based on",
    es: "Dependiendo de",
    it: "Basato su",
  },
  review: {
    en: "Review(s)",
    es: "Reseña(s)",
    it: "Recensione(s)",
  },
};

const Rating = ({ data }) => {
  const lang = useSelector((state) => state?.languageSlice?.data);
  const language = localStorage.getItem("language") || lang;

  return (
    <>
      <div className="relative w-full flex flex-col items-center space-y-1">
        <div className="flex items-center space-x-2">
          <RatingStars data={data?.value} />
          <div className="w-5 aspect-square flex-shrink-0 bg-prontopsy-blue flex items-center justify-center !leading-none text-xs text-white rounded">
            {data.value || 0}
          </div>
        </div>
        {data.reviews && (
          <div className="text-sm text-neutral-700">
            {languageConfig?.basedOn?.[language]}{" "}
            <span className="font-medium text-prontopsy-blue">
              {data.reviews}
            </span>{" "}
            {languageConfig?.review?.[language]}
          </div>
        )}
      </div>
    </>
  );
};

export default Rating;
