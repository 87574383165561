import React, { useState, useEffect, useRef, Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { usePopper } from "react-popper";
import { bottom, top, left, right } from "@popperjs/core";
import { classNames } from "../../helpers/classNames";
import Search from "../form/Search";

const SkeletonLoader = ({ className }) => (
  <div className={`animate-pulse bg-gray-200 ${className}`}></div>
);

const MultiSelect = ({
  xPlacement,
  placeholder,
  dropdownData = [],
  dropdownClass,
  dropdownButtonClass,
  selectedValues = [],
  label,
  labelClasses,
  errorType,
  errorText,
  isSearch,
  handleSelect = () => {},
  handleRemove = () => {},
  loaded = true,
  ...props
}) => {
  const [filteredData, setFilteredData] = useState(dropdownData || []);
  const [selected, setSelected] = useState(selectedValues || []);
  const popperElRef = useRef(null);
  const [targetElement, setTargetElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    setSelected(selectedValues);
  }, [dropdownData]);

  useEffect(() => {
    if (!dropdownData || !Array.isArray(dropdownData)) {
      return;
    }

    if (typeof searchInput !== "string") {
      setFilteredData(dropdownData);
      return;
    }
    if (!searchInput) {
      setFilteredData(dropdownData);
      return;
    }

    const filtered =
      searchInput &&
      dropdownData.length > 0 &&
      dropdownData.filter(
        (elm) =>
          elm.name &&
          elm?.name
            .replace(/[\/_]/g, "")
            .toLowerCase()
            .includes(searchInput.trim().toLowerCase())
      );
    setFilteredData(filtered);
  }, [searchInput, dropdownData]);

  useEffect(() => {
    setFilteredData(dropdownData);
  }, [dropdownData]);

  const placements = {
    bottom: bottom,
    bottomLeft: "bottom-start",
    bottomRight: "bottom-end",
    top: top,
    topLeft: "top-start",
    topRight: "top-end",
    right: right,
    left: left,
    "": bottom,
  };

  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: placements[xPlacement],
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 2],
        },
      },
    ],
  });

  const handleSelectItem = (item) => {
    const isSelected =
      selected && selected.some((selItem) => selItem.value === item.value);

    if (!isSelected) {
      setSelected([...selected, item]);
      handleSelect([...selected, item]);
    }
  };

  const handleRemoveItem = (item, index) => {
    const updatedSelected = selected.filter(
      (selItem) => selItem.value !== item.value
    );
    setSelected(updatedSelected);
    handleRemove(updatedSelected, index);
  };

  return (
    <>
      <div className="relative space-y-1 w-full">
        {label && (
          <div
            className={classNames(
              "text-sm font-semibold text-opplio-gray tracking-none",
              labelClasses
            )}
          >
            {label}
          </div>
        )}
        {loaded ? (
          <Listbox
            value={selected && selected.length > 0 ? selected : null}
            onChange={(value) => {
              handleSelectItem(value);
            }}
            as={"div"}
            className="relative"
          >
            {({ open }) => (
              <>
                <Listbox.Button
                  ref={setTargetElement}
                  className="flex items-center w-full"
                >
                  <div
                    className={classNames(
                      "relative w-full min-h-[48px] text-left flex items-center justify-between focus:ring-0 transition-all duration-200  bg-white rounded-sm border text-sm font-medium",
                      dropdownButtonClass,
                      open ? "border-opplio-graySky" : "border-opplio-border"
                    )}
                  >
                    {selected && selected.length === 0 ? (
                      <div className="text-gray-400 !text-sm !font-light pl-3">
                        {placeholder}
                      </div>
                    ) : (
                      <div className="flex flex-wrap">
                        {selected &&
                          selected.map((item, index) => (
                            <div
                              key={item.value}
                              className="bg-opplio-gray mx-1 my-1 px-2 py-1 rounded-full flex items-center"
                            >
                              <span className="text-xs mr-1">{item.name}</span>
                              <button
                                type="button"
                                className="focus:outline-none ml-1"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleRemoveItem(item, index);
                                }}
                              >
                                <i className="fa-solid fa-times"></i>
                              </button>
                            </div>
                          ))}
                      </div>
                    )}
                    <div
                      className={classNames(
                        "text-sm text-opplio-graySky transition-all duration-200",
                        open ? "-rotate-180" : ""
                      )}
                    >
                      <i className={"fa-light fa-fw fa-chevron-down"}></i>
                    </div>
                  </div>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  className="z-50"
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                  beforeEnter={() => setPopperElement(popperElRef.current)}
                  afterLeave={() => setPopperElement(null)}
                  ref={popperElRef}
                  style={styles.popper}
                  {...attributes.popper}
                >
                  <Listbox.Options
                    className={classNames(
                      "absolute mt-1 border border-opplio-border w-40 origin-top-right rounded-md bg-white shadow-lg focus:outline-none py-1",
                      dropdownClass
                    )}
                  >
                    {isSearch && (
                      <div className="py-2 px-3">
                        <Search
                          placeholder={"Search"}
                          onChange={(e) => setSearchInput(e.target.value)}
                        />
                      </div>
                    )}
                    <div className="py-1 max-h-80 overflow-auto scrollbar">
                      {filteredData &&
                        filteredData.map((item, index) => (
                          <Listbox.Option
                            key={index}
                            className={({ active, selected }) =>
                              classNames(
                                "relative group flex w-full items-center px-3 py-1.5 pl-8 text-sm gap-2 transition-all duration-200 cursor-pointer",
                                active
                                  ? "bg-opplio-veryLightBlue text-opplio-blue"
                                  : "text-opplio-grayB",
                                selected ? "bg-gray-400" : "",
                                item.isDisabled
                                  ? "!bg-opplio-silver !cursor-not-allowed"
                                  : "",
                                item.class
                              )
                            }
                            value={item}
                            disabled={item.isDisabled}
                          >
                            {({ selected }) => (
                              <>
                                {item.icon && (
                                  <i
                                    className={classNames("fa-fw", item.icon)}
                                  ></i>
                                )}
                                <div>{item.name}</div>
                                {selected && (
                                  <div className="absolute inset-y-0 left-0 flex items-center pl-2 text-opplio-blue text-base">
                                    <i className="fa-regular fa-fw fa-check"></i>
                                  </div>
                                )}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                    </div>
                  </Listbox.Options>
                </Transition>
              </>
            )}
          </Listbox>
        ) : (
          <SkeletonLoader className="w-full h-10 rounded-md" />
        )}

        {errorType && errorType === "error" && (
          <div className="text-xs text-red-600 mt-1">
            <i className="fa-regular fa-fw fa-square-exclamation text-sm mr-1"></i>
            {errorText}
          </div>
        )}
        {errorType && errorType === "warning" && (
          <div className="text-xs text-amber-600 mt-1">
            <i className="fa-regular fa-fw fa-triangle-exclamation text-sm mr-1"></i>
            {errorText}
          </div>
        )}
        {errorType && errorType === "success" && (
          <div className="text-xs text-green-600 mt-1">
            <i className="fa-regular fa-fw fa-circle-check text-sm mr-1"></i>
            {errorText}
          </div>
        )}
        {errorType && errorType === "info" && (
          <div className="text-xs text-sky-600 mt-1">
            <i className="fa-regular fa-fw fa-circle-info text-sm mr-1"></i>
            {errorText}
          </div>
        )}
      </div>
    </>
  );
};

export default MultiSelect;
