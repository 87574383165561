import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import Image from "../components/elements/Image";
import Button from "../components/form/Button";
import NavigationPanel from "./NavigationPanel";
import Logo from "../assets/images/logoCube.png";
import { classNames } from "../helpers/classNames";
import { toast } from "react-toastify";
import LogoutModal from "../components/elements/LogoutModal";
import { useDispatch, useSelector } from "react-redux";
import { setAuthData } from "../store/slice/authSlice";
import { fetchLanguageList } from "../service/languageService";
import { setLanguages } from "../store/slice/languageSlice";
import { appointmentLang, sideBar } from "../components/elements/language";
import ukFlag from "../assets/images/flag/uk.png";
import spainFlag from "../assets/images/flag/spain.png";
import italyFlag from "../assets/images/flag/italy.png";
import { getProfileDetails } from "../service/authService";
import { setInitialProfileData } from "../store/slice/initialProfileSlice";
import { JoinCallModal } from "../components/elements/JoinCallModal";
import { JoinChatModal } from "../components/elements/JoinChatModal";
import { setCallerData } from "../store/slice/callingSlice";
import { useSocket } from "../components/socket/SocketProvider";
import music from "../assets/music/ringtone.mp3";
import { setConsultantStatus } from "../store/slice/consultantStatusSlice";
import LanguageDropdown from "../components/elements/LanguageDropdown";
import usePageVisibility from "../util/PageVisibility";
import useOnlineStatus from "../util/OnlineStatus";
import { appointmentClose } from "../service/videoCallService";
import { apiRequest } from "../util/async/apiUtils";
import dayjs from "dayjs";
import Checkbox from "../components/form/Checkbox";
import Cookies from "js-cookie";
import useSound from "use-sound";

const langConfig = {
  joinCall: {
    en: "Join The Call",
    es: "Únase a la llamada",
    it: "Partecipa alla chiamata",
  },
};

const DefaultHeader = ({ headerClass }) => {
  const [loaded, setLoaded] = useState(true);
  const tokenData = useSelector((state) => state?.tokenSlice?.data);
  const token = localStorage.getItem("auth_token") || Cookies.get("auth_token");
  const localProfileData = JSON.parse(localStorage.getItem("userDetails"));
  const userId = localProfileData?._id;
  const [scrolled, setScrolled] = useState(false);
  const [menuToggle, setMenuToggle] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [languageList, setLanguageList] = useState([]);
  const lang = useSelector((state) => state?.languageSlice?.data);
  const language = localStorage.getItem("language") || lang;
  const profile = useSelector((state) => state?.initialProfileSlice?.data);
  const [isOpen, setIsOpen] = useState(false);
  const [isChatModalOpen, setIsChatModalOpen] = useState(false);
  const callerData = useSelector((state) => state?.callingSlice?.data);
  const dispatch = useDispatch();
  const [sessionId, setSessionId] = useState(null);
  const [sessionToken, setSessionToken] = useState(null);

  const audioRef = useRef(new Audio(music));
  const [play, { stop }] = useSound(music, { loop: true });

  const socket = useSocket();
  const [busyConsultants, setBusyConsultants] = useState([]);
  const [appointment, setAppointment] = useState(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isJoinCall, setIsJoinCall] = useState(false);
  const localAppointmentData = localStorage.getItem("appointmentData");
  const [appointmenData, setAppointmenData] = useState(null);
  const roleCode = JSON.parse(localStorage.getItem("userDetails"))?.roleCode;
  const [toastShown, setToastShown] = useState(false);
  const [appointmentProfile, setAppointmentProfile] = useState(null);
  const [onCall, setOnCall] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  //---------------Notification Loader----------------------
  const [notificationToggle, setNotificationToggle] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [page, setPage] = useState(1);
  const [notificationData, setNotificationData] = useState([]);
  const [totalNotificationLength, setTotalNotificationLength] = useState(null);
  const [notificationLoader, setNotificationLoader] = useState(false);
  const [selectedNotifications, setSelectedNotifications] = useState([]);
  const [totalUnseenNotification, setTotalUnseenNotification] = useState(null);
  const [seenLoader, setSeenLoader] = useState(false);
  const [disconnectedProfile, setDisconnectedProfile] = useState(null);
  const gmtOffset = localProfileData?.personal?.timeZone?.gmtOffsetName;

  const onlineHit = useCallback(() => {
    if (userId) {
      socket && socket.emit("userOnline", userId);
    }
  }, [socket, userId]);

  useEffect(() => {
    onlineHit();
  }, []);

  useEffect(() => {
    if (!token) {
      localStorage.clear();
    }
  }, [token, navigate]);

  const convertUTCToMinutes = (gmtOffsetName) => {
    const offsetString = gmtOffsetName.replace("UTC", "");
    const match = offsetString.match(/([+-]\d{2}):(\d{2})/);
    if (!match) return 0;
    const [, hours, minutes] = match;
    return parseInt(hours) * 60 + parseInt(minutes);
  };

  const fetchNotifications = useCallback(async () => {
    setNotificationLoader(true);
    try {
      const res = await apiRequest("get", "/notification/list", {
        queries: {
          offset: convertUTCToMinutes(gmtOffset), //new Date().getTimezoneOffset(),
          limit: 10,
          page,
        },
      });

      if (res?.data?.docs?.status === 200) {
        setNotificationData((prevList) =>
          page === 1 ? res.data.docs.data : [...prevList, ...res.data.docs.data]
        );

        setHasNextPage(res.data.docs.metadata.hasNextPage);
        setTotalNotificationLength(res.data.docs.metadata.totalDocs);
        setTotalUnseenNotification(res.data.docs.metadata.unseen);
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
    } finally {
      setNotificationLoader(false);
    }
  }, [page]);

  useEffect(() => {
    token && fetchNotifications();
  }, [page]);

  const handleNotificationSeenStatus = async () => {
    setSeenLoader(true);
    let res = await apiRequest("put", "/notification/update", {
      body: {
        id: selectedNotifications,
      },
    });
    setSeenLoader(false);

    if (res?.data?.status === 200) {
      setTotalUnseenNotification(
        totalUnseenNotification - selectedNotifications.length
      );
      setSelectedNotifications([]);
      toast.success(res?.data?.message, { autoClose: 3000 });
      fetchNotifications();
    }
  };
  const [allSeenLoader, setAllSeenLoader] = useState(false);
  const handleAllSeenNotification = async () => {
    try {
      setAllSeenLoader(true);
      let res = await apiRequest("put", "/notification/update", {
        body: {
          id: [],
        },
      });
      setAllSeenLoader(false);
      if (res?.data?.status === 200) {
        setSelectedNotifications([]);
        toast.success(res?.data?.message, { autoClose: 3000 });
        fetchNotifications();
      } else {
        toast.error(res?.data?.message, { autoClose: 3000 });
      }
    } catch (error) {
      setAllSeenLoader(false);
    }
  };

  //--------------------------------------------------------

  //--------------Already in call------------------------
  useSocket("userAlreadyInCallResponse", (data) => {
    if (
      data?.appointmentId === appointmenData?.appointmentId &&
      data?.userId === localProfileData?._id &&
      data?.roleCode === roleCode
    ) {
      if (data?.disconnect === true) {
        setOnCall(false);
      } else {
        setOnCall(true);
      }
    }
  });
  //-------------------*******--------------------------

  // useEffect(() => {
  //   localAppointmentData && setAppointmenData(JSON.parse(localAppointmentData));
  // }, []);

  useSocket(
    "userDisconnectFromCallNotifyResponse",
    (data) => {
      data &&
        data?.userId === localProfileData?._id &&
        setDisconnectedProfile(data);
      if (
        data?.disconnected &&
        !appointmenData &&
        localProfileData &&
        data?.userId == localProfileData?._id &&
        data?.sessionId === appointmenData?.sessionId
      ) {
        localStorage.setItem(
          "appointmentData",
          JSON.stringify(appointmentProfile)
        );
        setIsJoinCall(true);
      } else if (
        !data?.disconnected &&
        appointmenData &&
        localProfileData &&
        data?.userId === localProfileData._id
      ) {
        setIsJoinCall(false);
      }
    },
    [socket]
  );

  const handleLogOut = () => {
    if (socket) {
      socket.emit("userOffline", profile?._id);
    } else if (!socket) {
      toast.error("Network error. Please reload your browser once!");
      window.location.reload();
    }
    dispatch(setAuthData({}));
    appointmenData &&
      appointmenData?.appointmentId &&
      appointmentClose({ appointmentId: appointmenData?.appointmentId }).then(
        (res) => {
          socket &&
            socket.emit("videoSessionDisconnect", {
              disconnect: true,
              appointmentId: appointmenData?.appointmentId,
            });

          socket &&
            socket.emit("chatDisconnect", {
              disconnect: true,
              appointmentId: appointmenData?.appointmentId,
            });

          socket &&
            localProfileData?.roleCode == "consultant" &&
            socket.emit("consultantBusy", {
              consultantId: localProfileData?._id,
              userId: appointmenData?.callerData?.user?.id,
              isBusy: false,
            });

          socket.emit("userDisconnectFromCallNotify", {
            disconnected: false,
            sessionId: appointmenData.sessionId,
            userId: localProfileData?._id,
            appointmentId: appointmenData?.appointmentId,
          });
        }
      );

    localStorage.removeItem("auth_token");
    Cookies.remove("auth_token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("userDetails");
    localStorage.removeItem("appointmentData");
    localStorage.removeItem("joined");
    toast.success("You are logged out!");
    setLoaded(false);
    setTimeout(() => {
      setShowModal(false);
      setLoaded(true);
    }, 500);
  };

  const fetchProfileDetails = useCallback(() => {
    if (!token) return;
    getProfileDetails(token).then((response) => {
      // if (response?.data?.status !== 200) {
      //   // alert("from default header, fetch profile data");
      //   handleLogOut();
      // }

      if (response?.data?.status === 200) {
        if (!response?.data?.docs?.appointmentData?._id) {
          setAppointmenData(null);
        }

        if (
          response?.data?.docs?.appointmentData?.consultantStatus ===
            "notJoin" &&
          response?.data?.docs?.ringingStatus?.status === true &&
          roleCode === "consultant"
        ) {
          setSessionToken(response?.data?.docs?.appointmentData?.sessionToken);
          setSessionId(response?.data?.docs?.appointmentData?.sessionId);
          const Data = {
            user: {
              name:
                response?.data?.docs?.appointmentData?.clientDetails
                  ?.firstName +
                " " +
                response?.data?.docs?.appointmentData?.clientDetails?.lastName,
              email:
                response?.data?.docs?.appointmentData?.clientDetails?.email,
              image:
                response?.data?.docs?.appointmentData?.clientDetails?.image,
              id: response?.data?.docs?.appointmentData?.clientDetails?._id,
            },
            consultant: {
              name:
                response?.data?.docs?.appointmentData?.consultantDetails
                  ?.firstName +
                " " +
                response?.data?.docs?.appointmentData?.consultantDetails
                  ?.lastName,
              email:
                response?.data?.docs?.appointmentData?.consultantDetails?.email,
              image:
                response?.data?.docs?.appointmentData?.consultantDetails?.image,
              id: response?.data?.docs?.appointmentData?.consultantDetails?._id,
            },
            mode: response?.data?.docs?.appointmentData?.service?.mode?.slug,
          };
          const finalData = {
            appointmentId: response?.data?.docs?.appointmentData?._id,
            consultantId: response?.data?.docs?.appointmentData?.consultantId,
            clientId: response?.data?.docs?.appointmentData?.clientId,
            appointmentStartTime:
              response?.data?.docs?.appointmentData?.consultantJoinTime,
            mode: response?.data?.docs?.appointmentData?.service?.mode?.slug,
            callerData: { ...Data },
            user: {
              name:
                response?.data?.docs?.appointmentData?.clientDetails
                  ?.firstName +
                " " +
                response?.data?.docs?.appointmentData?.clientDetails?.lastName,
              email:
                response?.data?.docs?.appointmentData?.clientDetails?.email,
              image:
                response?.data?.docs?.appointmentData?.clientDetails?.image,
              id: response?.data?.docs?.appointmentData?.clientDetails?._id,
            },
            consultant: {
              name:
                response?.data?.docs?.appointmentData?.consultantDetails
                  ?.firstName +
                " " +
                response?.data?.docs?.appointmentData?.consultantDetails
                  ?.lastName,
              email:
                response?.data?.docs?.appointmentData?.consultantDetails?.email,
              image:
                response?.data?.docs?.appointmentData?.consultantDetails?.image,
              id: response?.data?.docs?.appointmentData?.consultantDetails?._id,
            },
            sessionId: response?.data?.docs?.appointmentData?.sessionId,
            token: response?.data?.docs?.appointmentData?.sessionToken,
            paymentMode: response?.data?.docs?.appointmentData?.paymentMode,
          };
          if (finalData.appointmentId) {
            dispatch(setCallerData(finalData));
            setAppointmentProfile(finalData);
            setAppointmenData(finalData);
            localStorage.setItem("appointmentData", JSON.stringify(finalData));
          } else {
            setAppointmenData(null);
          }
          response?.data?.docs?.ringingStatus?.mode === "chat"
            ? setIsChatModalOpen(true)
            : setIsOpen(true);
        } else if (
          response?.data?.docs?.appointmentData?.consultantStatus ===
            "notJoin" &&
          response?.data?.docs?.ringingStatus?.status === false
        ) {
          socket &&
            socket.emit("consultantBusy", {
              consultantId:
                response?.data?.docs?.appointmentData?.consultantDetails?._id ||
                "",
              userId: response?.data?.docs?.appointmentData?.clientDetails?._id,
              isBusy: false,
            });
          localStorage.removeItem("appointmentData");
          localStorage.removeItem("callPageLeaveFlag");
          localStorage.removeItem("joined");
        }

        if (response?.data?.docs?._id) {
          localStorage.setItem(
            "userDetails",
            JSON.stringify(response?.data?.docs)
          );
        }
        if (
          response?.data?.docs?.appointmentData?._id &&
          response?.data?.docs?.appointmentData?.status === "inProgress"
        ) {
          const appointment = {
            appointmentId: response?.data?.docs?.appointmentData?._id,
            appointmentStartTime:
              response?.data?.docs?.appointmentData?.details
                ?.appointmentStartTime,
            callerData: {
              consultant: {
                id: response?.data?.docs?.appointmentData?.consultantId,
                image:
                  response?.data?.docs?.appointmentData?.consultantDetails
                    ?.image,
                name:
                  response?.data?.docs?.appointmentData?.consultantDetails
                    ?.firstName +
                  " " +
                  response?.data?.docs?.appointmentData?.consultantDetails
                    ?.lastName,
              },
              user: {
                id: response?.data?.docs?.appointmentData?.clientId,
                image:
                  response?.data?.docs?.appointmentData?.clientDetails?.image,
                name:
                  response?.data?.docs?.appointmentData?.clientDetails
                    ?.firstName +
                  " " +
                  response?.data?.docs?.appointmentData?.clientDetails
                    ?.lastName,
              },
            },
            consultantId: response?.data?.docs?.appointmentData?.consultantId,
            mode: response?.data?.docs?.appointmentData?.service?.mode?.slug,
            paymentMode: response?.data?.docs?.appointmentData?.paymentMode,
            sessionId: response?.data?.docs?.appointmentData?.sessionId,
            token: response?.data?.docs?.appointmentData?.sessionToken,
          };
          appointment.appointmentId && setAppointmentProfile(appointment);
          appointment.appointmentId && setAppointmenData(appointment);
          appointment.appointmentId &&
            localStorage.setItem(
              "appointmentData",
              JSON.stringify(appointment)
            );
        } else {
          socket &&
            socket.emit("consultantBusy", {
              consultantId: appointmenData?.callerData?.consultant?.id || "",
              userId: appointmenData?.callerData?.user?.id,
              isBusy: false,
            });
          localStorage.removeItem("appointmentData");
          localStorage.removeItem("callPageLeaveFlag");
          localStorage.removeItem("joined");
        }
      }
    });
    dispatch(setInitialProfileData(localProfileData));
  }, [token, dispatch, roleCode, socket]);

  useEffect(() => {
    fetchProfileDetails();
  }, []);

  useEffect(() => {
    if (appointmenData?.appointmentId) {
      setIsJoinCall(true);
    } else {
      setIsJoinCall(false);
    }
  }, [appointmenData, localAppointmentData]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const profileData = {
    image: profile?.image?.url,
    userName: (profile?.firstName || "") + " " + (profile?.lastName || ""),
    userEmail: profile?.email,
  };

  const menuData = [
    {
      _id: 1,
      name: sideBar?.Home_Label?.[language],
      link: "/",
      click: () => setMenuToggle(false),
    },
    {
      _id: 2,
      name: sideBar?.Find_a_Psychologist_Label?.[language],
      link: `${token ? "/find-psychologist" : "/signin"}`,
      click: () => setMenuToggle(false),
    },
    {
      _id: 5,
      name: sideBar?.Area_of_Intervention_Label?.[language],
      link: "/area-of-intervention",
      click: () => setMenuToggle(false),
    },
    {
      _id: 8,
      name: sideBar?.Blog_Label?.[language],
      link: "/blog",
      click: () => setMenuToggle(false),
    },
    {
      _id: 12,
      name: sideBar?.Contact_us_Label?.[language],
      link: "/contact-us",
      click: () => setMenuToggle(false),
    },
    {
      _id: 13,
      name: token ? "Log Out" : "Sign In",
      link: !token && "/signin",
      click: () => {
        setMenuToggle(false);
        if (token) {
          setShowModal(true);
        }
      },
    },
  ];

  const handleScroll = () => {
    if (menuToggle) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  };

  useEffect(() => {
    function handleScroll() {
      const isScrolled = window.scrollY > 100;
      setScrolled(isScrolled);
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    fetchLanguageList(setLanguageList, setLoaded);
  }, []);

  useEffect(() => {
    handleScroll();
  }, [menuToggle]);

  useEffect(() => {
    if (!localStorage.getItem("auth_token")) {
      // alert("from default header 2");
      dispatch(setAuthData({}));
      localStorage.removeItem("auth_token");
      Cookies.remove("auth_token");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("userDetails");
    }
  }, [token]);

  const closeModal = () => setShowModal(false);

  let startTime;
  let loopTimeout;

  useEffect(() => {
    // const startPlaying = () => {
    //   if (!audioRef.current) return;
    //   startTime = Date.now();
    //   audioRef.current.play().catch((err) => {
    //     console.error("Error playing audio:", err);
    //   });
    //   audioRef.current.onended = () => {
    //     const currentTime = Date.now();
    //     const elapsedTime = (currentTime - startTime) / 1000;
    //     if (elapsedTime < 60) {
    //       audioRef.current.currentTime = 0;
    //       audioRef.current.play().catch((err) => {
    //         console.error("Error replaying audio:", err);
    //       });
    //     } else {
    //       audioRef.current.pause();
    //       audioRef.current.currentTime = 0;
    //     }
    //   };
    // };
    // const handleAudioPlay = () => {
    //   if (audioRef.current) {
    //     startPlaying();
    //     loopTimeout = setTimeout(() => {
    //       audioRef.current.pause();
    //       audioRef.current.currentTime = 0;
    //     }, 60000);
    //   }
    // };
    if (isOpen || isChatModalOpen) {
      // if (audioRef.current) {
      //   if (audioRef.current.readyState >= 3) {
      //     handleAudioPlay();
      //   } else {
      //     audioRef.current.addEventListener("canplaythrough", handleAudioPlay, {
      //       once: true,
      //     });
      //   }
      // }
      play();
    } else {
      clearTimeout(loopTimeout);
      stop();
      // if (audioRef.current) {
      //   audioRef.current.pause();
      //   audioRef.current.currentTime = 0;
      // }
    }
    return () => {
      clearTimeout(loopTimeout);
      stop();
      // if (audioRef.current) {
      //   audioRef.current.pause();
      //   audioRef.current.currentTime = 0;
      //   audioRef.current.removeEventListener("canplaythrough", handleAudioPlay);
      //   audioRef.current.onended = null;
      // }
    };
    // play();
  }, [isOpen, isChatModalOpen]);

  useEffect(() => {
    dispatch(setConsultantStatus(busyConsultants));
  }, [busyConsultants]);

  useSocket("consultantCallResponse", async (data) => {
    if (localProfileData && localProfileData._id === data?.consultant?.id) {
      dispatch(setCallerData(data));
      setIsOpen(true);
      localStorage.setItem("appointmentData", JSON.stringify(data));
    }
  });

  useSocket("chatSessionResponse", async (data) => {
    if (localProfileData && localProfileData?._id === data?.consultantId) {
      dispatch(setCallerData(data));
      localStorage.setItem("appointmentData", JSON.stringify(data));
      setAppointment(data);
      setIsChatModalOpen(true);
    }
  });

  useSocket(
    "chatJoinConfirmResponse",
    async (data) => {
      if (
        data?.joined === true &&
        data?.consultantId === localProfileData?._id
      ) {
        setIsChatModalOpen(false);
      }
    },
    [socket]
  );

  useSocket("chatSessionRejectResponse", async (data) => {
    if (!data?.disconnect) return;

    const isCurrentUserInvolved =
      localProfileData?._id === data?.userId ||
      localProfileData?._id === data?.consultantId;
    if (!isCurrentUserInvolved) return;

    if (data?.role !== "user") {
      setIsChatModalOpen(false);
      localStorage.removeItem("appointmentData");
      setAppointmenData(null);
      localStorage.removeItem("joined");
      setIsJoinCall(false);
      roleCode == "user" &&
        toast.info("Consultant is not available try to call again later.", {
          autoClose: 5000,
        });
    }
  });

  useSocket("vonageTokBoxSessionResponse", (data) => {
    if (data?.callerData?.consultant?.id === localProfileData?._id) {
      setSessionId(data?.sessionId);
      setSessionToken(data?.token);
      setAppointment(data);
      localStorage.setItem("appointmentData", JSON.stringify(data));
    }
  });

  useSocket("consultantBusyResponse", (data) => {
    setBusyConsultants((prevConsultants) => {
      const consultantMap = new Map(
        prevConsultants.map((consultant) => [
          consultant.consultantId,
          consultant,
        ])
      );
      consultantMap.set(data.consultantId, data);
      return Array.from(consultantMap.values());
    });
  });

  useSocket("videoSessionDisconnectResponse", async (data) => {
    if (data?.disconnect === true) {
      localStorage.removeItem("appointmentData");
      localStorage.removeItem("joined");
      setAppointmenData(null);
      setIsJoinCall(false);
    }
  });

  useSocket("vonageSessionRejectResponse", async (data) => {
    if (data?.disconnect === true && data?.userId == localProfileData?._id) {
      setIsJoinCall(false);
      localStorage.removeItem("appointmentData");
      localStorage.removeItem("joined");
      setAppointmenData(null);
      if (!toastShown && data?.role !== "user") {
        setToastShown(true);
        roleCode === "user" &&
          toast.info("Consultant is not available, try to call again later.", {
            autoClose: 10000,
          });
      }
    }
  });

  usePageVisibility(token, socket, profile?._id);

  //---------------online Status--------------------------
  const isOnline = useOnlineStatus();
  const timeoutRef = useRef(null);
  useEffect(() => {
    const emitUserOffline = () => {
      if (document.visibilityState === "hidden" && socket && profile) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
          if (!appointment) {
            socket.emit("userOffline", profile?._id);
          }
        }, 5 * 60 * 1000);
      } else {
        clearTimeout(timeoutRef.current);
      }
    };

    document.addEventListener("visibilitychange", emitUserOffline);

    return () => {
      document.removeEventListener("visibilitychange", emitUserOffline);
      clearTimeout(timeoutRef.current);
    };
  }, [profile, socket, appointment]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      socket && profile && socket.emit("userPing", { userId: profile._id });
    }, 3 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, [socket, profile]);
  //=========================================================

  useSocket("appointmentPingResponse", (dataa) => {
    if (
      appointmenData &&
      appointmenData?.appointmentId === dataa?.appointmentId &&
      localProfileData?._id === dataa?.id
    ) {
      setOnCall(true);
    }
  });

  return (
    <>
      <header
        className={classNames(
          "fixed top-0 left-0 w-full z-20 bg-transparent flex flex-col items-center transition-all duration-200",
          isJoinCall
            ? scrolled
              ? "md:h-32 h-28 bg-white shadow-md"
              : "md:h-40 h-32"
            : scrolled
            ? "md:h-24 h-20 bg-white shadow-md"
            : "md:h-32 h-20",
          headerClass
        )}
      >
        {!isOnline && (
          <div className="relative w-full bg-red-600 md:px-8 pl-1 pr-4 flex items-center h-10 text-center justify-center">
            <h2 className="text-center">You are offline!</h2>
          </div>
        )}
        {token && appointmenData && appointmenData?.appointmentId && (
          <div className="relative w-full bg-prontopsy-blue md:px-8 pl-1 pr-4 flex items-center h-10">
            <div className="w-full md:px-4 px-2 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto flex items-center justify-between">
              <div className="flex items-center w-full">
                <div className="flex gap-5 w-full items-center">
                  <div className="text-prontopsy-blue lg:text-xl text-base lg:w-8 w-7 aspect-square md:flex justify-center items-center bg-white rounded-full hidden">
                    <i class="fa-sharp fa-solid fa-bell"></i>
                  </div>

                  <div className="w-full text-white lg:text-sm md:text-xs text-sm animate-pulse">
                    {` ${appointmentLang?.inProgressAppointment?.[language]}
                          ${
                            roleCode === "consultant"
                              ? appointmenData?.callerData?.user?.name
                              : appointmenData?.callerData?.consultant?.name
                          }`}
                  </div>
                </div>
                <div className="lg:w-full w-6/12 flex justify-end">
                  <Button
                    buttonClasses="md:!px-10 md:px-6 !px-2 !bg-prontopsy-green animate-pulse !h-8"
                    buttonLabel={`${langConfig?.joinCall?.[language]}`}
                    buttonLabelClasses=""
                    buttonHasLink={false}
                    buttonType="button"
                    buttonFunction={() => {
                      setLoading(true);
                      getProfileDetails(token)
                        .then((response) => {
                          if (response?.data?.status === 200) {
                            localStorage.setItem(
                              "userDetails",
                              JSON.stringify(response?.data?.docs)
                            );
                            if (
                              !response?.data?.docs?.appointmentData
                                ?.appointmentId
                            ) {
                              setAppointmenData(null);
                            }
                            const appointmentId =
                              response?.data?.docs?.appointmentData?._id;
                            const mode =
                              response?.data?.docs?.appointmentData?.service
                                ?.mode?.slug;

                            if (appointmentId) {
                              const targetPath =
                                mode === "chat"
                                  ? `/text-chat/${appointmentId}`
                                  : `/chats/${appointmentId}`;

                              if (
                                response?.data?.docs?.appointmentData?.service
                                  ?.service?.slug ==
                                "direct-access-online-psychological-counseling"
                              ) {
                                window.location.href = targetPath;
                              } else {
                                socket &&
                                  socket.emit(
                                    "indirectAppointmentConfirmJoin",
                                    {
                                      userId: localProfileData?._id,
                                      appointmentId: appointmentId,
                                      joined: true,
                                      mode: mode,
                                      data: {
                                        clientId:
                                          response?.data?.docs?.appointmentData
                                            ?.clientId,
                                        consultantId:
                                          response?.data?.docs?.appointmentData
                                            ?.consultantId,
                                      },
                                    }
                                  );
                                window.location.href = targetPath;
                              }
                            } else {
                              console.error("Invalid appointment ID.");
                            }

                            setLoading(false);
                          } else {
                            setLoading(false);
                            console.error(
                              "Failed to retrieve profile details or invalid response status."
                            );
                          }
                        })
                        .catch((error) => {
                          setLoading(false);
                          console.error(
                            "An error occurred while fetching profile details:",
                            error
                          );
                        });
                    }}
                    isDisable={loading}
                    loading={loading}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <div className=" flex items-center w-full my-auto">
          <div className="w-full px-4 sm:max-w-xl md:max-w-3xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto flex items-center">
            <div
              className={classNames(
                "relative transition-all duration-200",
                scrolled ? "h-10 md:h-12 lg:h-16" : "h-10 md:h-16 lg:h-20"
              )}
            >
              <Link to={"/"} className="flex w-full h-full">
                <Image
                  src={Logo}
                  alt={"Prontopsy Logo"}
                  effect={"blur"}
                  className={"object-contain"}
                />
              </Link>
            </div>
            <div className="flex ml-auto space-x-3">
              <div className="relative">
                <LanguageDropdown
                  lang={language}
                  setLanguages={setLanguages}
                  languageList={languageList}
                  ukFlag={ukFlag}
                  spainFlag={spainFlag}
                  italyFlag={italyFlag}
                  dropdownClasses={screenWidth < 480 ? "min-w-40" : "min-w-32"}
                />
              </div>
              {!token && (
                <Button
                  buttonHasLink={true}
                  // buttonLink={"/signin"}
                  buttonFunction={() => (window.location.href = "/signin")}
                  buttonLabel={"Signin"}
                  buttonLabelClasses={"md:block hidden"}
                  buttonIcon={"fa-light fa-user-plus"}
                  buttonIconPosition={"left"}
                  buttonClasses={"uppercase text-sm hover:!bg-prontopsy-blue"}
                />
              )}

              <Button
                buttonHasLink={false}
                buttonIcon={"fa-solid fa-bars-staggered"}
                buttonIconPosition={"left"}
                buttonClasses={
                  "!px-0 text-xl aspect-square !rounded-full !bg-prontopsy-yellow !text-black hover:!bg-prontopsy-blue hover:!text-prontopsy-yellow"
                }
                buttonFunction={() => setMenuToggle(true)}
              />
            </div>
            {token && (
              <div className="flex items-center space-x-2 ml-2">
                <div
                  className="md:w-16 md:h-16 h-12 w-12 rounded-full overflow-hidden flex"
                  onClick={() => navigate("/profile")}
                >
                  <Image
                    src={profileData && profileData?.image}
                    alt={""}
                    effect={"blur"}
                    className={"!object-cover w-full h-full cursor-pointer"}
                  />
                </div>
                <div
                  className="text-sm  font-semibold text-prontopsy-blue hidden md:block cursor-pointer"
                  onClick={() => navigate("/profile")}
                >
                  {profileData.userName ? profileData?.userName : ""}
                </div>

                <div className="relative !ml-0">
                  <Button
                    buttonIcon={"fa-solid fa-bell"}
                    buttonIconPosition={"left"}
                    buttonClasses={
                      "!px-0 !text-2xl aspect-square !text-prontopsy-blue !bg-transparent"
                    }
                    buttonFunction={() => {
                      setSelectedNotifications([]);
                      token && fetchNotifications();
                      setNotificationToggle(!notificationToggle);
                    }}
                  />
                  {/* -----------------Number of notifications-------------------- */}
                  {totalUnseenNotification > 0 && (
                    <div
                      onClick={() => setNotificationToggle(!notificationToggle)}
                      className="absolute cursor-pointer top-0 right-0 bg-prontopsy-pink w-5 text-white text-xs aspect-square rounded-full flex justify-center items-center"
                    >
                      {/* {totalNotificationLength} */}
                      {totalUnseenNotification}
                    </div>
                  )}
                </div>
                {notificationToggle && (
                  <div className="absolute md:right-32 right-2 top-24 md:w-[400px] w-[350px] bg-white rounded-lg shadow-lg ring-1 ring-gray-200 z-50">
                    {/* Header */}
                    <div className="p-4 border-b border-gray-200 flex items-center justify-between bg-prontopsy-pink text-white rounded-t-md">
                      <h2 className="text-lg font-semibold text-white">
                        Notifications
                      </h2>
                      {/* {selectedNotifications.length > 0 && ( 
                      <div className="flex gap-1 items-center justify-end">
                       <button
                            className={`px-1 py-1 w-[60px] flex items-center justify-center rounded-md ${
                              seenLoader
                                ? "bg-prontopsy-lightblue cursor-not-allowed"
                                : "bg-prontopsy-lightblue"
                            }`}
                            onClick={
                              seenLoader ? null : handleNotificationSeenStatus
                            }
                            disabled={seenLoader}
                          >
                            {seenLoader ? (
                              <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white"></div>
                            ) : (
                              "Seen"
                            )}
                          </button> 

                         <button
                          className={`px-1 py-1 w-[80px] flex items-center justify-center rounded-md ${
                            seenLoader
                              ? "bg-prontopsy-darkblue cursor-not-allowed"
                              : "bg-prontopsy-darkblue"
                          }`}
                          onClick={
                            allSeenLoader ? null : handleAllSeenNotification
                          }
                          disabled={allSeenLoader}
                        >
                          {allSeenLoader ? (
                            <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white"></div>
                          ) : (
                            "Read All"
                          )}
                        </button> 
                      </div>
                      )} */}

                      {notificationData.length > 0 && (
                        <button
                          className={`px-1 py-1 w-[80px] flex items-center justify-center rounded-md ${
                            seenLoader
                              ? "bg-prontopsy-lightblue cursor-not-allowed"
                              : "bg-prontopsy-darkblue"
                          }`}
                          onClick={
                            allSeenLoader ? null : handleAllSeenNotification
                          }
                          disabled={allSeenLoader}
                        >
                          {allSeenLoader ? (
                            <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white"></div>
                          ) : (
                            "Read All"
                          )}
                        </button>
                      )}

                      <button
                        className="text-2xl cursor-pointer"
                        onClick={() => {
                          setNotificationToggle(false);
                          setSelectedNotifications([]);
                        }}
                      >
                        <i className="fa-light fa-xmark"></i>
                      </button>
                    </div>

                    {/* Notification List */}
                    <div className="overflow-y-auto max-h-[calc(100vh-18rem)] bg-white rounded-md shadow-sm">
                      {notificationData.length > 0 ? (
                        notificationData.map((notification) => (
                          <div
                            className={`flex items-center pl-4 pr-2 py-3 transition border-b last:border-none ${
                              selectedNotifications.includes(
                                notification._id
                              ) || notification?.seenStatus
                                ? "bg-gray-200"
                                : "hover:bg-gray-200"
                            }`}
                            key={notification._id}
                          >
                            {/* <Checkbox
                              checkboxLabelClass="!font-RobotoSlab mr-5"
                              checkboxInputClass="mr-2"
                              isChecked={
                                selectedNotifications.includes(
                                  notification?._id
                                ) || notification?.seenStatus
                              }
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setSelectedNotifications((pre) => [
                                    ...pre,
                                    notification._id,
                                  ]);
                                } else {
                                  setSelectedNotifications((pre) =>
                                    pre.filter((id) => id !== notification._id)
                                  );
                                }
                              }}
                            /> */}
                            <div
                              className="flex-1 cursor-pointer"
                              onClick={async () => {
                                const isUnseenOrNotSelected =
                                  !selectedNotifications.includes(
                                    notification?._id
                                  );

                                // setSelectedNotifications(() => [
                                //   notification._id,
                                // ]);
                                await apiRequest(
                                  "put",
                                  "/notification/update",
                                  {
                                    body: {
                                      id: [notification?._id],
                                    },
                                  }
                                );
                                if (
                                  notification?.type ===
                                  "notification-appointment"
                                ) {
                                  notification.url !== null &&
                                    // window.open(notification.url, "_blank");
                                    window.open(notification?.url);
                                  setNotificationToggle(false);
                                } else if (
                                  notification?.type === "notification-chat"
                                ) {
                                  navigate(
                                    `/email-details/${notification?.chatId}?consultantId=${notification?.fromUser?._id}`
                                  );
                                  setNotificationToggle(false);
                                }
                              }}
                            >
                              <p className="text-sm text-gray-700">
                                {notification.text}
                              </p>
                              <div className="text-right mt-1">
                                <span className="text-xs text-gray-500">
                                  {dayjs(notification.createdAt).format(
                                    "MMMM D, YYYY h:mm A"
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="p-4 text-gray-600 text-sm text-center">
                          No notifications
                        </div>
                      )}

                      {notificationData.length > 0 &&
                        notificationData.length < totalNotificationLength && (
                          <div>
                            <Button
                              buttonLabel="Load More"
                              buttonClasses="w-full rounded-none bg-blue-500 text-white hover:bg-blue-600 transition disabled:bg-gray-300"
                              buttonFunction={() =>
                                hasNextPage &&
                                setPage((prevPage) => prevPage + 1)
                              }
                              isDisable={notificationLoader}
                              loading={notificationLoader}
                            />
                          </div>
                        )}
                    </div>

                    {/* Footer Button */}
                    {/* <button
                      className="w-full p-2 text-sm text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-b-lg"
                      onClick={() => setNotificationToggle(false)}
                    >
                      Close
                    </button> */}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </header>

      <NavigationPanel
        data={menuData}
        toggle={menuToggle}
        setToggle={setMenuToggle}
      />
      <LogoutModal
        show={showModal}
        onClose={closeModal}
        onConfirm={handleLogOut}
        loaded={loaded}
      />

      <JoinCallModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        data={callerData?.user}
        callerData={callerData}
        sessionId={sessionId}
        sessionToken={sessionToken}
        audioRef={audioRef}
        // stop={stop}
      />
      <JoinChatModal
        isOpen={isChatModalOpen}
        setIsOpen={setIsChatModalOpen}
        appointmentData={appointment || callerData}
        callerData={callerData}
        audioRef={audioRef}
        // stop={stop}
      />
    </>
  );
};

export default DefaultHeader;
