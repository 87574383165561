import React, { useEffect, useState } from "react";
import { setTabSelection } from "../../store/slice/selectedTabSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchDashboard } from "../../service/dashboardService";
import SpinLoader from "../../components/elements/table/SpinLoader";
import { dashboardLang } from "../../components/elements/language";
import Cookies from "js-cookie";

const ControlPanel = () => {
  const [data, setData] = useState({});
  const [loaded, setLoaded] = useState(true);
  const dispatch = useDispatch();
  const token = localStorage.getItem("auth_token") || Cookies.get("auth_token");
  const lang = useSelector((state) => state?.languageSlice?.data);
  const language = localStorage.getItem("language") || lang;

  useEffect(() => {
    dispatch(setTabSelection({}));
    fetchDashboard(token, setData, setLoaded);
  }, [dispatch, token]);

  return (
    <div className="relative p-4">
      {!loaded ? (
        <SpinLoader />
      ) : (
        <div className="flex xl:flex-nowrap flex-wrap gap-4 w-full">
          <div className="border shadow-md flex flex-row gap-2 w-full md:w-[48%] lg:w-[32%] p-4 rounded-lg items-center">
            <div className="2xl:w-16 2xl:h-16 w-14 h-14 bg-slate-400 flex items-center justify-center rounded-full">
              <i className="fa-regular fa-calendar-check text-white text-xl"></i>
            </div>
            <div className="flex flex-col justify-center p-3">
              <h1 className="text-lg lg:text-xl font-bold">
                {data?.appointmentCountEnded}
              </h1>
              <p className="text-sm 2xl:whitespace-nowrap">
                {dashboardLang?.pastAppointments?.[language]}
              </p>
            </div>
          </div>

          <div className="border shadow-md flex flex-row gap-2 w-full md:w-[48%] lg:w-[32%] p-4 rounded-lg items-center">
            <div className="2xl:w-16 2xl:h-16 w-14 h-14 bg-slate-400 flex items-center justify-center rounded-full flex-shrink-0">
              <i className="fa-regular fa-clock text-white text-xl"></i>
            </div>
            <div className="flex flex-col justify-center p-3">
              <h1 className="text-lg lg:text-xl font-bold">
                {data?.appointmentCountUpcoming}
              </h1>
              <p className="text-sm 2xl:whitespace-nowrap">
                {dashboardLang?.upcomingAppointments?.[language]}
              </p>
            </div>
          </div>

          <div className="border shadow-md flex flex-row gap-2 w-full lg:w-[32%] md:w-[48%] p-4 rounded-lg items-center">
            <div className="2xl:w-16 2xl:h-16 w-14 h-14 bg-slate-400 flex items-center justify-center rounded-full">
              <i className="fa-regular fa-message text-white text-xl"></i>
            </div>
            <div className="flex flex-col justify-center p-3">
              <h1 className="text-lg lg:text-xl font-bold">
                {data?.reviewCount}
              </h1>
              <span className="text-sm 2xl:whitespace-nowrap">
                {dashboardLang?.reviews?.[language]}
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ControlPanel;
