import { apiRequest } from "../util/async/apiUtils";

export const fetchTextMessageList = async (id, params) => {
  try {
    const res = await apiRequest(
      `${"get"}`,
      `${`/communicate/chat-load-message`}`,
      {
        queries: {
          appointmentId: id,
          ...params,
        },
      },
      "multipart/form-data"
    );
    return res;
  } catch (err) {
    return err;
  }
};
