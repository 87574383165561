import { useState, useEffect } from "react";

const useBatteryStatus = () => {
  const [batteryStatus, setBatteryStatus] = useState({
    level: 1,
    charging: false,
  });

  useEffect(() => {
    let battery;

    const updateBatteryStatus = (battery) => {
      setBatteryStatus({
        level: battery.level,
        charging: battery.charging,
      });
    };

    // const handleBatteryEvents = async () => {
    //   battery = await navigator.getBattery();
    //   updateBatteryStatus(battery);

    //   battery.addEventListener("levelchange", () =>
    //     updateBatteryStatus(battery)
    //   );
    //   battery.addEventListener("chargingchange", () =>
    //     updateBatteryStatus(battery)
    //   );
    // };

    const handleBatteryEvents = async () => {
      if ("getBattery" in navigator) {
        try {
          const battery = await navigator.getBattery();
          updateBatteryStatus(battery);

          battery.addEventListener("levelchange", () =>
            updateBatteryStatus(battery)
          );
          battery.addEventListener("chargingchange", () =>
            updateBatteryStatus(battery)
          );
        } catch (error) {
          console.error("Error accessing battery information:", error);
        }
      } else {
        console.warn(
          "Battery Status API is not supported on this device/browser."
        );
        updateBatteryStatusFallback();
      }
    };

    // Fallback behavior when Battery Status API is not supported
    const updateBatteryStatusFallback = () => {
      updateBatteryStatus({
        level: null,
        charging: null,
      });
    };

    handleBatteryEvents();

    return () => {
      if (battery) {
        battery.removeEventListener("levelchange", () =>
          updateBatteryStatus(battery)
        );
        battery.removeEventListener("chargingchange", () =>
          updateBatteryStatus(battery)
        );
      }
    };
  }, []);

  return batteryStatus;
};

export default useBatteryStatus;
