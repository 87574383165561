import axios from "axios";
import BASE_URL from "../config/host";
import { apiRequest } from "../util/async/apiUtils";

export const signUpRequest = async (params, setLoaded = () => {}) => {
  setLoaded(false);
  try {
    let response = await axios.post(`${BASE_URL}/signup`, {
      ...params,
    });

    if (response.status === 200) {
      setLoaded(true);
    }

    return response;
  } catch (error) {
    setLoaded(true);
    return error.response;
  }
};

export const loginRequest = async (params, setLoaded = () => {}) => {
  setLoaded(false);
  try {
    let response = await axios.post(`${BASE_URL}/user-login`, {
      ...params,
    });

    if (response.status === 200) {
      setLoaded(true);
    }

    return response;
  } catch (error) {
    setLoaded(true);
    return error.response;
  }
};

export const socialLogin = async (params, setLoaded = () => {}) => {
  setLoaded(false);
  try {
    let response = await axios.post(`${BASE_URL}/glogin-cred`, {
      ...params,
    });
    if (response.status === 200) {
      setLoaded(true);
    }
    return response;
  } catch (error) {
    setLoaded(false);
    return error.response;
  }
};

export const getProfileDetails = async (token, setLoaded = () => {}) => {
  setLoaded(false);
  try {
    let response = await axios.get(`${BASE_URL}/profile/details`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setLoaded(true);
    return response;
  } catch (err) {
    setLoaded(true);
    return err.response;
  }
};

export const sendOtpService = async (params, setLoaded = () => {}) => {
  setLoaded(false);
  try {
    let response = await axios.post(`${BASE_URL}/send-otp`, {
      ...params,
    });
    setLoaded(true);
    return response;
  } catch (error) {
    setLoaded(true);
    return error.response;
  }
};

export const verifyOtpService = async (params, setLoaded = () => {}) => {
  setLoaded(false);
  try {
    let response = await axios.post(`${BASE_URL}/verify-otp`, {
      ...params,
    });
    setLoaded(true);
    return response;
  } catch (error) {
    setLoaded(true);
    return error.response;
  }
};

export const resetPasswordService = async (params, setLoaded = () => {}) => {
  setLoaded(false);
  try {
    let response = await axios.post(`${BASE_URL}/forget-password`, {
      ...params,
    });
    setLoaded(true);
    return response;
  } catch (error) {
    setLoaded(true);
    return error.response;
  }
};

export const verifyToken = async () => {
  try {
    const res = await apiRequest("post", "verify-token", {});

    return res;
  } catch (error) {
    return error?.response;
  }
};
