import React, { useEffect, useState } from "react";
import Button from "../form/Button";
import Checkbox from "../form/Checkbox";
import CalendarMonth from "./CalendarMonth";
import Modal from "./Modal";
import Cards from "./payment/Cards";
import AddNewCards from "./payment/AddNewCards";
import { useSelector } from "react-redux";
import { appointmentModalLang } from "./language";
import SuccessModal from "./SuccessModal";
import { toast } from "react-toastify";
import RadioButton from "../form/RadioButton";
import dayjs from "dayjs";
import { fetchAppointmentSlots } from "../../service/appointmentService";
import { bookAppointment } from "../../service/videoCallService";
import SecondResumeBooking from "./SecondResumeBooking";
import moment from "moment-timezone";
const languageConfig = {
  notAvailable: {
    en: "No Time Slots Available!",
    es: "¡No hay franjas horarias disponibles!",
    it: "Nessuna fascia oraria disponibile!",
  },
  poweredBy: {
    en: "Powered by",
    es: "Desarrollado por",
    it: "Offerto da",
  },
};

const AppointmentModal = ({ isOpen, setIsOpen, data, serviceId, modes }) => {
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const lang = useSelector((state) => state?.languageSlice?.data);
  const [checked, setChecked] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConsentAccepted, setIsConsentAccepted] = useState(false);
  const [isPrivecyAccepted, setIsPrivecyAccepted] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isCardAvailable, setIsCardAvailable] = useState(true);
  const [times, setTimes] = useState([]);
  const [timeLoaded, setTimeLoaded] = useState(true);
  const language = localStorage.getItem("language") || lang;
  const [modeId, setModeId] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);
  const [secondModalOpen, setSecondModalOpen] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userDetails"));
  const gmtOffset = userData?.personal?.timeZone?.gmtOffsetName;

  useEffect(() => {
    modes.find((elm) => {
      if (elm?.slug == "video") {
        setSelectedOption("video");
        setModeId(elm?._id);
      }
    });
  }, [modes]);

  const handleOptionChange = (option, id) => {
    setSelectedOption(option);
    setModeId(id);
  };
  const handleDateSelect = (value) => {
    setSelectedDate(value);
  };

  //Convert datestring according to gmtOffsetName
  const convertToGMTOffset = (dateString, gmtOffsetName) => {
    // Parsing the date string with moment
    const date = moment.utc(dateString);

    // Getting the offset from gmtOffsetName (e.g., "UTC+01:00")
    const match = gmtOffsetName.match(/UTC([+-]\d{2}):(\d{2})/);
    if (!match) {
      throw new Error("Invalid gmtOffsetName format");
    }

    const [, sign, hours, minutes] = match;
    const offsetInMinutes = parseInt(hours) * 60 + parseInt(minutes);
    const totalOffset = sign === "+" ? offsetInMinutes : -offsetInMinutes;

    // Applying the offset and formatting the date
    const convertedDate = date.utcOffset(totalOffset).format("h:mm A");

    return convertedDate;
  };

  const convertUTCToMinutes = (gmtOffsetName) => {
    const offsetString = gmtOffsetName.replace("UTC", "");
    const match = offsetString.match(/([+-]\d{2}):(\d{2})/);
    if (!match) return 0;
    const [, hours, minutes] = match;
    return parseInt(hours) * 60 + parseInt(minutes);
  };

  useEffect(() => {
    setTimeLoaded(false);
    data &&
      isOpen &&
      fetchAppointmentSlots({
        consultantId: data?._id,
        dateAppointment: selectedDate && selectedDate?.toISOString(),
        offset: new Date().getTimezoneOffset(), //convertUTCToMinutes(gmtOffset),
      }).then((res) => {
        setTimeLoaded(true);
        if (res.data.status == 200) {
          setTimes(res.data.docs);
        }
      });
  }, [selectedDate, isOpen == true]);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleContinue = () => {
    if (!modeId) {
      toast.error("Select one mode.");
      return;
    }
    if (!selectedTime) {
      toast.error("Select one date and time slot.");
      return;
    }
    if (!isConsentAccepted) {
      toast.error("Check consent to the psychological consult first.");
      return;
    }
    if (!isPrivecyAccepted) {
      toast.error("Check privacy and policy first.");
      return;
    }
    setSecondModalOpen(true);
    setIsOpen(false);
  };

  const confirmBooking = () => {
    setLoading(true);
    bookAppointment({
      consultantId: data?._id,
      consultantJoinTime: selectedTime?.startTime,
      modeId: modeId,
      serviceId: serviceId,
      details: {
        appointmentDate: formatDate(selectedDate),
        appointmentStartTime: selectedTime?.startTime,
        appointmentEndTime: selectedTime?.endTime,
      },
    }).then((res) => {
      if (res?.status == 200 || res?.status == 201) {
        setLoading(false);
        setIsModalOpen(true);
        setIsOpen(false);
        setSecondModalOpen(false);
        setSelectedTime(null);

        const minDate = new Date();
        minDate.setDate(minDate.getDate() + 1);

        setSelectedDate(minDate);
      } else {
        setLoading(false);
        toast.error(res?.error);
        setSelectedTime(null);
      }
    });
    setIsOpen(false);
  };

  useEffect(() => {
    if (!isOpen && !secondModalOpen) {
      const minDate = new Date();
      // minDate.setDate(minDate.getDate() + 1);
      setSelectedTime(null);
      setSelectedDate(minDate);
    }
  }, [isOpen, secondModalOpen]);

  return (
    <div>
      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        modalTitle={appointmentModalLang?.title1?.[language]}
      >
        <Button
          buttonClasses={
            "!w-10 !h-10 !rounded-full !border-black !bg-transparent shadow-md !absolute !top-2 !right-2 !text-black !flex-row !items-center !justify-center"
          }
          buttonIcon={"fa-regular fa-xmark"}
          buttonIconPosition={"left"}
          buttonFunction={() => setIsOpen(false)}
        />
        <div className="relative space-y-4">
          <div className="relative">
            <div className="text-2xl font-bold text-prontopsy-blue">
              {data?.name}
            </div>
          </div>
          <div className="md:flex block w-full md:gap-52 md:space-y-0 spacey-4 items-center">
            <div className="relative flex flex-wrap justify-between gap-8 items-center my-1">
              <div className="relative flex text-xs text-left font-RobotoSlab gap-2 font-bold text-gray-600">
                {data?.rate?.value || "N/A"}{" "}
                <span>{data?.rate?.currency}/MIN</span>
              </div>
            </div>
            <div className="flex md:gap-2 gap-1 md:text-sm text-xs font-bold text-gray-600">
              <div>{appointmentModalLang?.title11?.[language]} :</div>
              <div>
                {data?.rate?.currency}{" "}
                <span> {data?.rate?.value * 60 || "N/A"}</span>
              </div>
            </div>
          </div>

          {/* Mode */}
          <div className="relative flex items-center flex-wrap gap-3 !my-5 w-full">
            {modes &&
              modes.length > 0 &&
              modes.map((elm, index) => (
                <RadioButton
                  label={elm?.title?.[language]}
                  icon={`${
                    elm?.slug == "voice"
                      ? "fa-solid fa-phone-volume"
                      : elm?.slug == "video"
                      ? "fas fa-video"
                      : "fa-solid fa-comments"
                  }`}
                  isSelected={selectedOption === elm?.slug}
                  onChange={() => handleOptionChange(elm?.slug, elm?._id)}
                  key={elm?._id}
                />
              ))}

            {/* <RadioButton
              label={appointmentModalLang?.voice?.[language]}
              icon="fa-solid fa-phone-volume"
              isSelected={selectedOption === "voice"}
              onChange={() => handleOptionChange("voice")}
            />
            <RadioButton
              label={appointmentModalLang?.video?.[language]}
              icon="fas fa-video"
              isSelected={selectedOption === "video"}
              onChange={() => handleOptionChange("video")}
            />
            <RadioButton
              label={appointmentModalLang?.chat?.[language]}
              icon="fa-solid fa-comments"
              isSelected={selectedOption === "chat"}
              onChange={() => handleOptionChange("chat")}
            /> */}
          </div>

          {/* Calender */}
          <div>
            <div className="relative !mb-4">
              <CalendarMonth value={selectedDate} onChange={handleDateSelect} />
            </div>
          </div>

          <div className="flex items-start flex-col gap-1 !mb-4 space-y-1">
            <div className="text-xs text-slate-500 w-full">
              {/* -------------------Time zone--------------------- */}
              <div>
                {appointmentModalLang?.title2?.[language]} :{" "}
                <span className="text-xl">
                  {userData?.personal?.timeZone?.zoneName}
                </span>{" "}
              </div>
              <div className="mt-3 overflow-x-auto w-full pb-2">
                <div className="flex items-center gap-3 justify-start whitespace-nowrap">
                  {!timeLoaded ? (
                    <div className="flex justify-center mt-4 w-full">
                      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-prontopsy-blue"></div>
                    </div>
                  ) : times.length > 0 ? (
                    times.map((time) =>
                      time?.dailySchedule?.scheduleTime?.startTime ? (
                        <div
                          key={time?._id}
                          className={`flex items-center gap-3 justify-center border w-[230px] p-2 rounded-md ${
                            selectedTime?._id ===
                            time?.dailySchedule?.scheduleTime?._id
                              ? "bg-[#B32CBD] text-white"
                              : !time?.dailySchedule?.scheduleTime?.availability
                              ? "bg-slate-50 text-slate-300"
                              : "bg-slate-100"
                          } ${
                            time?.dailySchedule?.scheduleTime?.availability
                              ? "cursor-pointer"
                              : ""
                          } shadow-sm`}
                          onClick={() =>
                            time?.dailySchedule?.scheduleTime?.availability ===
                              true &&
                            setSelectedTime(time?.dailySchedule?.scheduleTime)
                          }
                        >
                          <div>
                            <p className="text-xl">
                              {dayjs(
                                time?.dailySchedule?.scheduleTime?.startTime
                              ).format("h:mm A")}
                              {/* {convertToGMTOffset(
                                time?.dailySchedule?.scheduleTime?.startTime,
                                gmtOffset
                              )} */}
                            </p>
                            <p>{dayjs(selectedDate).format("DD/MM/YYYY")}</p>
                          </div>
                          <div className="w-4 border border-gray-400" />
                          <div>
                            <p className="text-xl">
                              {dayjs(
                                time?.dailySchedule?.scheduleTime?.endTime
                              ).format("h:mm A")}
                              {/* {convertToGMTOffset(
                                time?.dailySchedule?.scheduleTime?.endTime,
                                gmtOffset
                              )} */}
                            </p>
                            <p>{dayjs(selectedDate).format("DD/MM/YYYY")}</p>
                          </div>
                        </div>
                      ) : (
                        <div className="bg-slate-300 w-[100%] p-3">
                          {languageConfig?.notAvailable?.[language]}
                        </div>
                      )
                    )
                  ) : (
                    <div className="bg-slate-300 w-[100%] p-3">
                      {languageConfig?.notAvailable?.[language]}
                    </div>
                  )}
                </div>
              </div>
            </div>

            {!checked && (
              <Cards
                setIsCardAvailable={setIsCardAvailable}
                setSelectedCard={setSelectedCard}
              />
            )}

            <AddNewCards
              checked={checked}
              setChecked={setChecked}
              timeSlotLoaded={timeLoaded}
            />
          </div>
          <div className="bg-slate-200/50 w-full py-3 !my-3 px-4 rounded-md">
            <div className="md:flex items-center gap-5 md:space-y-0 space-y-2">
              <div className="flex md:gap-5 gap-3 w-full items-center">
                <span className="text-green-600 flex-shrink-0">
                  <i className="fa-solid fa-lock"></i>
                </span>
                <div
                  className="font-RobotoSlab flex-shrink w-full text-gray-700 text-sm font-normal"
                  dangerouslySetInnerHTML={{
                    __html: appointmentModalLang?.title3?.[language],
                  }}
                ></div>
              </div>
              <div className="h-8 min-w-40 border border-gray-400 flex items-center justify-center gap-2 rounded-md">
                <div className="font-RobotoSlab text-gray-400 text-xs font-normal">
                  {languageConfig?.poweredBy?.[language]}
                </div>
                <div className="text-4xl text-gray-400">
                  <i class="fa-brands fa-stripe"></i>
                </div>
              </div>
            </div>
          </div>

          <div className="relative space-y-3">
            <Checkbox
              checkboxLabel={appointmentModalLang?.title4?.[language]}
              checkboxLabelClass={"!text-neutral-400 !font-RobotoSlab"}
              isChecked={isConsentAccepted}
              onChange={(e) => setIsConsentAccepted(e.target.checked)}
            />
            <Checkbox
              checkboxLabel={appointmentModalLang?.title5?.[language]}
              checkboxLabelClass={"!text-neutral-400 !font-RobotoSlab"}
              isChecked={isPrivecyAccepted}
              onChange={(e) => setIsPrivecyAccepted(e.target.checked)}
            />
          </div>
          <div className="relative !mt-7">
            <Button
              buttonLabel={appointmentModalLang?.continue?.[language]}
              buttonClasses={`!bg-prontopsy-blue !h-12 !px-6`}
              buttonLabelClasses={`!font-UnisonPro !text-xs !text-white !font-bold !italic`}
              buttonFunction={handleContinue}
              isDisable={loading}
            />
          </div>
        </div>
      </Modal>
      <SuccessModal
        isOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        setIsConsentAccepted={setIsConsentAccepted}
        setIsPrivecyAccepted={setIsPrivecyAccepted}
        setSelectedTime={setSelectedTime}
      />
      <SecondResumeBooking
        isOpen={secondModalOpen}
        setIsOpen={setSecondModalOpen}
        data={data}
        loading={loading}
        handleContinue={confirmBooking}
        mode={selectedOption}
        date={selectedDate}
        time={selectedTime}
        selectedCard={selectedCard}
      />
    </div>
  );
};

export default AppointmentModal;
