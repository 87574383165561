import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import OT from "@opentok/client";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import UserVideo from "../components/items/chatBox/UserVideo";
import UserChatsHeader from "../components/items/chatBox/UserChatsHeader";
import useMediaStream from "../components/elements/hooks/useMediaStream";
import { useSocket } from "../components/socket/SocketProvider";
import { fetchAppointmentList } from "../service/appointmentService";
import TimeCountdown from "../helpers/TimeCountdown";
import { toast } from "react-toastify";

const Chats = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const socket = useSocket();
  const { closeMediaDevices } = useMediaStream();

  // Memoized data
  const appointmentData = useMemo(
    () => JSON.parse(localStorage.getItem("appointmentData") || "{}"),
    []
  );
  const profile = useMemo(
    () => JSON.parse(localStorage.getItem("userDetails") || "{}"),
    []
  );
  const callerData = useSelector((state) => state?.callingSlice?.data);
  const authToken = localStorage.getItem("auth_token");

  // Comprehensive state management
  const [sessionState, setSessionState] = useState({
    session: null,
    publisher: null,
    subscriber: null,
    isInterviewStarted: false,
    isVideoEnabled: true,
    isAudioEnabled: true,
    isSubscribed: false,
    isFullscreen: false,
    isChatVisible: false,
    messages: [],
  });

  const containerRef = useRef(null);
  const sessionInitRef = useRef(false);

  // Configuration constants
  const CONFIG = useMemo(
    () => ({
      apiKey: 47879001,
      sessionId: appointmentData?.sessionId,
      token: appointmentData?.token,
      mode: appointmentData?.mode,
    }),
    [appointmentData]
  );

  // Error handling
  const handleError = useCallback(
    async (error) => {
      if (!error) return;
      const errorMap = {
        OT_NOT_CONNECTED: "Connection failed. Check internet.",
        OT_NO_MEDIA: "No camera/microphone detected.",
        // OT_HARDWARE_UNAVAILABLE: "Media devices in use.",
        OT_USER_MEDIA_ACCESS_DENIED: "Media access denied.",
        OT_INVALID_PARAMETER: "Invalid configuration.",
        OT_CONNECTION_FAILED:
          "Failed to connect to the OpenTok session. Verify that the session ID, token, and network connection are correct and functioning.",
        OT_NETWORK_DISCONNECTED:
          "The connection to the OpenTok session was lost due to network issues. Please check your internet connection and try again.",
        OT_HARDWARE_UNAVAILABLE:
          "The selected audio or video devices are unavailable. Verify that the devices are not in use by another application or disconnected.",
        OT_GET_USER_MEDIA_ERROR:
          "	OpenTok could not access your camera or microphone. Ensure that the browser has permission to access these devices.",
        OT_PERMISSION_DENIED:
          "Permission to access audio or video devices was denied. Please allow access to your camera or microphone in your browser settings.",
        OT_NO_MEDIA_DEVICES:
          "No audio or video devices were found. Ensure that your devices are connected and recognized by your system.",
        OT_MEDIA_DEVICE_NOT_FOUND:
          "The specified audio or video device could not be found. Ensure that the correct device is selected and connected.",
        OT_SCREEN_SHARE_UNSUPPORTED:
          "Screen sharing is not supported on this browser or platform. Use a supported browser like Chrome, Edge, or Firefox.",
        OT_SCREEN_SHARE_EXTENSION_ERROR:
          "The screen-sharing extension required for this action is not installed or enabled. Please install the extension and try again.",
        OT_PUBLISHER_ERROR:
          "An error occurred while trying to publish your audio or video stream to the session. Verify your network and device settings.",
        OT_SUBSCRIBER_ERROR:
          "An error occurred while subscribing to the audio or video stream. Please check your network and try again.",
        OT_SESSION_DISCONNECTED:
          "The session was disconnected. This may happen if the session ended or your network connection was interrupted.",
        OT_STREAM_DESTROYED:
          "The stream you were viewing has been destroyed. This could occur if the publisher left the session or stopped publishing.",
        OT_SERVER_ERROR:
          "A server-side error occurred. This is an internal OpenTok issue. Please try again later or contact support if the issue persists.",
      };

      console.error(error);
      // alert(errorMap[error.name] || "Unexpected error");
      toast(error?.message, { autoClose: 8000 });

      // Wait for the user to click "OK"
      // await new Promise((resolve) => {
      //   const CustomToast = () => (
      //     <div>
      //       <p>{error.message}</p>
      //       <button
      //         onClick={() => {
      //           toast.dismiss(); // Close the toast
      //           resolve(); // Resume code execution
      //         }}
      //         style={{
      //           marginTop: "10px",
      //           background: "#f55653",
      //           color: "#fff",
      //           border: "none",
      //           borderRadius: "4px",
      //           padding: "8px 16px",
      //           cursor: "pointer",
      //         }}
      //       >
      //         OK
      //       </button>
      //     </div>
      //   );

      //   // Show the toast
      //   toast(<CustomToast />, {
      //     autoClose: false, // Disable auto-close to wait for user interaction
      //     closeOnClick: false,
      //     draggable: false,
      //     position: "top-center",
      //     hideProgressBar: true,
      //   });
      // });

      navigate("/appointments");
    },
    [navigate]
  );

  if (window.fetch) {
    const originalFetch = window.fetch;
    window.fetch = function (...args) {
      const [url] = args;
      if (url.includes("hlg.tokbox.com/prod/logging/ClientEvent")) {
        return Promise.resolve(new Response(null, { status: 204 }));
      }
      return originalFetch.apply(this, args);
    };
  }
  // Session initialization
  const initializeSession = useCallback(() => {
    // Prevent multiple initializations
    if (sessionInitRef.current || !CONFIG.sessionId) return;

    const newSession = OT.initSession(CONFIG.apiKey, CONFIG.sessionId);
    sessionInitRef.current = true;

    const publisherOptions = {
      insertMode: "append",
      width: "100%",
      height: "100%",
      publishAudio: true,
      videoSource: CONFIG.mode === "voice" ? null : undefined,
    };

    const newPublisher = OT.initPublisher(
      "publisher",
      publisherOptions,
      handleError
    );

    // Stream handling
    newSession.on("streamCreated", (event) => {
      const newSubscriber = newSession.subscribe(
        event.stream,
        "subscriber",
        {
          insertMode: "append",
          width: "100%",
          height: "100%",
          videoSource: CONFIG.mode === "voice" ? null : undefined,
        },
        handleError
      );

      setSessionState((prev) => ({
        ...prev,
        subscriber: newSubscriber,
        isSubscribed: true,
      }));
    });

    // Connection and publishing
    newSession.connect(CONFIG.token, (error) => {
      if (!error) {
        newSession.publish(newPublisher, handleError);
        setSessionState((prev) => ({
          ...prev,
          session: newSession,
          publisher: newPublisher,
          isInterviewStarted: true,
        }));
      } else {
        handleError(error);
      }
    });

    // Cleanup function
    return () => {
      sessionInitRef.current = false;
      newSession.disconnect();
    };
  }, [CONFIG, handleError]);

  // Session management effect
  useEffect(() => {
    const cleanup = initializeSession();
    return cleanup;
  }, [initializeSession]);

  // Appointment ping
  useEffect(() => {
    const pingInterval = setInterval(() => {
      socket?.emit("appointmentPing", {
        appointmentId: appointmentData.appointmentId,
        onCall: true,
        id: profile?._id,
      });
    }, 60 * 1000);

    return () => clearInterval(pingInterval);
  }, [socket, appointmentData, profile]);

  // Control toggle methods
  const toggleVideo = useCallback(
    (state) => {
      sessionState.publisher?.publishVideo(state);
      setSessionState((prev) => ({
        ...prev,
        isVideoEnabled: state,
      }));
    },
    [sessionState.publisher]
  );

  const toggleAudio = useCallback(
    (state) => {
      sessionState.publisher?.publishAudio(state);
      setSessionState((prev) => ({
        ...prev,
        isAudioEnabled: state,
      }));
    },
    [sessionState.publisher]
  );
  const [isFullscreen, setIsFullscreen] = useState(false);
  const toggleFullscreen = () => {
    if (containerRef.current) {
      if (isFullscreen) {
        document.exitFullscreen();
      } else {
        containerRef.current.requestFullscreen();
      }
      setIsFullscreen(!isFullscreen);
    }
  };

  // Upcoming appointment tracking
  const [upcoming, setUpcoming] = useState(null);
  useEffect(() => {
    fetchAppointmentList(
      authToken,
      {
        limit: 1,
        "status[0]": "active",
        sortQuery: "details.appointmentStartTime",
      },
      setUpcoming
    );
  }, [authToken]);

  return (
    <>
      {upcoming?.[0]?.details?.appointmentStartTime && (
        <TimeCountdown targetDate={upcoming[0].details.appointmentStartTime} />
      )}
      <div
        className="bg-zinc-800 h-screen flex flex-col overflow-hidden"
        ref={containerRef}
      >
        <UserVideo
          isAudioEnabled={sessionState.isAudioEnabled}
          data={callerData?.consultant?.name}
        />
        <UserChatsHeader
          toggleVideo={toggleVideo}
          toggleAudio={toggleAudio}
          isInterviewStarted={sessionState.isInterviewStarted}
          // Other necessary props
          isFullscreen={isFullscreen}
          isChatVisible={sessionState.isChatVisible}
          // stopStreaming={stopStreaming}
          isAudioEnabled={sessionState.isAudioEnabled}
          isVideoEnabled={sessionState.isVideoEnabled}
          toggleFullscreen={toggleFullscreen}
          setIsFullscreen={setIsFullscreen}
          setIsAudioEnabled={(state) => toggleAudio(state)}
          setIsVideoEnabled={(state) => toggleVideo(state)}
          setIsInterviewStarted={(started) =>
            setSessionState((prev) => ({
              ...prev,
              isInterviewStarted: started,
            }))
          }
          toggleChatVisibility={() =>
            setSessionState((prev) => ({
              ...prev,
              isChatVisible: !prev.isChatVisible,
            }))
          }
          subscriber={sessionState.subscriber?.element}
          messages={sessionState.messages}
          // sendMessage={sendMessage}
        />
      </div>
    </>
  );
};

export default React.memo(Chats);
