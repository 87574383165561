import React, { useEffect, useState } from "react";
import Radio from "../../form/Radio";
import Image from "../Image";
import StripeForm from "./StripeForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Button from "../../form/Button";
import {
  addCardService,
  deleteCardService,
  fetchCardList,
} from "../../../service/stripeService";
import americanExpress from "../../../assets/images/cardicon/American Express.png";
import dinersClub from "../../../assets/images/cardicon/Diners Club.png";
import discover from "../../../assets/images/cardicon/Discover.png";
import elo from "../../../assets/images/cardicon/elo.png";
import hipercard from "../../../assets/images/cardicon/hipercard-29.png";
import jcb from "../../../assets/images/cardicon/JCB.png";
import masterCard from "../../../assets/images/cardicon/MasterCard.png";
import mnp from "../../../assets/images/cardicon/mnp.png";
import troy from "../../../assets/images/cardicon/troy.png";
import unionPay from "../../../assets/images/cardicon/UnionPay.png";
import visa from "../../../assets/images/cardicon/Visa.png";
import { toast } from "react-toastify";
import { appointmentLang, appointmentModalLang } from "../language";
import { useSelector } from "react-redux";
import DeleteModal from "../DeleteModal";

const Cards = ({
  isToggle = true,
  setIsCardAvailable = () => {},
  setSelectedCard = () => {},
}) => {
  const [cardList, setCardList] = useState([]);
  const [loaded, setLoaded] = useState(true);
  const [addCardLoaded, setAddCardLoaded] = useState(true);
  const lang = useSelector((state) => state?.languageSlice?.data);
  const language = localStorage.getItem("language") || lang;
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedForDelete, setSelectedForDelete] = useState(null);
  const [deleteLoaded, setDeleteLoaded] = useState(true);
  const [stripeToken, setStripeToken] = useState(null);

  const languageConfig = {
    addNewCard: {
      en: "Add new card",
      es: "Agregar nueva tarjeta",
      it: "Aggiungi nuova carta",
    },
    addCardLang: {
      en: "Add Card",
      es: "Agregar tarjeta",
      it: "Aggiungi carta",
    },
  };

  const apiKey =
    "pk_test_51GxqGLGRfcBcvK9WTraDAbrRRTA7POPrTYc3fIq1L6CncO7ei5aGBbHudCeKje13HTTyHwGa78X1OZUOWfdkIcCx001yuEfXA1";

  const stripePromise = loadStripe(apiKey);

  useEffect(() => {
    cardList.length === 0
      ? setIsCardAvailable(false)
      : setIsCardAvailable(true);

    cardList.length > 0 && setSelectedCard(cardList[0]);
  }, [cardList]);

  useEffect(() => {
    setLoaded(false);
    fetchCardList().then((res) => {
      setLoaded(true);
      if (res.data.status === 200) {
        setCardList(res.data.data.data);
      } else {
        toast.error(res?.data?.message);
      }
    });
  }, []);

  const handleSelect = (e, elm) => {
    setSelectedCard(elm);
  };

  const getCardImage = (brand) => {
    switch (brand) {
      case "american_express":
      case "American Express":
        return americanExpress;
      case "diners_club":
      case "Diners Club":
        return dinersClub;
      case "discover":
      case "Discover":
        return discover;
      case "visa":
      case "Visa":
        return visa;
      case "mastercard":
      case "MasterCard":
        return masterCard;
      case "jcb":
      case "JCB":
        return jcb;
      case "unionpay":
      case "UnionPay":
        return unionPay;
      case "hipercard":
      case "Hipercard":
        return hipercard;
      case "mnp":
      case "MNP":
        return mnp;
      case "elo":
      case "ELO":
        return elo;
      default:
        return troy;
    }
  };

  const handleDelete = () => {
    // Handle the delete action here
    setDeleteLoaded(false);
    selectedForDelete &&
      deleteCardService({ sourceId: selectedForDelete?.id }).then((res) => {
        setDeleteLoaded(true);
        if (res.data.status === 200) {
          let data = cardList.filter(
            (elm) => elm?.id !== selectedForDelete?.id
          );
          setCardList(data);

          setIsDeleteModalOpen(false);
        }
      });
  };

  const addNewCard = () => {
    if (!stripeToken) {
      toast.error("Please add a card");
      return;
    }
    setAddCardLoaded(false);
    stripeToken &&
      addCardService({ card_token: stripeToken }).then((res) => {
        setAddCardLoaded(true);
        if (res?.data?.status === 200) {
          setCardList((pre) => [...pre, res?.data?.newCardData]);
          toast.success(res?.data?.message);
          setStripeToken(null);
        } else if (res?.data?.status === 400) {
          toast.error(res?.data?.message);
          setStripeToken(null);
        }
      });
  };

  return (
    <>
      {loaded ? (
        <div className="relative !my-5 w-full">
          {isToggle ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              {cardList.length > 0 &&
                cardList.map((elm, index) => (
                  <div
                    key={elm?.last4}
                    className="flex items-center border rounded-sm p-3 bg-slate-50"
                  >
                    <Radio
                      isChecked={index === 0}
                      radioName={"credit_card"}
                      onChange={(e) => handleSelect(e, elm)}
                    />
                    <div className="w-10 overflow-hidden mx-2">
                      <Image
                        src={getCardImage(elm?.brand)}
                        alt={elm?.brand}
                        className="w-full h-full object-contain"
                      />
                    </div>
                    <span className="mx-4">{`xxx-xxx-xxxx-${elm?.last4}`}</span>
                  </div>
                ))}
            </div>
          ) : (
            <>
              <div className="flex flex-wrap gap-6">
                {cardList.length > 0 &&
                  cardList.map((elm) => (
                    <div
                      key={elm?.last4}
                      className="flex items-center border rounded-lg p-3 bg-slate-100 w-[330px] h-[70px]"
                    >
                      <div className="w-10 overflow-hidden mx-2">
                        <Image
                          src={getCardImage(elm?.brand)}
                          alt={elm?.brand}
                          className="w-full h-full object-contain"
                        />
                      </div>
                      <span className="ml-6">{`xxx-xxx-xxxx-${elm?.last4}`}</span>
                      <span
                        className="ml-8 text-red-600 cursor-pointer"
                        onClick={() => {
                          setSelectedForDelete(elm);
                          setIsDeleteModalOpen(true);
                        }}
                      >
                        <i className="fa-solid fa-trash"></i>
                      </span>
                    </div>
                  ))}
              </div>
              {cardList.length > 0 ? (
                <div className="mt-[20px]">
                  <span className=" text-sm text-slate-400">
                    {appointmentModalLang?.title8?.[language]}
                  </span>
                </div>
              ) : (
                <div className="mt-[20px]">
                  <span className=" text-sm text-slate-400">
                    {appointmentModalLang?.title9?.[language]}
                  </span>
                </div>
              )}
              <div className="mt-4 w-full">
                <div class="relative w-full border-t border-dashed border-neutral-300 py-4">
                  <h2 class="text-base text-prontopsy-blue font-RobotoSlab font-bold">
                    {languageConfig?.addNewCard?.[language]}
                  </h2>
                </div>
                <div className="border-t border-slate-300 pt-4">
                  <Elements
                    stripe={stripePromise}
                    options={{ locale: language }}
                  >
                    <StripeForm setToken={setStripeToken} />
                  </Elements>
                </div>
                <Button
                  buttonLabel={languageConfig?.addCardLang?.[language]}
                  buttonClasses={"mt-3"}
                  buttonFunction={addNewCard}
                  isDisable={!addCardLoaded}
                />
              </div>
            </>
          )}
        </div>
      ) : (
        <div className="flex justify-center mt-4 w-full">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-prontopsy-blue"></div>
        </div>
      )}
      <DeleteModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onDelete={handleDelete}
        loaded={deleteLoaded}
      />
    </>
  );
};

export default Cards;
