import React, { useEffect, useRef, useState } from "react";
import Button from "../../form/Button";
import Image from "../../elements/Image";
import { useSelector } from "react-redux";
import { fetchRoleList } from "../../../service/roleService";
import Work from "./Work";

const languageConfig = {
  client: {
    en: "Client",
    es: "Cliente",
    it: "Cliente",
  },
  consultant: {
    en: "Consultant",
    es: "Consultor",
    it: "Consulente",
  },
};

const JoinOurCommunity = () => {
  const [pageData, setPageData] = useState({});
  const data = useSelector((state) => state?.pageSlice?.data);
  const [roleData, setRoleData] = useState([]);
  const [selectedRoleData, setSelectedRoleData] = useState({});
  const lang = useSelector((state) => state?.languageSlice?.data);
  const language = localStorage.getItem("language") || lang;
  const workSectionRef = useRef(null);

  useEffect(() => {
    fetchRoleList(setRoleData);
  }, []);

  useEffect(() => {
    let info =
      data.length > 0 &&
      data.filter((elm) => elm?.title?.en === "Join_our_community");
    setPageData(info[0]);
  }, [data]);

  let buttonInfo =
    roleData &&
    roleData.length > 0 &&
    roleData.map((elm) => ({
      label:
        elm?.title == "Client"
          ? languageConfig?.client
          : languageConfig?.consultant,
      labelClasses: "uppercase",
      buttonClasses: "px-7 !h-10 lg:!h-12 xl:!h-14",
      link: "",
      data: elm,
    }));

  useEffect(() => {
    Object.keys(selectedRoleData).length == 0 &&
      buttonInfo &&
      setSelectedRoleData(buttonInfo[0]?.data);
  }, [buttonInfo]);

  const ourCommunityData = {
    title: pageData?.subTitle?.[language],
    subtitle: pageData?.description?.[language],
    image: pageData?.image?.url,
    buttons: buttonInfo,
  };

  const scrollToWorkSection = () => {
    workSectionRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <section className="relative py-10 md:py-12 lg:py-16 xl:py-20">
        <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto">
          <div className="w-full mx-auto max-w-5xl text-center">
            <div className="flex items-center justify-center gap-3 mb-6 sm:mb-8 md:mb-10 lg:mb-12 xl:mb-14 2xl:mb-16">
              {ourCommunityData.buttons &&
                ourCommunityData.buttons.map((button, index) => (
                  <div key={index} className="relative">
                    <Button
                      buttonLabel={button?.label?.[language]}
                      buttonLabelClasses={button.labelClasses}
                      buttonClasses={`${button.buttonClasses} ${
                        button?.data?.code === selectedRoleData?.code
                          ? "!bg-prontopsy-darkblue"
                          : "!bg-prontopsy-pink"
                      }`}
                      buttonHasLink={false}
                      buttonFunction={() => {
                        setSelectedRoleData(button.data);
                        scrollToWorkSection();
                      }}
                    />
                  </div>
                ))}
            </div>
            {ourCommunityData?.title && (
              <div className=" text-center flex justify-center">
                <h2
                  className="text-2xl lg:text-3xl xl:text-4xl 2xl:text-[38px] text-prontopsy-blue font-UnisonPro italic font-bold !leading-[1.1] max-w-72"
                  dangerouslySetInnerHTML={{ __html: ourCommunityData?.title }}
                ></h2>
              </div>
            )}
            {ourCommunityData?.subtitle && (
              <div
                className="text-sm md:text-base xl:text-[17px] text-neutral-700 space-y-4 mt-6 mx-auto leading-[1.5rem]"
                dangerouslySetInnerHTML={{ __html: ourCommunityData?.subtitle }}
              ></div>
            )}
          </div>
          <div className="relative max-w-3xl w-full mx-auto aspect-[15/11] mt-10">
            <Image
              src={ourCommunityData?.image}
              alt={""}
              effect={"blur"}
              className={"w-full h-full"}
            />
          </div>
        </div>
      </section>
      <div ref={workSectionRef}>
        <Work role={selectedRoleData} />
      </div>
    </>
  );
};

export default JoinOurCommunity;
