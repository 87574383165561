import React, { useContext } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { useDispatch, useSelector } from "react-redux";
import Button from "../form/Button";
import axios from "axios";
import { getProfileDetails, socialLogin } from "../../service/authService";
import { setAuthData } from "../../store/slice/authSlice";
import { setTokenData } from "../../store/slice/tokenSlice";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import SocketContext, { useSocket } from "../socket/SocketProvider";
import { setInitialProfileData } from "../../store/slice/initialProfileSlice";
import { useNavigate } from "react-router-dom";

const langConfig = {
  dont: {
    en: "Don't have an account?",
    es: "¿No tienes una cuenta?",
    it: "Non hai un account?",
  },
  facebook: {
    en: "Continue with Facebook",
    es: "Continúa con Facebook",
    it: "Continua con Facebook",
  },
  google: {
    en: "Continue with Google",
    es: "Continúa con Google",
    it: "Continua con Google",
  },
  signup: {
    en: "Sign up now",
    es: "Regístrate ahora",
    it: "Iscriviti ora",
  },
  forgot: {
    en: "Forgot password?",
    es: "¿Has olvidado tu contraseña?",
    it: "Ha dimenticato la password?",
  },
  remember: {
    en: "Remember me",
    es: "Recuérdame",
    it: "Ricordati di me",
  },
};

const GoogleLoginComponent = ({ role }) => {
  const lang = useSelector((state) => state?.languageSlice?.data);
  const language = localStorage.getItem("language") || lang || "en";
  const dispatch = useDispatch();
  const { connectSocket } = useContext(SocketContext);
  const socket = useSocket();
  const navigate = useNavigate();

  const login = useGoogleLogin({
    onSuccess: async (response) => {
      try {
        const res = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${response.access_token}`,
            },
          }
        );
        const data = {
          type: "google",
          role: role,
          loginData: res?.data,
        };

        socialLogin(data).then((res) => {
          if (res.data?.status == 200) {
            // setDetailsData(res?.data?.userData);
            dispatch(setAuthData(res?.data));
            dispatch(setTokenData(res?.data?.accessToken));
            localStorage.setItem("auth_token", res?.data?.accessToken);
            localStorage.setItem("refreshToken", res?.data?.refreshToken);

            Cookies.set("auth_token", res?.data?.accessToken, {
              httpOnly: true, // Prevent access via JavaScript
              secure: true, // Only send over HTTPS
              sameSite: "Strict", // Prevent CSRF
              maxAge: 3 * 24 * 60 * 60 * 1000, // 3 days
            });

            if (!socket || !socket?.connected) {
              connectSocket();
            }
            toast.success(res?.data?.message);
            socket && socket.emit("userOnline", res?.data?.userData?._id);

            getProfileDetails(res?.data?.accessToken).then((response) => {
              if (response?.data?.status === 200) {
                localStorage.setItem(
                  "userDetails",
                  JSON.stringify(response?.data?.docs)
                );
                dispatch(setInitialProfileData(response?.data?.docs));
                // setTimeout(() => {
                navigate("/profile");
                // }, 1000);
              }
            });
            // setEmail("");
            // setPassword("");
          } else {
            toast.error(res.data.message, { autoClose: 5000 });
          }
        });
      } catch (err) {
        console.error("Error fetching user info:", err);
      }
    },
    // redirect_uri: "https://dev.front.prontopsy.com",
    redirect_uri: "http://localhost:3000",
    onError: () => {
      console.error("Login Failed");
    },
  });

  return (
    <div onClick={() => login()} className="cursor-pointer">
      <Button
        buttonIcon={"fa-brands fa-google"}
        buttonIconPosition={"left"}
        buttonLabelClasses={"capitalize font-RobotoSlab"}
        buttonClasses={
          "!text-neutral-700 h-10 lg:h-12 bg-white border border-black"
        }
        buttonLabel={langConfig.google[language]}
      />
    </div>
  );
};

export default GoogleLoginComponent;
