import React from "react";
import {
  PDFDownloadLink,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import Logo from "../../assets/images/logo.png";
import { left, right } from "@popperjs/core";

// Component to generate the invoice
const InvoiceDownloadComponent = ({ details, language }) => {
  const invoiceData = {
    from: {
      name: "GLOBAL DIGITAL VAGABONDS LTD",
      address: "Registered in England and Wales 9 Seagrave road London SW6 1RP",
      phone: "+393476662697",
      email: "globaldigitalvagabonds@gmail.com",
      companyNumber: "12671661",
      taxId: "23806 28486",
    },
    to: {
      name: details?.consultantData?.name,
      address: details?.consultantData?.address,
      phone: details?.consultantData?.phone,
      email: details?.consultantData?.email,
    },
    invoiceNumber: details?.invoice?.invoiceString,
    invoiceDate: details?.appointmentDate,
    dueDate: details?.appointmentDate,
    items: [
      {
        description: `Admin fee (${details?.adminPercentage}%) use of video consulting platform 'PRONTOPSY' (www.prontopsy.com)`,
        appointmentId: details?.appointmentId,
        appointmentDate: details?.appointmentDate,
        currency: details?.paymentInfo?.currency,
        amount: details?.capturedAmount,
      },
    ],
    totalAmountDue: details?.capturedAmount - details?.adminAmount,
    providerInfo: "Provider of regulated payment service: STRIPE",
  };

  return (
    <div>
      <PDFDownloadLink
        document={<InvoicePDF invoiceData={invoiceData} />}
        fileName={`invoice_${invoiceData.invoiceNumber}.pdf`}
        style={{
          textDecoration: "none",
          padding: "0px 16px 0px 16px",
          color: "#fff",
          backgroundColor: "#007bff",
          borderRadius: "5px",
          fontSize: "15px",
          overflow: "hidden",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "16px",
          height: "39px",
        }}
      >
        {({ loading }) =>
          loading ? (
            "Generating PDF..."
          ) : (
            <>
              <i
                className="fa-fw fa-solid fa-download"
                style={{ fontSize: "18px" }}
              ></i>{" "}
              <span>Admin Invoice</span>
            </>
          )
        }
      </PDFDownloadLink>
    </div>
  );
};

// Invoice PDF Component
const InvoicePDF = ({ invoiceData }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      {/* Header Section */}
      <Image style={styles.logo} src={Logo} />

      {/* Sender (From) Details */}
      <View
        style={{
          width: "100%",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-start",
          marginTop: 30,
        }}
      >
        {/* Sender (From) Details */}
        <View style={[styles.section, { flex: 1, lineHeight: "1px" }]}>
          <Text style={styles.subtitle}>FROM</Text>
          <Text>{invoiceData.from.name}</Text>
          <Text>{invoiceData.from.address}</Text>
          <Text>Phone: {invoiceData.from.phone}</Text>
          <Text>Email: {invoiceData.from.email}</Text>
          <Text>Company Number: {invoiceData.from.companyNumber}</Text>
          <Text>Tax ID: {invoiceData.from.taxId}</Text>
        </View>

        {/* Invoice Information */}
        <View
          style={[
            styles.invoiceInfo,
            { flex: 1, textAlign: right, lineHeight: "2px" },
          ]}
        >
          <Text>Invoice No: {invoiceData.invoiceNumber}</Text>
          <Text>Invoice Date: {invoiceData.invoiceDate}</Text>
          <Text>Due Date: {invoiceData.dueDate}</Text>
        </View>
      </View>

      {/* Recipient (To) Details */}
      <View style={styles.section}>
        <Text style={styles.subtitle}>TO</Text>
        <Text>{invoiceData.to.name}</Text>
        <Text>{invoiceData.to.address}</Text>
        <Text>Phone: {invoiceData.to.phone}</Text>
        <Text>Email: {invoiceData.to.email}</Text>
      </View>

      {/* Items Table */}
      <View style={styles.table}>
        {/* Table Header */}
        <View style={[styles.tableRow, styles.tableHeader]}>
          <Text style={styles.tableCol}>Description</Text>
          <Text style={styles.tableCol2}>Currency</Text>
          <Text style={styles.tableCol3}>Amount</Text>
        </View>

        {/* Table Rows */}
        {invoiceData.items.map((item, index) => (
          <View style={styles.tableRow} key={index}>
            <View
              style={[styles.tableCol, { textAlign: !left, lineHeight: "2px" }]}
            >
              <Text>{item.description}</Text>
              <Text>Appointment Id: {item.appointmentId}</Text>
              <Text>Appointment Date: {item.appointmentDate}</Text>
            </View>
            <Text style={styles.tableCol2}>{item.currency}</Text>
            <Text style={styles.tableCol3}>€{item.amount.toFixed(2)}</Text>
          </View>
        ))}
      </View>

      {/* Total Amount */}
      <View style={styles.section}>
        <Text style={styles.totalText}>
          TOTAL AMOUNT DUE: €{invoiceData.totalAmountDue.toFixed(2)}
        </Text>
      </View>

      {/* Footer */}
      <Text style={styles.footer}>{invoiceData.providerInfo}</Text>
    </Page>
  </Document>
);

// Styles for the PDF
const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontSize: 12,
    fontFamily: "Helvetica",
  },
  logo: {
    width: 150,
    height: 80,
    marginRight: 10,
    marginBottom: 15,
  },
  header: {
    marginBottom: 20,
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: 20,
  },
  section: {
    marginBottom: 15,
  },
  subtitle: {
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 5,
    color: "#0007",
  },
  invoiceInfo: {
    marginTop: 20,
    fontSize: 12,
    fontWeight: "bold",
  },
  table: {
    display: "table",
    width: "100%",
    borderWidth: 1,
    borderColor: "#000",
    borderStyle: "solid",
    marginBottom: 20,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCol: {
    width: "60%",
    padding: 5,
    borderWidth: 1,
    borderColor: "#000",
    textAlign: "center",
  },
  tableCol2: {
    width: "20%",
    padding: 5,
    borderWidth: 1,
    borderColor: "#000",
    textAlign: "center",
  },
  tableCol3: {
    width: "20%",
    padding: 5,
    borderWidth: 1,
    borderColor: "#000",
    textAlign: "center",
  },
  tableHeader: {
    backgroundColor: "#f0f0f0",
    fontWeight: "bold",
  },
  totalText: {
    fontSize: 14,
    fontWeight: "bold",
    textAlign: "right",
    marginTop: 10,
  },
  footer: {
    marginTop: 30,
    textAlign: "center",
    fontSize: 10,
    color: "#666",
  },
});

export default InvoiceDownloadComponent;
