import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

import Button from "../../form/Button";
import { useSocket } from "../../socket/SocketProvider";
import useBatteryStatus from "../../elements/hooks/useBatteryStatus";
import usePageVisibility from "../../../util/PageVisibility";
import LeaveModal from "../../elements/LeaveModal";
import {
  appointmentCancel,
  appointmentClose,
} from "../../../service/videoCallService";
import { getProfileDetails } from "../../../service/authService";

dayjs.extend(duration);

const UserChatsHeader = ({
  toggleVideo,
  toggleAudio,
  isFullscreen,
  isAudioEnabled,
  isVideoEnabled,
  toggleFullscreen,
  setIsAudioEnabled,
  setIsVideoEnabled,
  isInterviewStarted,
  stopStreaming = () => {},
  subscriber,
}) => {
  const navigate = useNavigate();
  const socket = useSocket();
  const { id } = useParams();

  // Memoized and parsed data
  const userDetails = useMemo(
    () => JSON.parse(localStorage.getItem("userDetails") || "{}"),
    []
  );
  const appointmentData = useMemo(
    () => JSON.parse(localStorage.getItem("appointmentData") || "{}"),
    []
  );

  // State management
  const [elapsedTime, setElapsedTime] = useState("00 : 00 : 00");
  const [isOneHourPassed, setIsOneHourPassed] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  // Battery status
  const { level, charging } = useBatteryStatus();

  // Calculated values
  const calculateElapsedTime = useCallback(() => {
    const currentTime = dayjs();
    const startTime = dayjs(
      appointmentData?.appointmentStartTime || currentTime
    );
    const duration = dayjs.duration(currentTime.diff(startTime));

    const hours = String(duration.hours()).padStart(2, "0");
    const minutes = String(duration.minutes()).padStart(2, "0");
    const seconds = String(duration.seconds()).padStart(2, "0");

    if (duration.asHours() >= 1) {
      setIsOneHourPassed(true);
    }

    return `${hours} : ${minutes} : ${seconds}`;
  }, [appointmentData]);

  // Elapsed time tracking
  useEffect(() => {
    const interval = setInterval(() => {
      setElapsedTime(calculateElapsedTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [calculateElapsedTime]);

  // Battery level monitoring
  useEffect(() => {
    if (charging === false) {
      if (Math.round(level * 100) <= 5) {
        handleAppointmentEnd("lowBattery");
      } else if (Math.round(level * 100) === 20) {
        alert("Your battery is low!");
      }
    }
  }, [level, charging]);

  // One-hour duration limit
  useEffect(() => {
    if (isOneHourPassed) {
      toast.info("You have reached your maximum calling duration!");
      handleAppointmentEnd("duration");
    }
  }, [isOneHourPassed]);

  // Subscriber connection monitoring
  useEffect(() => {
    if (!subscriber) {
      const timer = setTimeout(
        () => handleAppointmentEnd("noSubscriber"),
        1 * 60 * 1000
      );
      return () => clearTimeout(timer);
    }
  }, [subscriber]);

  // Media controls
  const handleVideoToggle = () => {
    const newState = !isVideoEnabled;
    setIsVideoEnabled(newState);
    toggleVideo(newState);
  };

  const handleAudioToggle = () => {
    const newState = !isAudioEnabled;
    setIsAudioEnabled(newState);
    toggleAudio(newState);
  };

  useSocket("videoSessionDisconnectResponse", async (data) => {
    if (
      data?.disconnect === true &&
      data?.appointmentId === appointmentData?.appointmentId
    ) {
      if (userDetails?.roleCode === "user") {
        window.location.href = `/review-appointment/${appointmentData?.appointmentId}`;
      } else {
        window.location.href = `/appointments-details/${appointmentData?.appointmentId}`;
        localStorage.removeItem("appointmentData");
      }
      localStorage.removeItem("joined");
      // appointmentClose({ appointmentId: data?.appointmentId }).then((res) => {
      //   console.log(first)
      //   if (res?.data?.status == 200 || res?.data?.status == 201) {
      //     if (userDetails?.roleCode === "user") {
      //       window.location.href = `/review-appointment/${appointmentData?.appointmentId}`;
      //     } else {
      //       window.location.href = "/appointments";
      //       localStorage.removeItem("appointmentData");
      //     }
      //   }
      // });
      // if (userDetails?.roleCode === "user") {
      //   window.location.href = `/review-appointment/${appointmentData?.appointmentId}`;
      // } else {
      //   window.location.href = "/appointments";
      //   localStorage.removeItem("appointmentData");
      // }
    }
  });

  // Appointment management
  const handleAppointmentEnd = useCallback(
    (reason) => {
      appointmentClose({ appointmentId: appointmentData?.appointmentId })
        .then((res) => {
          if (res?.data?.status === 200 || res?.data?.status === 201) {
            socket?.emit("videoSessionDisconnect", {
              disconnect: true,
              appointmentId: appointmentData?.appointmentId,
            });

            stopStreaming();

            const redirectPath =
              userDetails?.roleCode === "user"
                ? `/review-appointment/${appointmentData?.appointmentId}`
                : `/appointments-details/${appointmentData?.appointmentId}`;

            window.location.href = redirectPath;
          }
        })
        .catch(console.error);
    },
    [appointmentData, socket, userDetails, stopStreaming]
  );

  const handleJoinLater = useCallback(() => {
    appointmentCancel(
      { appointmentId: appointmentData?.appointmentId },
      setLoading
    );

    socket?.emit("vonageSessionReject", {
      disconnect: true,
      appointmentId: appointmentData?.appointmentId,
      role: userDetails?.roleCode,
      consultantId: appointmentData?.consultantId,
      userId: appointmentData?.callerData?.user?.id,
    });

    socket?.emit("consultantBusy", {
      consultantId: appointmentData?.consultantId,
      userId: appointmentData?.callerData?.user?.id,
      isBusy: false,
    });

    localStorage.removeItem("appointmentData");
    localStorage.removeItem("joined");
    window.location.href = "/";
  }, [appointmentData, socket, userDetails]);

  // Page visibility tracking
  usePageVisibility(
    localStorage.getItem("auth_token"),
    socket,
    userDetails?._id
  );

  return (
    <div className="md:flex block bg-slate-900 md:px-8 px-5 py-4 absolute bottom-0 w-full z-10 justify-between">
      <>
        <div className="flex items-center md:space-x-5 space-x-2 text-gray-300 lg:w-5/12 md:w-9/12 w-full md:justify-start justify-between">
          <div className="flex space-x-2 md:w-[120px] w-[160px]">
            <div className="text-red-600">
              <i className="fa-sharp fa-regular fa-record-vinyl"></i>
            </div>
            <div>{elapsedTime}</div>
          </div>
          <div className="text-sm text-white bg-prontopsy-pink/60 px-4 py-2 rounded-full">
            {userDetails?.roleCode === "user"
              ? appointmentData?.callerData?.consultant?.name
              : appointmentData?.callerData?.user?.name}
          </div>
          <div className="text-sm capitalize text-white bg-prontopsy-pink/60 px-4 py-2 rounded-full">
            {appointmentData?.mode}
          </div>
        </div>
        <div className="flex justify-between md:w-[53%] w-full items-center md:mt-0 mt-5">
          <div className="text-slate-100 md:justify-center justify-end flex gap-5">
            {isInterviewStarted && (
              <div className="flex gap-5 text-xl">
                {appointmentData?.mode == "video" && (
                  <button onClick={handleVideoToggle}>
                    <div className="w-10 aspect-square bg-prontopsy-lightblue/50 text-base rounded-full flex justify-center items-center">
                      <i
                        className={` ${
                          !isVideoEnabled
                            ? " fa-solid fa-video-slash"
                            : "fa-solid fa-video"
                        }`}
                      ></i>
                    </div>
                  </button>
                )}
                <button onClick={handleAudioToggle}>
                  <div className="w-10 aspect-square bg-prontopsy-lightblue/50 text-base rounded-full flex justify-center items-center">
                    <i
                      className={` ${
                        !isAudioEnabled
                          ? " fa-solid fa-microphone-slash"
                          : "fa-solid fa-microphone"
                      }`}
                    ></i>
                  </div>
                </button>
              </div>
            )}
            <button
              onClick={toggleFullscreen}
              className={`bg-blue-500 w-9 aspect-square rounded text-base flex justify-center items-center ${
                isFullscreen ? "bg-blue-600" : "bg-blue-500"
              }`}
            >
              {isFullscreen ? (
                <i class="fa-solid fa-compress-wide"></i>
              ) : (
                <i class="fa-solid fa-expand"></i>
              )}
            </button>
          </div>
          <div className="flex items-center text-gray-100 space-x-10 md:justify-end justify-center">
            {/* {isInterviewStarted && ( */}
            <Button
              buttonLabel={"Leave"}
              buttonIcon={"fa-solid fa-square-phone-hangup"}
              buttonIconPosition={"left"}
              buttonClasses={
                "uppercase bg-red-700 text-sm hover:bg-red-600 text-white px-4 py-2 rounded"
              }
              buttonFunction={() => {
                isFullscreen && toggleFullscreen();
                setIsOpen(true);
              }}
            />
            {/* )} */}
          </div>
        </div>
      </>
      <LeaveModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onConfirm={() => handleAppointmentEnd("manual")}
        loading={loading}
      />
    </div>
  );
};

export default React.memo(UserChatsHeader);
