import React, { useEffect, useRef, useState } from "react";
import Button from "../../components/form/Button";
import Image from "../../components/elements/Image";
import { useDispatch, useSelector } from "react-redux";
import { setTabSelection } from "../../store/slice/selectedTabSlice";
import { useNavigate } from "react-router-dom";
import {
  addNoteService,
  blockClientService,
  fetchClientList,
  fetchClientNotesList,
} from "../../service/clientService";
import {
  commonFormLabels,
  submitBtn,
} from "../../components/elements/language";
import SpinLoader from "../../components/elements/table/SpinLoader";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import NoDataFound from "../../components/elements/NoDataFound";
import Modal from "../../components/elements/Modal";
import TextAreaAutoSize from "../../components/form/TextareaAutoSize";

const MyClients = () => {
  const token = localStorage.getItem("auth_token") || Cookies.get("auth_token");
  const [loaded, setLoaded] = useState(true);
  const [blockLoaded, setBlockLoaded] = useState(true);
  const [list, setList] = useState([]);
  const [allList, setAllList] = useState([]);
  const lang = useSelector((state) => state?.languageSlice?.data);
  const language = localStorage.getItem("language") || lang;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //For pagination
  const [limit, setLimit] = useState(4);
  const [totalDocs, setTotalDocs] = useState(null);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [page, setPage] = useState(1);

  const [scrolledToBottom, setScrolledToBottom] = useState(false);
  const scrollableRef = useRef(null);

  useEffect(() => {
    dispatch(setTabSelection({}));
  }, []);
  useEffect(() => {
    if (!token) {
      navigate("/");
      localStorage.clear();
      Cookies.remove("auth_token");
    }
  }, [token, navigate, Cookies]);

  const myClients = {
    en: "My clients",
    es: "Mis clientes",
    it: "I miei clienti",
  };

  useEffect(() => {
    const handleScroll = () => {
      if (scrollableRef.current) {
        const { scrollHeight, scrollTop, clientHeight } = scrollableRef.current;
        const scrolledToBottom = scrollTop + clientHeight >= scrollHeight;

        setScrolledToBottom(scrolledToBottom);
      }
    };

    const scrollableElement = scrollableRef.current;
    if (scrollableElement) {
      scrollableElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollableElement) {
        scrollableElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [allList]);
  useEffect(() => {
    if (scrolledToBottom && hasNextPage && loaded) {
      setPage((pre) => pre + 1);
    }
  }, [scrolledToBottom, hasNextPage, loaded]);

  useEffect(() => {
    fetchClientList(
      { limit: limit, page: page },
      token,
      setList,
      setLoaded,
      setHasNextPage
    ).then((res) => {
      if (res?.data?.docs?.status === 200) {
        let metaData = res?.data?.docs?.metadata;
        setTotalDocs(metaData?.totalDocs);
        page !== 1
          ? setAllList((prevList) => [...prevList, ...res?.data?.docs?.data])
          : setAllList(res?.data?.docs?.data);
      }
    });
  }, [token, page]);

  const handleUnblock = (id) => {
    blockClientService({ clientId: id }, token, setBlockLoaded).then((res) => {
      if (res?.data?.status === 200) {
        toast.success(res?.data?.message);
        setAllList((prevList) =>
          prevList.map((elm) => {
            if (elm?._id?.clientId === id) {
              return { ...elm, isBlocked: !elm?.isBlocked };
            }
            return elm;
          })
        );
      }
    });
  };

  const appointments =
    list.length > 0 &&
    allList.map((elm) => ({
      id: elm?.clientData?._id,
      image: elm?.clientData?.image,
      name: elm?.clientData?.firstName + " " + elm?.clientData?.lastName,
      email: elm?.clientData?.email,
      phone: elm?.clientData?.address?.phone,
      zip: elm?.clientData?.address?.zipcode,
      address1: elm?.clientData?.address?.address1,
      address2: elm?.clientData?.address?.address2,
      city: elm?.clientData?.address?.city,
      state: elm?.clientData?.address?.state,
      country: elm?.clientData?.address?.country,
      isBlocked: elm?.isBlocked || false,
      buttonTextBlock: {
        en: "Block",
        es: "Bloquear",
        it: "Bloccare",
      },
      buttonTextUnblock: {
        en: "Unblock",
        es: "Desbloquear",
        it: "Sbloccare",
      },
      buttonTextNote: {
        en: "Note",
        es: "Nota",
        it: "Nota",
      },
      buttonIconUnblock: "fa-regular fa-unlock-keyhole",
      buttonIconPink: "fa-regular fa-ban",
      buttonIconBlue: "fa-regular fa-file",
    }));

  //Note related
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [noteInput, setNoteInput] = useState("");
  const [noteLoaded, setNoteLoaded] = useState(true);
  const [noteAddLoaded, setNoteAddLoaded] = useState(true);
  const [noteList, setNoteList] = useState([]);
  const [id, setId] = useState(null);

  const handleSubmit = () => {
    if (!noteInput || noteInput.trim().length === 0) {
      toast.error("Note is required!");
      return;
    }
    addNoteService(
      { userId: id, message: noteInput },
      token,
      setNoteAddLoaded
    ).then((res) => {
      if (res.data.status == 200) {
        setNoteInput("");
        setIsModalOpen(false);
        setNoteList([res?.data?.data]);
      }
    });
  };
  // console.log(noteList[0]?.message, "note list");
  useEffect(() => {
    noteInput.length > 0 && setNoteInput(noteList[0]?.message);
  }, [noteList.length > 0]);

  return (
    <>
      {!loaded ? (
        <SpinLoader />
      ) : (
        <div className="relative divide-y divide-neutral-300">
          <h5 className="text-lg text-prontopsy-blue font-RobotoSlab font-bold py-2">
            {myClients?.[language]}
          </h5>
          {allList.length === 0 && loaded && <NoDataFound />}
          <div
            ref={scrollableRef}
            className="h-[80vh] overflow-auto shadow-md p-2"
          >
            {list.length > 0 &&
              appointments.map((appointment) => (
                <div key={appointment.id}>
                  <div className="block lg:flex justify-between gap-5 py-5 space-y-5 md:space-y-0">
                    <div className="flex gap-5 flex-shrink">
                      <div className="w-16 h-16 rounded-full overflow-hidden flex-shrink-0">
                        <Image
                          src={appointment?.image}
                          alt={""}
                          effect={"blur"}
                          className={"object-cover w-full h-full"}
                        />
                      </div>
                      <div className="flex-shrink">
                        <div className="text-base text-prontopsy-blue font-bold italic font-UnisonPro uppercase mb-2">
                          {appointment.name}
                        </div>
                        <div className="text-gray-600 font-bold font-RobotoSlab text-xs uppercase !leading-relaxed">
                          {`${commonFormLabels?.email?.[language]} : ${
                            appointment?.email || "N/A"
                          }`}
                        </div>
                        <div className="text-gray-600 font-bold font-RobotoSlab text-xs !leading-relaxed">
                          {`${commonFormLabels?.phone?.[language]} : ${
                            appointment?.phone || "N/A"
                          }`}
                        </div>
                        <div
                          className="text-gray-600 font-bold font-RobotoSlab text-xs !leading-relaxed"
                          dangerouslySetInnerHTML={{
                            __html: `${
                              commonFormLabels?.address?.[language]
                            } : ${appointment?.address1 || "N/A"}`,
                          }}
                        ></div>
                        <div className="text-gray-600 font-bold font-RobotoSlab text-xs !leading-relaxed">
                          {`${commonFormLabels?.code?.[language]} : ${
                            appointment?.zipcode || "N/A"
                          }`}
                        </div>
                        <div className="text-gray-600 font-bold font-RobotoSlab text-xs !leading-relaxed">
                          {`${commonFormLabels?.city?.[language]} : ${
                            appointment?.city || "N/A"
                          }`}
                        </div>
                        <div className="text-gray-600 font-bold font-RobotoSlab text-xs !leading-relaxed">
                          {`${commonFormLabels?.state?.[language]} : ${
                            appointment?.state || "N/A"
                          }`}
                        </div>
                        <div className="text-gray-600 font-bold font-RobotoSlab text-xs !leading-relaxed">
                          {`${commonFormLabels?.country?.[language]} : ${
                            appointment?.country || "N/A"
                          }`}
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center md:justify-end justify-center gap-3 flex-shrink-0">
                      <div className="relative">
                        <Button
                          buttonLabel={
                            appointment?.isBlocked
                              ? appointment?.buttonTextUnblock?.[language]
                              : appointment?.buttonTextBlock?.[language]
                          }
                          buttonLabelClasses={
                            "!uppercase !text-" +
                            appointment?.buttonColor +
                            " !text-base !whitespace-nowrap !font-medium"
                          }
                          buttonIconPosition={"left"}
                          buttonIcon={
                            appointment?.isBlocked
                              ? appointment?.buttonIconUnblock
                              : appointment?.buttonIconPink
                          }
                          buttonClasses={`${
                            appointment?.isBlocked
                              ? "!bg-red-500 !rounded-xl !px-2 !h-12 !text-white !border-2 !border-red-600"
                              : "!bg-transparent !rounded-xl !px-2 !h-12 !text-prontopsy-pink !border-2 !border-prontopsy-pink"
                          }`}
                          buttonFunction={() => handleUnblock(appointment?.id)}
                          isDisable={!blockLoaded}
                        />
                      </div>
                      <div className="relative">
                        <Button
                          buttonLabel={appointment?.buttonTextNote?.[language]}
                          buttonLabelClasses={
                            "!uppercase !text-white !text-base !whitespace-nowrap !font-medium"
                          }
                          buttonIconPosition={"left"}
                          buttonIcon={appointment?.buttonIconBlue}
                          buttonClasses={
                            "!bg-prontopsy-blue !rounded-xl !px-6 !h-12 !text-white"
                          }
                          buttonFunction={() => {
                            // navigate(`/client-notes/${appointment?.id}`)
                            setId(appointment?.id);
                            fetchClientNotesList(
                              { clientId: appointment.id },
                              token,
                              setNoteList,
                              setNoteLoaded
                            ).then((res) => {
                              if (res?.data?.docs?.status === 200) {
                                let metaData = res?.data?.docs?.metadata;
                                setTotalDocs(metaData?.totalDocs);
                                setNoteList(res?.data?.docs?.data);
                                if (
                                  Array.isArray(res?.data?.docs?.data) &&
                                  res?.data?.docs?.data.length > 0
                                ) {
                                  setNoteInput(
                                    res?.data?.docs?.data[0]?.message
                                  );
                                }
                              }
                            });
                            setIsModalOpen(true);
                          }}
                          // loading={!noteLoaded}
                        />
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>
              ))}
            {!loaded && (
              <div className="flex justify-center mt-4">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-prontopsy-blue"></div>
              </div>
            )}
          </div>
        </div>
      )}

      {/* Note mmodal */}
      <Modal isOpen={isModalOpen} setIsOpen={setIsModalOpen} titleShow={false}>
        <div className="relative mt-2">
          <div className="absolute right-0 -top-6">
            {/* <button onClick={closeModal}>sl</button> */}
            <Button
              buttonLabel={""}
              buttonLabelClasses={
                "!uppercase !text-" +
                " !text-base !whitespace-nowrap !font-medium"
              }
              buttonIconPosition={"left"}
              buttonIcon={"fa-regular fa-xmark"}
              buttonClasses={
                "!bg-transparent  !px-0 !py-0 !h-4 !text-prontopsy-pink  !text-2xl mt-2  "
              }
              buttonFunction={() => setIsModalOpen(false)}
            />
          </div>

          {!noteLoaded ? (
            <div className=" top-0 left-0 w-full h-[30px] bg-gray-300 bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-transparent p-6 rounded-lg shadow-lg">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-prontopsy-blue"></div>
              </div>
            </div>
          ) : (
            <div className="col-span-12 lg:col-span-6">
              <div className="relative">
                <TextAreaAutoSize
                  label={"*Write Note"}
                  iconClasses={"hidden"}
                  inputPlaceholder={"Write your note here .."}
                  inputType={Text}
                  divClasses={"h-12 bg-transparent !border-black rounded-none"}
                  labelClasses={
                    "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab"
                  }
                  inputClasses={
                    "!bg-transparent !h-12 !min-h-32 !overflow-auto"
                  }
                  inputName={"name"}
                  onChange={(e) => setNoteInput(e.target.value)}
                  inputValue={noteInput}
                />
              </div>

              <div className="relative w-full mt-3">
                {/* <button onClick={closeModal}>sl</button> */}
                <Button
                  buttonLabel={submitBtn?.[language]}
                  buttonLabelClasses={
                    "!uppercase !text-" +
                    " !text-base !whitespace-nowrap !font-medium"
                  }
                  buttonClasses={
                    "!py-5 !px-6 !h-5 !text-white  !text-xl  !bg-prontopsy-pink text-white "
                  }
                  buttonFunction={() => handleSubmit()}
                  isDisable={!noteAddLoaded}
                  loading={!noteAddLoaded}
                />
              </div>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default MyClients;
