import { apiRequest } from "../util/async/apiUtils";

export const getVonageToken = async (params) => {
  try {
    const res = await apiRequest(
      `${"post"}`,
      `${"/communicate/get-video-session-token"}`,
      {
        body: {
          ...params,
        },
      }
    );

    return res;
  } catch (err) {
    return err;
  }
};

export const bookAppointment = async (params) => {
  try {
    const res = await apiRequest(
      `${"post"}`,
      `${"/appointment/booking-appointment"}`,
      {
        body: {
          ...params,
        },
      }
    );

    return res;
  } catch (err) {
    return err;
  }
};

export const reviewAddFront = async (params) => {
  try {
    const res = await apiRequest(`${"post"}`, `${"/review/front/add"}`, {
      body: {
        ...params,
      },
    });

    return res;
  } catch (err) {
    return err;
  }
};

export const mediaChat = async (params) => {
  try {
    const res = await apiRequest(
      `${"post"}`,
      `${"/media/add"}`,
      {
        body: {
          ...params,
        },
      },
      "multipart/form-data"
    );

    return res;
  } catch (err) {
    return err;
  }
};

export const appointmentClose = async (params) => {
  try {
    const res = await apiRequest(
      `${"post"}`,
      `${"appointment/appointment-close"}`,
      {
        body: {
          ...params,
        },
      }
    );

    return res;
  } catch (err) {
    return err;
  }
};

export const appointmentCancel = async (params, setLoading = () => {}) => {
  setLoading(true);
  try {
    const res = await apiRequest(
      `${"post"}`,
      `${"appointment/appointment-cancel"}`,
      {
        body: {
          ...params,
        },
      }
    );
    setLoading(false);
    return res;
  } catch (err) {
    setLoading(false);
    return err;
  }
};

export const chatList = async (params) => {
  try {
    const res = await apiRequest(
      `${"get"}`,
      `${"communicate/chat-load-message"}`,
      {
        queries: {
          ...params,
        },
      }
    );

    return res;
  } catch (err) {
    return err;
  }
};
