import axios from "axios";
import BASE_URL from "../config/host";

export const fetchConsultantList = async (
  params,
  setList,
  setLoaded = () => {}
) => {
  setLoaded(false);

  try {
    let response = await axios.get(`${BASE_URL}/user/front-list`, {
      params: {
        roleCode: "consultant",
        limit: 500,
        ...params,
      },
    });

    if (response.status === 200) {
      setList(response.data?.docs?.data);
      setLoaded(true);
    }

    return response;
  } catch (error) {
    setLoaded(true);
    return error.response;
  }
};

export const fetchConsultantStatusList = async () => {
  try {
    let response = await axios.get(`${BASE_URL}/user/online-status`);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const fetchConsultantListPrivately = async (
  token,
  params,
  setList,
  setLoaded = () => {}
) => {
  setLoaded(false);
  try {
    let response = await axios.get(`${BASE_URL}/user/front-list`, {
      params: {
        roleCode: "consultant",
        ...params,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      setList(response.data?.docs?.data);
      setLoaded(true);
    }

    return response;
  } catch (error) {
    setLoaded(true);
    return error.response;
  }
};

export const fetchConsultantDetails = async (
  token,
  params,
  setList,
  setLoaded = () => {}
) => {
  setLoaded(false);
  try {
    let response = await axios.get(
      `${BASE_URL}/user/front-details/${params.id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.data.status === 200) {
      setList(response.data.docs);
      setLoaded(true);
    }

    return response.data;
  } catch (error) {
    setLoaded(true);
    return error.response;
  }
};

export const mankeFavoriteService = async (
  params,
  token,
  setLoaded = () => {}
) => {
  setLoaded(false);
  try {
    let response = await axios.put(`${BASE_URL}/user/manage-favorite`, params, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setLoaded(true);
    return response;
  } catch (error) {
    setLoaded(true);
    return error.response;
  }
};

export const fetchWishlistedData = async (
  token,
  params,
  setList,
  setLoaded = () => {},
  setHasNextPage = () => {}
) => {
  setLoaded(false);
  try {
    let response = await axios.get(`${BASE_URL}/user/wishlist`, {
      params: {
        ...params,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    setList(response.data.docs.data);
    setLoaded(true);
    setHasNextPage(response.data.docs.metadata?.hasNextPage);
    return response;
  } catch (error) {
    setLoaded(true);
    return error.response;
  }
};
