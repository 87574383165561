import React, {
  createContext,
  useEffect,
  useState,
  useCallback,
  useContext,
} from "react";
import { useSelector } from "react-redux";
import io from "socket.io-client";
import useOnlineStatus from "../../util/OnlineStatus";
import Cookies from "js-cookie";
const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const [connecting, setConnecting] = useState(false);
  const authToken =
    localStorage.getItem("auth_token") || Cookies.get("auth_token");
  const authData = useSelector((state) => state.tokenSlice?.data);
  const isOnline = useOnlineStatus();

  const SOCKET_URL = "https://dev.api.prontopsy.com";

  const connectSocket = () => {
    if (connecting || socket) return;
    setConnecting(true);

    const newSocket = io(SOCKET_URL, {
      auth: { token: authToken || authData },
      reconnection: true,
      reconnectionAttempts: Infinity,
      reconnectionDelay: 2000,
    });

    newSocket.on("connect", () => {
      console.log("Socket connected");
      setConnecting(false);
      setSocket(newSocket);
    });

    newSocket.on("disconnect", () => {
      console.log("Socket disconnected");
      setSocket(null);
      if (isOnline) connectSocket();
    });

    newSocket.on("connect_error", (error) => {
      console.error("Connection error:", error);
      setConnecting(false);
    });

    setSocket(newSocket);
  };

  const disconnectSocket = () => {
    if (socket) {
      socket.disconnect();
      setSocket(null);
      setConnecting(false);
    }
  };

  useEffect(() => {
    if ((authToken || Object.keys(authData).length > 0) && isOnline) {
      connectSocket();
    }

    return () => {
      disconnectSocket();
    };
  }, [authToken, authData, isOnline]);

  return (
    <SocketContext.Provider
      value={{
        socket,
        connectSocket,
        reconnectSocket: connectSocket,
        disconnectSocket,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = (eventName, callback, dependencies = []) => {
  const { socket } = useContext(SocketContext);
  const memoizedCallback = useCallback(callback, dependencies);

  useEffect(() => {
    if (!socket) return;

    socket.on(eventName, memoizedCallback);

    return () => {
      socket.off(eventName, memoizedCallback);
    };
  }, [socket, eventName, memoizedCallback]);

  return socket;
};

export default SocketContext;
