import React, { useState } from "react";
import Image from "../../elements/Image";
import Signupimage from "../../../assets/images/signupimage.webp";
import InputGroup from "../../form/Input";
import Button from "../../form/Button";
import { toast } from "react-toastify";
import { resetPasswordService } from "../../../service/authService";
import { useNavigate } from "react-router-dom";
import PageBanner from "../PageBanner";
import { useSelector } from "react-redux";

const ForgotPassword = ({ image }) => {
  const regex =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{8,}$/;
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(true);
  const [signupData, setSignupData] = useState({});
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [clicked, setClicked] = useState(false);
  const data = useSelector((state) => state.pageSlice.data);
  const info =
    data &&
    Array.isArray(data) &&
    data.length > 0 &&
    data.filter((elm) => elm.title.en === "signup");
  const bannerData = {
    image:
      info &&
      info[0]?.banner &&
      info[0]?.banner.length > 0 &&
      info[0]?.banner[0]?.image?.url,
    title: "Forgot Password",
  };

  const handleData = (e) => {
    setSignupData((pre) => ({
      ...pre,
      [e.target.name]: e.target.value,
    }));
    setClicked(false);
  };

  const loginData = {
    image: Signupimage,
    title: "Client Forgot Password",
  };

  const handleSignup = () => {
    setClicked(true);
    signupData.password &&
      !signupData.confirmPassword &&
      toast.warning("Confirm Password is Required!");
    if (signupData.newPassword && signupData.confirmPassword) {
      resetPasswordService(signupData, setLoaded).then((res) => {
        if (res.data.status === 200) {
          toast.success(res.data.message);
          navigate("/signin");
        } else {
          toast.error(res.data.message);
        }
      });
    }
  };
  const isPasswordInvalid = newPassword && !regex.test(newPassword);
  const isSignupDataPasswordEmpty = clicked && !signupData.newPassword;
  return (
    <>
      <PageBanner bannerData={bannerData} />
      <div className="relative py-10 md:py-12 lg:py-16 xl:py-20 ">
        <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto">
          <div className="block lg:flex shadow-2xl">
            <div className="w-full lg:w-1/2 p-10 hidden md:block">
              <div className="flex flex-col justify-center w-full aspect-[4/5] overflow-hidden">
                <Image
                  src={image || ""}
                  alt="Image"
                  effect="blur"
                  className="object-cover w-full h-full"
                />
              </div>
            </div>
            <div className="w-full lg:w-1/2">
              <div className="bg-prontopsy-yellow px-5 py-5 h-full flex flex-col items-center justify-center">
                <div className="w-full mx-auto max-w-lg text-center space-y-4">
                  {loginData.title && (
                    <h2
                      className="!mb-6 sm:!mb-8 md:!mb-10 lg:!mb-12 xl:!mb-16 text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl text-prontopsy-blue font-UnisonPro italic font-bold !leading-[1.1]"
                      dangerouslySetInnerHTML={{ __html: loginData.title }}
                    ></h2>
                  )}
                  <div className="relative">
                    <InputGroup
                      onChange={(e) => {
                        handleData(e);
                        setNewPassword(e.target.value);
                      }}
                      inputValue={signupData.newPassword}
                      inputName="newPassword"
                      labelClasses="font-RobotoSlab"
                      inputPlaceholder="New Password"
                      inputType="newPassword"
                      divClass="!border !border-black !bg-transparent"
                      inputClasses="!text-black border-none placeholder:text-black h-10 md:h-12 lg:h-14 xl:h-16 rounded-none !bg-black/20"
                      errorType={
                        isSignupDataPasswordEmpty
                          ? "warning"
                          : isPasswordInvalid
                          ? "warning"
                          : ""
                      }
                      errorText={
                        newPassword && !regex.test(newPassword)
                          ? "Minimum eight characters, at least one letter, one number and one special character"
                          : "Password is required!"
                      }
                    />
                  </div>

                  <div className="relative">
                    <InputGroup
                      inputName="confirmPassword"
                      onChange={(e) => {
                        handleData(e);
                        setConfirmPassword(e.target.value);
                      }}
                      inputValue={signupData.confirmPassword}
                      labelClasses="font-RobotoSlab"
                      inputPlaceholder="Confirm Password"
                      inputType="password"
                      divClass="!border !border-black !bg-transparent"
                      inputClasses="!text-black border-none placeholder:text-black h-10 md:h-12 lg:h-14 xl:h-16 rounded-none !bg-black/20"
                      errorType={
                        confirmPassword && newPassword !== confirmPassword
                          ? "danger"
                          : ""
                      }
                      errorText={
                        (confirmPassword &&
                          !confirmPassword.match(newPassword) &&
                          "Confirm Password Does Not Match With Password!") ||
                        (clicked &&
                          !signupData.confirmPassword &&
                          "Confirm password is required!")
                      }
                      isDisabled={!signupData.newPassword}
                    />
                  </div>
                  <div className="flex justify-center">
                    <div className="relative">
                      <Button
                        buttonLabel="Change Password"
                        buttonLabelClasses="!capitalize font-RobotoSlab"
                        buttonClasses="!h-10 lg:!h-12 !bg-prontopsy-blue !px-16"
                        buttonFunction={handleSignup}
                        isDisable={!loaded}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
