import React, { useState } from "react";
import { useSelector } from "react-redux";
import InputGroup from "../../form/Input";
import Button from "../../form/Button";

const ResetPasswordModal = ({
  isOpen,
  onClose,
  onSubmit,
  loaded = true,
  isClicked,
  setIsClicked = () => {},
  handleGoBack,
  signupData,
  setSignupData = () => {},
}) => {
  const siteData = useSelector((state) => state.siteSettings.data);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const regex =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{8,}$/;

  const handleData = (e) => {
    setSignupData((pre) => ({
      ...pre,
      [e.target.name]: e.target.value,
    }));
    setIsClicked(false);
  };
  const isPasswordInvalid = newPassword && !regex.test(newPassword);
  const isSignupDataPasswordEmpty = isClicked && !signupData?.newPassword;

  return (
    <div
      className={`fixed z-10 inset-0 overflow-y-auto ${
        isOpen ? "block" : "hidden"
      }`}
    >
      <div className=" flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
        &#8203;
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-white px-4 pt-5 pb-4">
            <div className="pb-[70px] pt-[50px]">
              <div className="mt-3 text-center ">
                <div className="w-full">
                  <img
                    className="m-auto"
                    src={siteData?.logo?.url}
                    height="40px"
                    width="100px"
                    alt="Your Company"
                    crossorigin="anonymous"
                  />
                </div>
                <div className="text-center font-bold mt-5">
                  <h2 className="text-blue-600 text-2xl">Forget Password ?</h2>
                  <h3 className="mt-4 text-gray-600 text-xl">
                    Choose Your New <br />
                    Password
                  </h3>
                </div>
                <div className="mt-2">
                  <div>
                    <InputGroup
                      onChange={(e) => {
                        handleData(e);
                        setNewPassword(e.target.value);
                      }}
                      inputValue={signupData?.newPassword}
                      inputName="newPassword"
                      labelClasses="font-RobotoSlab"
                      inputPlaceholder="New Password"
                      inputType="password"
                      divClass="!border !border-black !bg-transparent"
                      inputClasses="!text-black border-none placeholder:text-black h-10 md:h-12 lg:h-14 xl:h-16 rounded-none !bg-black/20"
                      errorType={
                        isSignupDataPasswordEmpty
                          ? "warning"
                          : isPasswordInvalid
                          ? "warning"
                          : ""
                      }
                      errorText={
                        newPassword && !regex.test(newPassword)
                          ? "Minimum eight characters, at least one letter, one number and one special character"
                          : "Password is required!"
                      }
                    />
                  </div>
                  <div className="mt-2">
                    <InputGroup
                      inputName="confirmPassword"
                      onChange={(e) => {
                        handleData(e);
                        setConfirmPassword(e.target.value);
                      }}
                      inputValue={signupData?.confirmPassword}
                      labelClasses="font-RobotoSlab"
                      inputPlaceholder="Confirm Password"
                      inputType="password"
                      divClass="!border !border-black !bg-transparent"
                      inputClasses="!text-black border-none placeholder:text-black h-10 md:h-12 lg:h-14 xl:h-16 rounded-none !bg-black/20"
                      errorType={
                        confirmPassword && newPassword !== confirmPassword
                          ? "danger"
                          : isClicked && !confirmPassword
                          ? "warning"
                          : ""
                      }
                      errorText={
                        (confirmPassword &&
                          !confirmPassword.match(newPassword) &&
                          "Confirm Password Does Not Match With New Password!") ||
                        (isClicked &&
                          !signupData?.confirmPassword &&
                          "Confirm password is required!")
                      }
                      isDisabled={!signupData?.newPassword}
                    />
                  </div>
                  <Button
                    buttonFunction={onSubmit}
                    buttonLabel={"Submit"}
                    isDisable={!loaded}
                    buttonClasses="w-full mt-4 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 "
                  >
                    Submit
                  </Button>
                  <br />
                  <button
                    onClick={() => handleGoBack()}
                    type="button"
                    className="w-full mt-1 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 "
                  >
                    Go Back
                  </button>
                  <br />
                  <button
                    type="button"
                    className="mt-1 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500    "
                    onClick={() => {
                      onClose();
                      setIsClicked(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordModal;
