import axios from "axios";
import BASE_URL from "../config/host";

export const fetchClientList = async (
  params,
  token,
  setList = () => {},
  setLoaded = () => {},
  setHasNextPage = () => {}
) => {
  setLoaded(false);
  try {
    let response = await axios.get(`${BASE_URL}/client/list`, {
      params: {
        ...params,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response?.data?.docs.status === 200) {
      setList(response?.data?.docs?.data);
      setLoaded(true);
      setHasNextPage(response.data.docs.metadata.hasNextPage);
    }

    return response;
  } catch (error) {
    setLoaded(true);
    return error.response;
  }
};

export const blockClientService = async (
  params,
  token,
  setLoaded = () => {}
) => {
  setLoaded(false);
  try {
    let response = await axios.post(`${BASE_URL}/client/block`, params, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setLoaded(true);
    return response;
  } catch (error) {
    setLoaded(true);
    return error.response;
  }
};

export const fetchClientNotesList = async (
  params,
  token,
  setList = () => {},
  setLoaded = () => {},
  setHasNextPage = () => {}
) => {
  setLoaded(false);
  try {
    let response = await axios.get(`${BASE_URL}/note/list`, {
      params: {
        ...params,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response?.data?.docs.status === 200) {
      setList(response?.data?.docs?.data);
      setLoaded(true);
      setHasNextPage(response.data.docs.metadata.hasNextPage);
    }

    return response;
  } catch (error) {
    setLoaded(true);
    return error.response;
  }
};

export const deleteNote = async (params, token, setLoaded = () => {}) => {
  setLoaded(false);
  try {
    const response = await axios.delete(`${BASE_URL}/note/delete`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: { ...params },
    });
    setLoaded(true);
    return response;
  } catch (error) {
    setLoaded(true);
    return error.response;
  }
};

export const addNoteService = async (params, token, setLoaded = () => {}) => {
  setLoaded(false);
  try {
    let response = await axios.post(`${BASE_URL}/note/user-note`, params, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setLoaded(true);
    return response;
  } catch (error) {
    setLoaded(true);
    return error.response;
  }
};
