import { useSelector } from "react-redux";
import Button from "../form/Button";
import Modal from "./Modal";

const langConfig = {
  warningWords: {
    en: " Are you sure you want to leave? All the Chat conversation will be erase.",
    es: "¿Estás seguro de que quieres irte? Se borrará toda la conversación del chat.",
    it: "Sei sicuro di voler partire? Tutta la conversazione in chat verrà cancellata.",
  },
};

const LeaveChatModal = ({ isOpen, setIsOpen, onConfirm, loading }) => {
  const lang = useSelector((state) => state?.languageSlice?.data);
  const language = localStorage.getItem("language") || lang;
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} titleShow={false} size={"sm"}>
      <div className="text-center my-6 lg:space-y-6 space-y-4 flex flex-col justify-center items-center">
        <span className="my-2 text-6xl text-red-500 bg-slate-300 rounded-full !w-32 !aspect-square flex justify-center items-center">
          <i class="fa-solid fa-message-xmark"></i>
        </span>
        <p className="mb-4">
          {/* Are you sure you want to leave? All the Chat conversation will be
          erase. */}
          {langConfig?.warningWords?.[language]}
        </p>
      </div>
      <div className="flex justify-center gap-3">
        <Button
          buttonHasLink={true}
          buttonFunction={() => setIsOpen(false)}
          buttonLabel={"Cancel"}
          buttonIcon={"fa-light fa-xmark"}
          buttonIconPosition={"left"}
          buttonClasses={
            "uppercase !bg-red-500 text-sm hover:!bg-prontopsy-blue"
          }
        />
        <Button
          // buttonHasLink={true}
          // buttonLink={"/"}
          loading={loading}
          buttonLabel={"Leave"}
          buttonIcon={"fa-solid fa-right-from-bracket"}
          buttonIconPosition={"left"}
          buttonClasses={"uppercase text-sm hover:!bg-prontopsy-blue"}
          buttonFunction={onConfirm}
        />
      </div>
    </Modal>
  );
};
export default LeaveChatModal;
