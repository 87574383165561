import React, { useEffect } from "react";
import UserEmailList from "../components/items/emailchatBox/userEmailList";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
const EmailChats = () => {
  const token = localStorage.getItem("auth_token") || Cookies.get("auth_token");
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate("/");
      localStorage.clear();
      Cookies.remove("auth_token");
    }
  }, [token, navigate, Cookies]);

  return (
    <>
      <div className="relative">
        <div className="relative flex">
          <UserEmailList />
        </div>
      </div>
    </>
  );
};

export default EmailChats;
