import React, { useCallback, useEffect, useState } from "react";
import { useSocket } from "../components/socket/SocketProvider";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../components/form/Button";
import { appointmentCancel } from "../service/videoCallService";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { getProfileDetails } from "../service/authService";
import Cookies from "js-cookie";

const languageConfig = {
  headingText: {
    en: "Please wait until other joins...",
    es: "Espere hasta que otros se unan...",
    it: "Si prega di attendere finché non si uniranno altri...",
  },
  paragraphText: {
    en: "We are connecting you to your session.",
    es: "Te estamos conectando a tu sesión.",
    it: "Ti stiamo collegando alla tua sessione.",
  },
  buttonText: {
    en: "Cancel Appointment",
    es: "Cancelar cita",
    it: "Annulla appuntamento",
  },
};

const WaitingPage = () => {
  const appointmentData = JSON.parse(localStorage.getItem("appointmentData"));
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const navigate = useNavigate();
  const socket = useSocket();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const lang = useSelector((state) => state?.languageSlice?.data);
  const language = localStorage.getItem("language") || lang;
  const token = localStorage.getItem("auth_token") || Cookies.get("auth_token");

  const fetchProfile = async () => {
    const response = await getProfileDetails(token);
    if (!response?.data?.docs?.appointmentData?._id) {
      navigate("/");
    }
  };
  useEffect(() => {
    fetchProfile();
    // !userDetails?.appointmentData && navigate("/");
  }, []);

  useEffect(() => {
    if (!appointmentData) return;
    const { appointmentStartTime, mode, appointmentId } = appointmentData;

    if (appointmentStartTime) {
      navigate(
        mode === "chat"
          ? `/text-chat/${appointmentId}`
          : `/chats/${appointmentId}`
      );
    }
  }, [appointmentData, navigate]);

  useSocket("indirectAppointmentConfirmJoinResponse", (dataa) => {
    const local = JSON.parse(localStorage.getItem("appointmentData"));

    if (
      (dataa?.joined && dataa?.appointmentId === local?._id) ||
      dataa?.appointmentId === local?.appointmentId
    ) {
      socket &&
        socket.emit("consultantBusy", {
          consultantId: dataa?.data?.consultantId,
          userId: dataa?.data?.clientId,
          isBusy: true,
        });
      dataa?.mode !== "chat"
        ? navigate(`/chats/${dataa?.appointmentId}`)
        : navigate(`/text-chat/${dataa?.appointmentId}`);
    }
  });

  const handleJoinLater = useCallback(() => {
    // setIsOpen(false);

    appointmentCancel({ appointmentId: id }, setLoading).then((res) => {
      if (res?.data?.status === 200) {
        toast.success(res?.data?.message);
        localStorage.removeItem("appointmentData");
        // navigate("/");
        window.location.href = "/";
      } else {
        toast.error(res?.data?.message, { autoClose: 3000 });
        window.location.href = "/";
      }
    });

    if (socket) {
      if (appointmentData?.mode === "chat") {
        socket.emit("chatSessionReject", {
          disconnect: true,
          appointmentId: appointmentData?.appointmentId,
          role: userDetails?.roleCode,
          consultantId: appointmentData?.consultantId || "",
          userId: appointmentData?.callerData?.user?.id,
        });
      } else {
        socket.emit("vonageSessionReject", {
          disconnect: true,
          appointmentId: appointmentData?.appointmentId,
          role: userDetails?.roleCode,
          consultantId: appointmentData?.consultantId,
          userId: appointmentData?.callerData?.user?.id,
        });
      }

      socket.emit("consultantBusy", {
        consultantId: appointmentData?.consultantId,
        userId: appointmentData?.callerData?.user?.id,
        isBusy: false,
      });
    }

    localStorage.removeItem("appointmentData");
    localStorage.removeItem("joined");
    // navigate("/");
    // window.location.href = "/";
  }, [appointmentData]);

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <div className="text-center">
        <h1 className="text-2xl font-semibold text-gray-700 animate-bounce">
          {/* Please wait until other joins... */}
          {languageConfig?.headingText?.[language]}
        </h1>
        <p className="mt-4 text-gray-500">
          {/* We are connecting you to your session. */}
          {languageConfig?.paragraphText?.[language]}
        </p>
      </div>
      <div>
        <Button
          buttonLabel={`${languageConfig?.buttonText?.[language]}`} //"Cancel Appointment"
          buttonIcon="fa-solid fa-square-phone-hangup"
          buttonIconPosition="left"
          buttonClasses="flex items-center gap-2 bg-red-600 hover:bg-red-500 text-white font-semibold px-6 py-3 mt-6 rounded-lg shadow-md transition duration-300 uppercase text-sm tracking-wider"
          buttonFunction={() => {
            handleJoinLater();
          }}
          isDisable={loading}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default WaitingPage;
