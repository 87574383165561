import React, { useCallback, useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { apiRequest } from "../util/async/apiUtils";
import { useSocket } from "../components/socket/SocketProvider";
import { getProfileDetails } from "../service/authService";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

const BlankLayout = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const socket = useSocket();
  const token = localStorage.getItem("auth_token") || Cookies.get("auth_token");
  const [appointmentData, setAppointmentData] = useState(
    JSON.parse(localStorage.getItem("appointmentData")) || null
  );
  const [profile, setProfile] = useState(
    JSON.parse(localStorage.getItem("userDetails")) || null
  );
  const [isAuth, setIsAuth] = useState(false);
  const [isLoader, setIsLoader] = useState(false);

  const navigateHome = useCallback(() => {
    localStorage.removeItem("appointmentData");
    setIsAuth(false);
    navigate("/");
  }, [navigate]);

  const authenticateAppointment = useCallback(async () => {
    setIsLoader(true);
    try {
      const res = await apiRequest(
        "post",
        "appointment/authenticate-appointment",
        {
          body: { appointmentId: appointmentData?.appointmentId || id },
        }
      );

      if (
        [401, 403, 404].includes(res?.status) ||
        res?.data?.data?.appointmentStatus === "close"
      ) {
        toast.error("Appointment closed or not found.", { autoClose: 4000 });
        navigateHome();
      } else {
        setIsAuth(true);
      }
    } catch (error) {
      console.error("Error verifying token:", error);
      toast.error(error?.data?.message, { autoClose: 4000 });
      navigateHome();
    } finally {
      setIsLoader(false);
    }
  }, [appointmentData, id, navigateHome]);

  const fetchProfileDetails = useCallback(async () => {
    try {
      const response = await getProfileDetails(token);
      const profileData = response?.data?.docs;

      if (response?.data?.status === 200) {
        localStorage.setItem("userDetails", JSON.stringify(profileData));
        setProfile(profileData);

        const appointment = {
          appointmentId: profileData?.appointmentData?._id,
          appointmentStartTime:
            profileData?.appointmentData?.consultantJoinTime,
          waitingArray: profileData?.appointmentData?.waitingArray,
          callerData: {
            consultant: {
              id: profileData?.appointmentData?.consultantId,
              image: profileData?.appointmentData?.consultantDetails?.image,
              name: `${profileData?.appointmentData?.consultantDetails?.firstName} ${profileData?.appointmentData?.consultantDetails?.lastName}`,
            },
            user: {
              id: profileData?.appointmentData?.clientId,
              image: profileData?.appointmentData?.clientDetails?.image,
              name: `${profileData?.appointmentData?.clientDetails?.firstName} ${profileData?.appointmentData?.clientDetails?.lastName}`,
            },
          },
          mode: profileData?.appointmentData?.service?.mode?.slug,
          paymentMode: profileData?.appointmentData?.paymentMode,
          sessionId: profileData?.appointmentData?.sessionId,
          token: profileData?.appointmentData?.sessionToken,
        };

        localStorage.setItem("appointmentData", JSON.stringify(appointment));
        setAppointmentData(appointment);
        if (
          profileData?.appointmentData?.service?.service?.slug ===
          "direct-access-online-psychological-counseling"
        ) {
          !profileData?.appointmentData?.consultantJoinTime && navigateHome();
        } else {
          socket?.emit("indirectAppointmentConfirmJoin", {
            userId: profile?._id,
            appointmentId: appointment?.appointmentId,
            joined: true,
            mode: appointment?.mode,
            data: {
              clientId: profileData?.appointmentData?.clientId,
              consultantId: profileData?.appointmentData?.consultantId,
            },
          });
        }
      } else {
        toast.error("Invalid profile details.", { autoClose: 4000 });
        navigateHome();
      }
    } catch (error) {
      console.error("Error fetching profile details:", error);
      toast.error("Error fetching profile details.", { autoClose: 4000 });
      navigateHome();
    }
  }, []);

  const handleSocketDisconnect = useCallback(() => {
    if (socket && appointmentData) {
      socket.emit("userAlreadyInCall", {
        userId: profile?._id,
        appointmentId: appointmentData?.appointmentId,
        disconnect: true,
        roleCode: profile?.roleCode,
      });
    }
  }, [appointmentData, profile, socket]);

  useEffect(() => {
    if (token) fetchProfileDetails();
  }, [token, fetchProfileDetails]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (appointmentData) authenticateAppointment();
    }, 1000);

    return () => clearTimeout(timer);
  }, [appointmentData, authenticateAppointment]);

  useEffect(() => {
    const handleUnload = () => {
      if (appointmentData?.mode === "chat") handleSocketDisconnect();
    };

    window.addEventListener("beforeunload", handleUnload);
    document.addEventListener("visibilitychange", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleUnload);
      document.removeEventListener("visibilitychange", handleUnload);
    };
  }, [appointmentData, handleSocketDisconnect]);

  return isAuth ? (
    <Outlet />
  ) : (
    <div className="flex items-center justify-center h-screen text-2xl font-extrabold">
      Loading...
    </div>
  );
};

export default BlankLayout;
