import React, { useEffect, useState } from "react";
import { register } from "swiper/element/bundle";
import ConsultantItem from "../single/ConsultantItem";
import Button from "../form/Button";
import "swiper/css/navigation";
import {
  fetchConsultantListPrivately,
  fetchConsultantStatusList,
} from "../../service/consultantService";
import NoDataFound from "../elements/NoDataFound";
import SpinLoader from "../elements/table/SpinLoader";
import { useSelector } from "react-redux";
import { consultantDetails } from "../elements/language";
import { useSocket } from "../socket/SocketProvider";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
register();
const languageConfig = {
  viewAll: {
    en: "View All",
    es: "Ver todo",
    it: "Visualizza tutto",
  },
};

const Consultants = ({ params, loaded = true, setLoaded = () => {} }) => {
  const [data, setData] = useState([]);
  const lang = useSelector((state) => state?.languageSlice?.data);
  const language = localStorage.getItem("language") || lang;
  const token = localStorage.getItem("auth_token") || Cookies.get("auth_token");
  const [liveConsultants, setLiveConsultants] = useState([]);
  const [consultantData, setConsultantData] = useState(null);
  const socket = useSocket();
  const [busyData, setbusyData] = useState([]);
  const navigate = useNavigate();

  const busyConsultants = useSelector(
    (state) => state?.consultantStatusSlice?.data
  );
  const [consultantStatusList, setConsultantStatusList] = useState([]);

  useEffect(() => {
    setbusyData(busyConsultants);
  }, [busyConsultants]);

  useEffect(() => {
    fetchConsultantListPrivately(token, { ...params }, setData, setLoaded);
  }, []);

  const titleLable = {
    en: "Available<br/><span class='text-prontopsy-pink'>Consultants</span>",
    es: "Consultores<br/><span class='text-prontopsy-pink'>Disponibles</span>",
    it: "Consulenti<br/><span class='text-prontopsy-pink'>Disponibili</span>",
  };

  const [initialData, setInitialData] = useState([]);

  const fetchStatusList = () => {
    fetchConsultantStatusList().then((res) => {
      setConsultantStatusList(res?.data?.data);
    });
  };

  useEffect(() => {
    fetchStatusList();
  }, []);

  useSocket("userStatusResponse", (newObjects) => {
    if (newObjects && newObjects?.userId) {
      fetchStatusList();
    }
  });

  useSocket("consultantBusyResponse", (data) => {
    if (data && data.consultantId) {
      fetchStatusList();
    }
  });

  useEffect(() => {
    setLiveConsultants((prevObjects) => {
      const objectsMap = new Map(prevObjects.map((obj) => [obj.userId, obj]));

      initialData.forEach((newObj) => {
        objectsMap.set(newObj?.userId, newObj);
      });

      return Array.from(objectsMap.values());
    });
  }, [initialData, socket]);

  useEffect(() => {
    if (
      consultantStatusList &&
      Array.isArray(consultantStatusList) &&
      consultantStatusList.length > 0
    ) {
      const formattedData = consultantStatusList.map((elm) => ({
        userId: elm._id,
        online: elm.onlineStatus === "online" ? true : false,
      }));
      const formattedBusyData = consultantStatusList.map((elm) => ({
        consultantId: elm?._id,
        isBusy: elm?.onlineStatus === "busy" ? true : false,
      }));
      setbusyData(formattedBusyData);
      formattedData && setInitialData(formattedData);
    }
  }, [consultantStatusList]);

  useEffect(() => {
    let info =
      data?.length > 0 &&
      data.map((elm) => {
        const busyStatus =
          consultantStatusList &&
          Array.isArray(consultantStatusList) &&
          consultantStatusList.find((data) => elm._id === data?._id);

        return {
          _id: elm?._id,
          name: elm?.firstName
            ? elm?.firstName + " " + elm?.lastName
            : "Consultant",
          image: elm?.image?.url,
          specialization:
            elm?.specialization?.inventionArea?.title?.[language] || "N/A",
          intervention:
            elm?.member?.personalQualification?.title?.[language] || "N/A",
          bio: elm?.biography?.longBioDescription,
          language: {
            _id: elm?.languagesData?._id,
            name: elm?.languagesData?.name,
            code: elm?.languagesData?.code,
          },
          timeZone: elm?.personal?.timeZone?.zoneName,
          nationality: {
            name: elm?.personal?.nationality?.nationality || "Unknown",
            code: elm?.personal?.nationality?.code?.toLowerCase() || "unknown",
          },
          rate: {
            currency: elm?.currencyData?.currency,
            value:
              elm?.service &&
              Array.isArray(elm?.service) &&
              elm.service.length > 0 &&
              elm?.service[0]?.price,
          },
          isOnline: busyStatus?.onlineStatus,
          isFavorite: elm?.isFavourite || false,
          rating: {
            value: elm?.ratingData?.rating || 0,
            reviews: elm?.ratingData?.reviewCount || 0,
          },
          actions: {
            call: {
              isLink: false,
              click: () => {},
              label: consultantDetails?.callNow?.[language] || "Call Now",
            },
            booking: {
              isLink: false,
              click: () => {},
              label: consultantDetails?.bookConsult?.[language] || "Book Now",
            },
            email: {
              isLink: true,
              link: `/email-details/${elm?.chatDetail?._id}` || "#",
              label: consultantDetails?.emailMe?.[language] || "Email Me",
            },
          },
          serviceDataTableMap: elm?.serviceDataTableMap || [],
        };
      });

    const sortedInfo =
      Array.isArray(info) &&
      [...info].sort((a, b) => {
        if (a.isOnline === "online" && b.isOnline !== "online") return -1;
        if (a.isOnline !== "online" && b.isOnline === "online") return 1;
        return 0;
      });

    const consultantDataa = info && {
      title: titleLable?.[language],
      consultant: sortedInfo,
    };
    setConsultantData(consultantDataa);
  }, [language, data, busyData, consultantStatusList]);

  return (
    <>
      {loaded ? (
        <section className="relative py-10 md:py-12 lg:py-16 xl:py-20 2xl:py-24 flex items-center bg-neutral-100">
          {data && data.length > 0 && consultantData?.consultant?.length > 0 ? (
            <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto">
              <div className="w-full text-center">
                {consultantData?.title && (
                  <h2
                    className="text-2xl lg:text-3xl xl:text-4xl 2xl:text-[45px] text-prontopsy-blue font-UnisonPro italic font-bold !leading-[1.1]"
                    dangerouslySetInnerHTML={{ __html: consultantData?.title }}
                  ></h2>
                )}
              </div>

              <>
                <div className="grid md:grid-cols-2 grid-cols-1 2xl:gap-10 xl:gap-8 gap-6 mt-20">
                  <div>
                    <ConsultantItem
                      data={consultantData?.consultant[0]}
                      mainClasses={"!p-0 !h-full"}
                      divClasses={"!py-5 !gap-6 !rounded-xl"}
                      rateClass={"!mt-5"}
                      subheadingClass={"!pt-0"}
                      isShowDescription={false}
                      callUsBtn={true}
                    />
                  </div>
                  <div>
                    <ConsultantItem
                      data={consultantData?.consultant[1]}
                      mainClasses={"!p-0 !h-full"}
                      divClasses={"!py-5 !gap-6 !rounded-xl"}
                      rateClass={"!mt-5"}
                      subheadingClass={"!pt-0"}
                      isShowDescription={false}
                      callUsBtn={true}
                    />
                  </div>
                  <div>
                    <ConsultantItem
                      data={consultantData?.consultant[2]}
                      mainClasses={"!p-0 !h-full"}
                      divClasses={"!py-5 !gap-6 !rounded-xl"}
                      rateClass={"!mt-5"}
                      subheadingClass={"!pt-0"}
                      isShowDescription={false}
                      callUsBtn={true}
                    />
                  </div>
                  <div>
                    <ConsultantItem
                      data={consultantData?.consultant[3]}
                      mainClasses={"!p-0 !h-full"}
                      divClasses={"!py-5 !gap-6 !rounded-xl"}
                      rateClass={"!mt-5"}
                      subheadingClass={"!pt-0"}
                      isShowDescription={false}
                      callUsBtn={true}
                    />
                  </div>
                </div>

                <div className="flex justify-center items-center mt-10">
                  {" "}
                  <Button
                    buttonClasses={
                      "!bg-prontopsy-blue !px-8 !py-2 w-[150px] mt-[30px]"
                    }
                    buttonLabel={languageConfig?.viewAll?.[language]}
                    buttonLabelClasses={"!text-base"}
                    buttonFunction={() =>
                      token
                        ? navigate("/find-psychologist")
                        : navigate("/signin")
                    }
                  />
                </div>
              </>
            </div>
          ) : (
            <>
              <div className="m-auto">
                <NoDataFound />
              </div>
            </>
          )}
        </section>
      ) : (
        <SpinLoader />
      )}
    </>
  );
};

export default Consultants;
