import React, { useEffect, useState } from "react";
import Image from "../../elements/Image";
import { useSelector } from "react-redux";
const AboutSection1 = () => {
  const [about, setAbout] = useState({});
  const lang = useSelector((state) => state?.languageSlice?.data);
  const language = localStorage.getItem("language") || lang;
  const data = useSelector((state) => state?.pageSlice?.data);

  useEffect(() => {
    let info =
      data.length > 0 && data.filter((elm) => elm?.title?.en == "About");
    setAbout(info[0]);
  }, [data]);

  const aboutData = {
    image: about && about?.image?.url,
    title: about && about?.subTitle?.[language],
    description: about && about?.description?.[language],
  };
  return (
    <section className="relative md:py-20 py-12">
      <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto">
        <div className="block xl:grid grid-cols-12 space-y-10 3xl:gap-5 gap-8">
          <div className="relative xl:col-span-6">
            {aboutData?.image && (
              <div className="w-full relative max-w-xl aspect-[5/3]">
                <Image
                  src={aboutData?.image}
                  alt={""}
                  effect={"blur"}
                  className={"object-cover w-full h-full"}
                />
              </div>
            )}
          </div>
          <div className="relative xl:col-span-6 flex flex-col justify-center max-w-lg w-full">
            {aboutData?.title && (
              <h2
                className="text-lg md:text-xl xl:text-2xl text-prontopsy-blue font-UnisonPro italic font-bold !leading-[1.2]"
                dangerouslySetInnerHTML={{ __html: aboutData?.title }}
              ></h2>
            )}
            {aboutData?.description && (
              <div
                className="text-xs md:text-sm xl:text-base text-neutral-700 space-y-4 mt-2 md:mt-4 lg:mt-6"
                dangerouslySetInnerHTML={{ __html: aboutData?.description }}
              ></div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection1;
