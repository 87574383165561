import React, { useCallback, useEffect, useRef } from "react";
import Modal from "./Modal";
import { useDispatch, useSelector } from "react-redux";
import { setToken } from "../../store/slice/vonageSlice";
import { useNavigate } from "react-router-dom";
import { setCallerData } from "../../store/slice/callingSlice";
import { useSocket } from "../socket/SocketProvider";
import { appointmentCancel } from "../../service/videoCallService";
import { toast } from "react-toastify";
import music from "../../assets/music/ringtone.mp3";
import useSound from "use-sound";

const headingLang = {
  en: "is want to start AUDIO conversation with you.",
  es: "te está llamando.",
  it: "ti sta chiamando.",
};
const subheadingLang = {
  en: "Informed Consent has been accepted and Payment has been authorized.",
  es: "Se ha aceptado el Consentimiento Informado y se ha autorizado el Pago.",
  it: "Il Consenso Informato è stato accettato e il Pagamento è stato autorizzato.",
};
const joinLang = {
  en: "Join Now",
  es: "Únete Ahora",
  it: "Iscriviti Ora",
};
const cancelLang = {
  en: "Cancel",
  es: "Cancelar",
  it: "Cancellare",
};

export const JoinCallModal = ({
  isOpen,
  setIsOpen,
  data,
  sessionId,
  sessionToken,
  callerData,
  audioRef,
}) => {
  const lang = useSelector((state) => state?.languageSlice?.data);
  const language = localStorage.getItem("language") || lang;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const socket = useSocket();
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const appointment =
    JSON.parse(localStorage.getItem("appointmentData")) || callerData;
  // const [play, { stop }] = useSound(music);

  const callData = {
    user: {
      name: userDetails?.firstName + " " + userDetails?.lastName,
      email: userDetails?.email,
      image: userDetails?.image?.url,
      id: userDetails?._id,
    },
    consultant: {
      name: data?.name,
      image: data?.image?.url || data?.image,
      id: data?._id || data?.id,
    },
  };

  const handleJoinNow = () => {
    dispatch(setCallerData(callData));
    localStorage.setItem(
      "appointmentData",
      JSON.stringify({
        ...appointment,
        appointmentStartTime: Date.now(),
      })
    );
    dispatch(
      setToken({
        loading: true,
        sessionId: sessionId,
        token: sessionToken,
      })
    );
    // if (audioRef) {
    //   audioRef.current.pause();
    //   audioRef.current.currentTime = 0;
    // }
    // stop();
    socket &&
      socket.emit("consultantBusy", {
        consultantId: data?._id,
        userId:
          appointment?.callerData?.user?.id ||
          callerData?.callerData?.user?._id,
        isBusy: true,
      });
    socket &&
      socket.emit("consultantJoin", {
        joined: true,
        mode: appointment?.mode || callerData?.mode,
        data: appointment?.callerData || callerData?.callerData,
        appointmentId: appointment?.appointmentId || callerData?.appointmentId,
        userId: userDetails?._id,
      });
    const appointmentData = {
      ...appointment,
      mode: callerData?.mode,
      appointmentId: callerData?.appointmentId,
      consultantId: callerData?.consultantId,
      appointmentStartTime: Date.now(),
      // appointmentStartTime: callerData?.appointmentStartTime,
      sessionId: sessionId,
      token: sessionToken,
    };
    if (!appointment || appointment?.appointmentId == null) {
      localStorage.setItem("appointmentData", JSON.stringify(appointmentData));
    }
    localStorage.setItem("joined", true);

    const appointmentId =
      appointment?.appointmentId || callerData?.appointmentId;

    if (appointmentId) {
      navigate(`/chats/${appointmentId}`);
    }

    setIsOpen(false);
  };

  useSocket("consultantJoinResponse", async (data) => {
    if (
      data?.joined === true &&
      userDetails &&
      data?.data?.consultant?.id === userDetails?._id
    ) {
      setIsOpen(false);
      // appointment &&
      //   appointment?.appointmentId &&
      //   sessionToken &&
      navigate(
        `/chats/${appointment?.appointmentId || callerData?.appointmentId}`
      );
      // handleJoinNow();
      // localStorage.setItem("joined", true);
    }
  });

  const handleJoinLater = useCallback(() => {
    setIsOpen(false);
    // stop();
    appointmentCancel({ appointmentId: appointment?.appointmentId });

    if (socket) {
      socket.emit("consultantBusy", {
        consultantId: appointment?.callerData?.consultant?.id || "",
        userId: appointment?.callerData?.user?.id,
        isBusy: false,
      });

      socket.emit("vonageSessionReject", {
        disconnect: true,
        appointmentId: appointment?.callerData?.appointmentId,
        role: userDetails?.roleCode,
        consultantId: appointment?.callerData?.consultant?.id || "",
        userId: appointment?.callerData?.user?.id,
      });
    }

    setTimeout(() => {
      localStorage.removeItem("appointmentData");
    }, 1000);
  }, [appointment, socket, userDetails, appointmentCancel, setIsOpen]);

  // const handleJoinLater = () => {
  //   setIsOpen(false);
  //   appointmentCancel({ appointmentId: appointment?.appointmentId });
  //   socket &&
  //     socket.emit("consultantBusy", {
  //       consultantId: appointment?.callerData?.consultant?.id || "",
  //       userId: appointment?.callerData?.user?.id,
  //       isBusy: false,
  //     });
  //   socket &&
  //     socket.emit("vonageSessionReject", {
  //       disconnect: true,
  //       appointmentId: appointment?.callerData?.appointmentId,
  //       role: userDetails?.roleCode,
  //       consultantId: appointment?.callerData?.consultant?.id || "",
  //       userId: appointment?.callerData?.user?.id,
  //     });
  //   setTimeout(() => {
  //     localStorage.removeItem("appointmentData");
  //   }, 1000);
  // };

  useEffect(() => {
    let timer;
    if (isOpen) {
      timer = setTimeout(() => {
        socket.emit("autoDisconnectCall", {
          appointmentId: appointment?.appointmentId,
          callerData: appointment?.callerData,
        });
        handleJoinLater();
      }, 1000 * 60); // 1 minute
    }
    return () => clearTimeout(timer);
  }, [isOpen]);

  // useSocket(
  //   "vonageSessionRejectResponse",
  //   async (data) => {
  //     if (!data?.disconnect) return;
  //     const isCurrentUserInvolved =
  //       userDetails?._id === data?.userId ||
  //       userDetails?._id === data?.consultantId;
  //     if (!isCurrentUserInvolved) return;

  //     setIsOpen(false);
  //     userDetails?.roleCode !== "user" &&
  //       data?.role === "user" &&
  //       toast.info(
  //         "Client has canceled the call. Stay online as they may try to reach out again.",
  //         { autoClose: 5000 }
  //       );
  //     localStorage.removeItem("appointmentData");
  //     localStorage.removeItem("joined");
  //   },
  //   []
  // );

  //---------------------------------------------------------------------
  const toastDisplayedRef = useRef(false);
  useSocket(
    "vonageSessionRejectResponse",
    async (data) => {
      if (!data?.disconnect) return;

      const isCurrentUserInvolved =
        userDetails?._id === data?.userId ||
        userDetails?._id === data?.consultantId;

      if (!isCurrentUserInvolved) return;

      setIsOpen(false);

      // Display the toast only if it hasn't been shown yet
      if (!toastDisplayedRef.current) {
        toastDisplayedRef.current = true;
        if (userDetails?.roleCode !== "user" && data?.role === "user") {
          toast.info(
            "Client has canceled the call. Stay online as they may try to reach out again.",
            { autoClose: 5000 }
          );
        }
      }

      localStorage.removeItem("appointmentData");
      localStorage.removeItem("joined");
    },
    []
  );
  // Reset the ref when the modal opens again
  useEffect(() => {
    if (isOpen) {
      toastDisplayedRef.current = false; // Reset when modal opens
    }
  }, [isOpen]);

  //-------------------------------------------------

  const headingLang = {
    en: `want to start a <span class="text-prontopsy-blue text-xl font-bold">${callerData?.mode}</span> consult with you.`,
    es: `Quiero iniciar una consultar de <span class="text-prontopsy-blue text-xl font-bold">${callerData?.mode}</span> contigo.`,
    it: `voglio iniziare una consultare <span class="text-prontopsy-blue text-xl font-bold">${callerData?.mode}</span> con te.`,
  };

  return (
    <Modal isOpen={isOpen} titleShow={false} size={"xl2"}>
      <div className="flex flex-col items-center justify-center md:p-8 p-2 bg-white  rounded-lg">
        <img
          crossOrigin="anonymous"
          src={data?.image?.url || data?.image}
          alt={`${data?.name}`}
          effect={"blur"}
          className="!w-32 !h-32 object-cover rounded-full border-4 border-gray-200 mb-6 flex items-center justify-center"
        />
        <div className="text-lg font-medium text-gray-500 mb-6 capitalize text-center pb-4">
          <p className="text-prontopsy-pink text-2xl font-bold pb-0 uppercase">
            {" "}
            {`${data?.firstName || data?.name} ${data?.lastName || ""}`}
          </p>
          <div dangerouslySetInnerHTML={{ __html: headingLang?.[language] }} />
        </div>
        <span className="mt-[-25px] text-center text-prontopsy-dodgerBlue">
          {subheadingLang?.[language]}
        </span>
        <div className="flex md:space-x-6 space-x-4 mt-4 text-xl">
          <button
            onClick={handleJoinNow}
            className="bg-green-500  flex gap-4 items-center text-white md:px-8 px-4 py-4 rounded-lg shadow-md hover:bg-green-600 transition duration-300 ease-in-out md:text-lg text-base"
          >
            <i className="fa-solid fa-phone"></i>
            {joinLang?.[language]}
          </button>
          <button
            onClick={() => {
              // stop();
              handleJoinLater();
            }}
            className="bg-red-500 flex md:gap-4 gap-2 items-center text-white md:px-8 px-4 py-4 rounded-lg shadow-md hover:bg-red-600 transition duration-300 ease-in-out md:text-lg text-base"
          >
            <i className="fa-solid fa-xmark"></i>
            {cancelLang?.[language]}
          </button>
        </div>
      </div>
    </Modal>
  );
};
