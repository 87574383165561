import { apiRequest } from "../util/async/apiUtils";

export const fetchEmailList = async (params) => {
  try {
    const res = await apiRequest(
      `${"get"}`,
      `${`/communicate/list-chat?chatType[0]=personal`}`,
      {
        body: {
          ...params,
        },
      }
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const emailChatInitialization = async (params) => {
  try {
    const res = await apiRequest(`${"post"}`, `${"/communicate/add"}`, {
      body: {
        ...params,
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const sendMessage = async (params) => {
  try {
    const res = await apiRequest(
      `${"post"}`,
      `${"/communicate/send-message"}`,
      {
        body: {
          ...params,
        },
      },
      "multipart/form-data"
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const fetchEmailDetailsList = async (id, params) => {
  try {
    const res = await apiRequest(
      `${"get"}`,
      `${`/communicate/chat-load-message`}`,
      {
        queries: {
          chatId: id,
          ...params,
        },
      },
      "multipart/form-data"
    );
    return res;
  } catch (err) {
    return err;
  }
};
