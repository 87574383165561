import React, { useCallback, useEffect, useRef, useState } from "react";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import Image from "../../src/components/elements/Image";
import SpinLoader from "../components/elements/table/SpinLoader";
import Button from "../components/form/Button";
import LeaveChatModal from "../components/elements/LeavChatModal";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSocket } from "../components/socket/SocketProvider";
import { fetchConsultantDetails } from "../service/consultantService";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { appointmentClose, mediaChat } from "../service/videoCallService";
import { toast } from "react-toastify";
import { fetchTextMessageList } from "../service/textChatService";
import Picker from "emoji-picker-react";
import usePageVisibility from "../util/PageVisibility";
import useBatteryStatus from "../components/elements/hooks/useBatteryStatus";
import useOnlineStatus from "../util/OnlineStatus";
import { getProfileDetails } from "../service/authService";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";
import { fetchAppointmentList } from "../service/appointmentService";
import TimeCountdown from "../helpers/TimeCountdown";
dayjs.extend(duration);

const langConfig = {
  waitingWord: {
    en: " Please wait until the other joins...",
    es: "Espere hasta que el otro se una...",
    it: "Per favore aspetta finché l'altro non si unisce...",
  },
};

const TextChat = () => {
  useEffect(() => {
    Fancybox.bind("[data-fancybox]", {});
  }, []);
  const [loaded, setLoaded] = useState(true);
  const user = JSON.parse(localStorage.getItem("userDetails"));
  const targetRef = useRef(null);
  const [message, setMessage] = useState("");
  const [isLeaveModalOpen, setIsLeaveModalOpen] = useState(false);
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const appointmentDataLocal = JSON.parse(
    localStorage.getItem("appointmentData")
  );
  const lang = useSelector((state) => state?.languageSlice?.data);
  const language = localStorage.getItem("language") || lang;
  const navigate = useNavigate();
  const socket = useSocket();
  const token = localStorage.getItem("auth_token") || Cookies.get("auth_token");
  const [consultantDetails, setConsultantDetails] = useState(null);
  const [list, setList] = useState([]);
  const callerData = JSON.parse(
    localStorage.getItem("appointmentData")
  )?.callerData;
  const [stop, setStop] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [sending, setSending] = useState(false);
  const [elapsedTime, setElapsedTime] = useState("00:00:00");
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [isOneHourPassed, setIsOneHourPassed] = useState(false);
  const [showPicker, setShowPicker] = useState(false);
  const pickerRef = useRef(null);
  const roleCode = JSON.parse(localStorage.getItem("userDetails"))?.roleCode;
  const onlineStatus = useOnlineStatus();
  const [flag, setFlag] = useState(false);
  const [appointmentData, setAppointmentData] = useState(appointmentDataLocal);

  //___________User already in call ________________
  const emitUserInCall = useCallback(() => {
    if (socket && appointmentData) {
      socket.emit("userAlreadyInCall", {
        userId: userDetails?._id,
        appointmentId: appointmentData?.appointmentId,
        disconnect: false,
        roleCode: roleCode,
      });
    }
  }, [socket, appointmentData, userDetails?._id, roleCode]);

  // Usage
  useEffect(() => {
    emitUserInCall(); // Call the function
  }, [emitUserInCall]);
  //--------------------accidental call disconnect------------------
  const [disconnectedProfile, setDisconnectedProfile] = useState(null);
  useEffect(() => {
    if (appointmentData && socket) {
      socket.emit("userDisconnectFromCallNotify", {
        disconnected: false,
        sessionId: appointmentData?.sessionId,
        appointmentId: appointmentData?.appointmentId,
        userId:
          roleCode === "user"
            ? appointmentData?.clientId
            : appointmentData?.consultantId,
      });
    }
  }, [socket]);

  // useSocket("userDisconnectFromCallNotifyResponse", (data) => {
  //   // console.log(data, "ddddd");
  //   setDisconnectedProfile(data);
  //   if (
  //     data?.disconnected === true &&
  //     data?.appointmentId === appointmentData?.appointmentId
  //   ) {
  //     if (data?.userId === appointmentData?.clientId) {
  //       toast.error(
  //         "User left the consult (Please wait, he/she will rejoin).",
  //         {
  //           autoClose: 3000,
  //         }
  //       );
  //     } else if (data?.userId === appointmentData?.consultantId) {
  //       toast.error(
  //         "Consultant left the consult (Please wait, he/she will rejoin).",
  //         {
  //           autoClose: 3000,
  //         }
  //       );
  //     }
  //   }
  // });

  //---------------------------------------------------------------

  useEffect(() => {
    setLoaded(false);
    fetchTextMessageList(id, {}).then((res) => {
      setList(res?.data?.docs?.data);
      setLoaded(true);
    });
  }, []);

  // useEffect(() => {
  //   if (id && appointmentData && appointmentData.appointmentId) {
  //     appointmentVerification({ appointmentId: id }).then((res) => {
  //       if (res?.data?.data?.status === false) {
  //         toast.error("Appointment not found!");
  //         navigate("/");
  //       }
  //     });
  //   } else {
  //     navigate("/");
  //   }
  // }, [id]);

  //Initial profile data fetch
  const handleProfileDetails = useCallback(() => {
    if (!token) return;

    getProfileDetails(token).then((response) => {
      if (response?.data?.status !== 200) return;

      // const userDetails = response?.data?.docs;
      if (response?.data?.docs?._id) {
        localStorage.setItem("userDetails", JSON.stringify(userDetails));
      }

      const appointmentDataa = response?.data?.docs?.appointmentData;
      if (appointmentDataa?._id && appointmentDataa?.status === "inProgress") {
        const appointment = {
          appointmentId: appointmentDataa._id,
          appointmentStartTime: appointmentDataa.consultantJoinTime,
          callerData: {
            consultant: {
              id: appointmentDataa.consultantId,
              image: appointmentDataa.consultantDetails?.image,
              name: `${appointmentDataa.consultantDetails?.firstName} ${appointmentDataa.consultantDetails?.lastName}`,
            },
            user: {
              id: appointmentDataa.clientId,
              image: appointmentDataa.clientDetails?.image,
              name: `${appointmentDataa.clientDetails?.firstName} ${appointmentDataa.clientDetails?.lastName}`,
            },
          },
          consultantId: appointmentDataa.consultantId,
          clientId: appointmentDataa.clientId,
          mode: appointmentDataa.service?.mode?.slug,
          paymentMode: appointmentDataa.paymentMode,
          sessionId: appointmentDataa.sessionId,
          token: appointmentDataa.sessionToken,
        };

        setAppointmentData(appointment);
        localStorage.setItem("appointmentData", JSON.stringify(appointment));
      } else {
        socket.emit("consultantBusy", {
          consultantId: appointmentData?.consultantId || "",
          userId: appointmentData?.clientId,
          isBusy: false,
        });
        localStorage.removeItem("appointmentData");
      }
    });
  }, [token, socket, appointmentData]);

  useEffect(() => {
    handleProfileDetails();
  }, []);

  useSocket("indirectAppointmentConfirmJoinResponse", (dataa) => {
    const local = JSON.parse(localStorage.getItem("appointmentData"));
    if (
      dataa?.joined &&
      dataa?.appointmentId === local?.appointmentId &&
      !local?.appointmentStartTime
    ) {
      socket &&
        socket.emit("consultantBusy", {
          consultantId: dataa?.data?.consultantId,
          userId: dataa?.data?.clientId,
          isBusy: true,
        });
      window.location.reload();
    }
  });

  const calculateElapsedTime = () => {
    const currentTime = dayjs();
    const time =
      appointmentData && appointmentData.appointmentId
        ? appointmentData?.appointmentStartTime
        : currentTime;
    const startTime = dayjs(time);

    const duration = dayjs.duration(currentTime.diff(startTime));

    const hours = String(duration.hours()).padStart(2, "0");
    const minutes = String(duration.minutes()).padStart(2, "0");
    const seconds = String(duration.seconds()).padStart(2, "0");

    // Check if elapsed time is one hour or more
    if (duration.asHours() >= 1 && !isOneHourPassed) {
      setIsOneHourPassed(true);
    }

    return `${hours} : ${minutes} : ${seconds}`;
  };

  useEffect(() => {
    setElapsedTime(calculateElapsedTime());

    const interval = setInterval(() => {
      setElapsedTime(calculateElapsedTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [appointmentData]);

  const handleSendMessage = () => {
    if (
      (!message && attachments?.length == 0) ||
      (message && message.trim().length === 0)
    ) {
      setMessage("");
      return toast.error("Message cannot be empty!");
    }

    setSending(true);

    const mediaObject = {};
    attachments.forEach((attachment, index) => {
      mediaObject[`media[${index}]`] = attachment;
    });

    const data = {
      text: message,
      createdAt: new Date(),
      fromUser: {
        _id: userDetails?._id,
        firstName: userDetails?.firstName,
        lastName: userDetails?.lastName,
        image: userDetails?.image,
        email: userDetails?.email,
        roleCode: userDetails?.roleCode,
      },
      toUser: {
        _id: consultantDetails?._id,
        firstName: consultantDetails?.firstName,
        lastName: consultantDetails?.lastName,
        image: consultantDetails?.image,
        email: consultantDetails?.email,
        roleCode: consultantDetails?.roleCode,
      },
      appointmentId: appointmentData?.appointmentId,
      attachment: attachments.map((attachment) => ({
        url: null,
        loading: true,
        extension: attachment.extension,
      })),
      status: "sending",
    };

    if (attachments?.length > 0) {
      mediaChat({
        ...mediaObject,
        appointmentId: appointmentData?.appointmentId,
        from: userDetails?._id,
        to: consultantDetails?._id,
        text: message,
      }).then((res) => {
        if (res?.status === 200 || res?.status === 201) {
          setSending(false);
          const updatedData = {
            ...data,
            attachment: res?.data?.data[0]?.attachment.map((att) => ({
              ...att,
              loading: false,
            })),
            status: "sent",
          };
          socket.emit("chatSessionMessage", updatedData);

          setMessage("");
          setAttachments([]);
        } else if (res?.status === 415) {
          toast.error("Unsupported file type!");

          setSending(false);
        } else {
          toast.error(res?.message);
          setSending(false);
        }
      });
    } else {
      data.status = "sent";
      socket.emit("chatSessionMessage", data);
      setMessage("");
      setSending(false);
    }

    setMessage("");
    setAttachments([]);
  };

  useSocket("chatSessionMessageResponse", async (data) => {
    appointmentData?.appointmentId === data?.appointmentId &&
      list &&
      Array.isArray(list) &&
      setList((pre) => [...pre, data]);
  });

  useEffect(() => {
    callerData &&
      Object.keys(callerData).length > 0 &&
      userDetails &&
      fetchConsultantDetails(
        token,
        {
          id:
            userDetails?.roleCode === "user"
              ? callerData?.consultant?.id
              : callerData?.user?.id,
        },
        setConsultantDetails
      );
  }, []);

  // Scroll to the bottom when 'list' changes
  useEffect(() => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, [list]);

  const onConfirm = () => {
    setLoading(true);
    setFlag(false);
    appointmentData &&
      appointmentData?.appointmentId &&
      appointmentClose({ appointmentId: appointmentData?.appointmentId }).then(
        (res) => {
          if (res?.data?.status == 200 || res?.data?.status == 201) {
            setIsOneHourPassed(false);
            setLoading(false);
            setIsLeaveModalOpen(false);
            socket &&
              socket.emit("chatDisconnect", {
                disconnect: true,
                appointmentId: appointmentData?.appointmentId,
              });
            socket &&
              socket.emit("consultantBusy", {
                consultantId: appointmentData?.consultantId || "",
                userId: appointmentData?.clientId,
                isBusy: false,
              });

            if (userDetails?.roleCode === "user") {
              socket &&
                socket.emit("consultantBusy", {
                  consultantId: appointmentData?.consultantId || "",
                  userId: appointmentData?.clientId,
                  isBusy: false,
                });
              localStorage.removeItem("joined");
              navigate(`/review-appointment/${appointmentData?.appointmentId}`);
            } else {
              socket &&
                socket.emit("consultantBusy", {
                  consultantId: appointmentData?.consultantId || "",
                  userId: appointmentData?.clientId,
                  isBusy: false,
                });

              navigate(
                `/appointments-details/${appointmentData?.appointmentId}`
              );
              localStorage.removeItem("appointmentData");
              localStorage.removeItem("joined");
            }
          } else {
            setLoading(false);
            toast.error(res?.data?.message);
            localStorage.removeItem("appointmentData");
            localStorage.removeItem("joined");
            navigate("/");
          }
        }
      );
  };

  //------------Appointment close after browser closing--------------

  const intervalRef = useRef(null);
  useEffect(() => {
    // Set up the interval to call the callback every 10 minutes
    intervalRef.current = setInterval(() => {
      socket.emit("appointmentPing", {
        appointmentId: appointmentData?.appointmentId,
        clientId: appointmentData?.clientId,
        consultantId: appointmentData?.consultantId,
        userId: user?._id,
      });
    }, 60 * 1000);

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalRef.current);
  }, [socket]); // Ensure callback is up-to-date

  useSocket("appointmentPingResponse", (data) => {
    if (data?.appointmentId === appointmentData?.appointmentId) {
      data?.userId !== user?._id ? setFlag(true) : setFlag(false);
    }
  });

  useEffect(() => {
    if (flag === false) {
      const timer = setTimeout(() => {
        onConfirm();
      }, 2 * 60 * 1000);
      return () => clearTimeout(timer);
    }
  }, [flag]);

  //-----------------------------------------------------------------

  // if (!onlineStatus) {
  //   toast.error("You are offline!", { autoClose: 5000 });
  //   navigate("/signin");
  // }
  //one houre --------------------

  useEffect(() => {
    if (isOneHourPassed === true) {
      toast.info("You have reached your maximum calling duration!");
      onConfirm();
    }
  }, [isOneHourPassed === true]);

  //------------Battery Percentage-----------------------

  const { level, charging } = useBatteryStatus();

  useEffect(() => {
    if (charging === false && Math.round(level * 100) === 20) {
      alert("Your battery is low!");
    } else if (charging === false && Math.round(level * 100) <= 5) {
      toast.warn("Due to low battery call is disconnected!");
      onConfirm();
    }
  }, [level, charging]);

  useSocket("chatDisconnectResponse", async (data) => {
    if (data?.disconnect === true) {
      appointmentClose({ appointmentId: appointmentData?.appointmentId });
      localStorage.removeItem("joined");
      if (userDetails?.roleCode === "user") {
        navigate(`/review-appointment/${appointmentData?.appointmentId}`);
      } else {
        navigate(`/appointments-details/${appointmentData?.appointmentId}`);
        localStorage.removeItem("appointmentData");
      }
      setStop(true);
    }
  });

  const handleFileUpload = (files) => {
    setAttachments([...attachments, ...files]);
  };

  const handleRemoveAttachment = (index) => {
    const newAttachments = attachments.filter((_, i) => i !== index);
    setAttachments(newAttachments);
  };

  const renderPreview = (file) => {
    const fileType = file.type;
    if (fileType.startsWith("image/")) {
      return (
        <img
          src={URL.createObjectURL(file)}
          alt={file.name}
          className="w-full h-full object-cover"
        />
      );
    } else if (fileType === "application/pdf") {
      return (
        <object
          data={URL.createObjectURL(file)}
          type="application/pdf"
          className="w-full h-full"
        >
          <i className="fa-solid fa-file-pdf text-4xl text-red-600"></i>
        </object>
      );
    } else if (
      fileType === "application/msword" ||
      fileType ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      return <i className="fa-solid fa-file-word text-4xl text-blue-600"></i>;
    } else if (
      fileType === "application/vnd.ms-excel" ||
      fileType ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      return <i className="fa-solid fa-file-excel text-4xl text-green-600"></i>;
    } else {
      return <i className="fa-solid fa-file text-4xl text-gray-600"></i>;
    }
  };

  const renderAttachment = (attachment) => {
    const { url, filename, extension, loading } = attachment;

    if (loading) {
      return <i className="fa fa-spinner fa-spin text-4xl text-gray-600"></i>;
    }

    const handleDownload = async (e) => {
      e.stopPropagation();
      e.preventDefault();
      try {
        const response = await fetch(url, { crossOrigin: "anonymous" });
        const blob = await response.blob();
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error("Download failed", error);
      }
    };

    const downloadIcon = (
      <button
        onClick={handleDownload}
        className="z-40 absolute top-1 right-1 bg-white py-1 px-2 rounded-full hover:bg-slate-200"
      >
        <i className="fa-solid fa-download text-xl text-gray-600"></i>
      </button>
    );

    const commonProps = {
      className: "relative hover:bg-slate-200",
      target: "_blank",
    };

    if (
      extension === "jpeg" ||
      extension === "png" ||
      extension === "jpg" ||
      extension === "svg" ||
      extension == "webp"
    ) {
      return (
        <a href={url} data-fancybox="gallery" {...commonProps}>
          <img
            crossOrigin="anonymous"
            src={url}
            alt={filename}
            className="w-full h-52 object-cover"
          />
          {downloadIcon}
        </a>
      );
    }

    if (extension === "pdf") {
      return (
        <a href={url} {...commonProps}>
          <object data={url} type="application/pdf" className="w-full">
            <i className="fa-solid fa-file-pdf text-9xl text-red-600"></i>
          </object>
          {downloadIcon}
        </a>
      );
    }

    if (extension === "doc" || extension === "docx") {
      return (
        <a href={url} {...commonProps}>
          <i className="fa-solid fa-file-word text-9xl text-blue-600"></i>
          {downloadIcon}
        </a>
      );
    }

    if (extension === "xls" || extension === "xlsx") {
      return (
        <a href={url} {...commonProps}>
          <i className="fa-solid fa-file-excel text-9xl text-green-600"></i>
          {downloadIcon}
        </a>
      );
    }

    return (
      <a href={url} {...commonProps}>
        <i className="fa-solid fa-file text-9xl text-gray-600"></i>
        {downloadIcon}
      </a>
    );
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey && socket) {
      handleSendMessage();
    }
  };
  useEffect(() => {
    message === "\n" && setMessage("");
  }, [message]);

  usePageVisibility(token, socket, userDetails?._id);

  const onEmojiClick = (emojiObject) => {
    setMessage((prevInput) => prevInput + emojiObject.emoji);
    setShowPicker(false);
  };
  // Close emoji picker when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setShowPicker(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  //_________________Navigate page_______________________-

  // Define the handlePageLeave function
  const handlePageLeave = () => {
    if (!appointmentData || !userDetails || !socket) {
      console.error(
        "Either appointmentData, profile, or socket is not available."
      );
      return;
    }

    const userId =
      roleCode === "user"
        ? appointmentData?.clientId
        : appointmentData?.consultantId;

    if (!userId) {
      console.error("User  ID is not defined.");
      localStorage.removeItem("callPageLeaveFlag");
      return;
    }

    let disconnectData = {
      disconnected: disconnectedProfile?.disconnected || true,
      userId: userId,
    };

    if (appointmentData?.sessionId && appointmentData?.mode !== "chat") {
      disconnectData.sessionId = appointmentData.sessionId;
    } else if (appointmentData?.mode === "chat") {
      disconnectData.appointmentId = appointmentData?.appointmentId;
    } else {
      console.error("Invalid appointment data for socket event.");
      return;
    }

    socket &&
      appointmentData &&
      socket.emit("userAlreadyInCall", {
        userId: userDetails?._id,
        appointmentId: appointmentData?.appointmentId,
        disconnect: true,
        roleCode: roleCode,
      });

    socket &&
      socket.emit("userDisconnectFromCallNotify", {
        disconnected: true,
        sessionId: appointmentData?.sessionId,
        appointmentId: appointmentData?.appointmentId,
        userId:
          roleCode === "user"
            ? appointmentData?.clientId
            : appointmentData?.consultantId,
      });
    localStorage.setItem("callPageLeaveFlag", true);
  };

  const handleSocketDisconnect = () => {
    if (socket && appointmentData) {
      socket.emit("userAlreadyInCall", {
        userId: user?._id,
        appointmentId: appointmentData?.appointmentId,
        disconnect: true,
        roleCode: roleCode,
      });
    }
  };

  useEffect(() => {
    const beforeUnloadHandler = () => handleSocketDisconnect();

    window.addEventListener("beforeunload", beforeUnloadHandler);

    return () => {
      window.removeEventListener("beforeunload", beforeUnloadHandler);
    };
  }, []);

  // useEffect(() => {
  //   window.addEventListener("beforeunload", () => {
  //     socket &&
  //       appointmentData &&
  //       socket.emit("userAlreadyInCall", {
  //         userId: user?._id,
  //         appointmentId: appointmentData?.appointmentId,
  //         disconnect: true,
  //         roleCode: roleCode,
  //       });
  //   });

  //   return () => {
  //     window.removeEventListener("beforeunload", () => {
  //       socket &&
  //         appointmentData &&
  //         socket.emit("userAlreadyInCall", {
  //           userId: user?._id,
  //           appointmentId: appointmentData?.appointmentId,
  //           disconnect: true,
  //           roleCode: roleCode,
  //         });
  //     });
  //   };
  // }, [socket, appointmentData, user]);

  const usePageTracking = () => {
    const location = useLocation();
    useEffect(() => {
      const handleRouteChange = () => {
        if (location.pathname === `/text-chat/${id}`) {
          handlePageLeave();
        }
      };
      return () => {
        handleRouteChange();
      };
    }, [location]);
  };
  usePageTracking();
  //____________________________________________________________

  const [upcoming, setUpcoming] = useState(null);
  useEffect(() => {
    fetchAppointmentList(
      token,
      {
        limit: 1,
        "status[0]": "active",
        sortQuery: "details.appointmentStartTime",
      },
      setUpcoming
    );
  }, []);
  let date = upcoming && upcoming[0]?.details?.appointmentStartTime;

  return (
    <>
      {/* <div class="flex items-center justify-center h-30px bg-gray-100">
        <div class="animate-pulse text-lg font-semibold text-blue-500">
          {" "}
          {`You have new appointment within ${10} minutes.`}
        </div>
      </div> */}
      {date && <TimeCountdown targetDate={date} />}
      {loaded ? (
        <div className="w-full h-[calc(100vh-0rem)] flex flex-col ">
          {!appointmentData?.appointmentStartTime ? (
            <>
              <div className="flex flex-col sm:flex-row items-center justify-between w-full gap-4 p-4 bg-gray-100 rounded-md shadow-lg">
                <p className="text-lg sm:text-2xl font-extrabold text-gray-800 text-center sm:text-left">
                  {/* Please wait until the other joins... */}
                  {langConfig?.waitingWord?.[language]}
                </p>
                <Button
                  buttonLabel="Leave"
                  buttonIcon="fa-solid fa-square-phone-hangup"
                  buttonIconPosition="left"
                  buttonClasses="flex items-center gap-2 bg-red-600 hover:bg-red-500 text-white font-semibold px-6 py-3 rounded-lg shadow-md transition duration-300 uppercase text-sm tracking-wider"
                  buttonFunction={() => {
                    navigate("/appointments");
                  }}
                />
              </div>
            </>
          ) : (
            <>
              <div className="flex items-center bg-prontopsy-blue p-3 md:px-16  text-white shadow-lg">
                <div className="lg:w-16 md:w-14 w-10 aspect-square rounded-full overflow-hidden">
                  <Image
                    src={
                      user &&
                      appointmentData?.callerData &&
                      user?.roleCode === "user"
                        ? appointmentData?.callerData?.consultant?.image
                        : appointmentData?.callerData?.user?.image
                    }
                    alt="Consultant Image"
                    effect="blur"
                    className="object-cover !w-14 !h-14 rounded-full"
                  />
                </div>
                <div className="md:pl-4 pl-2">
                  <h2 className="md:text-lg text-sm">
                    {user &&
                    appointmentData?.callerData &&
                    user?.roleCode === "user"
                      ? appointmentData?.callerData?.consultant?.name
                      : appointmentData?.callerData?.user?.name}
                  </h2>
                </div>

                <div className="flex ml-auto items-center">
                  <div className="mr-2">
                    {" "}
                    <h3 className="text-md bg-prontopsy-pink px-2 py-1 rounded-md">
                      Chat
                    </h3>
                  </div>
                  <div>
                    <div className="lg:text-xl md:text-lg text-sm border-r border-l px-2">
                      {elapsedTime}
                    </div>
                  </div>
                  <div className="px-2">
                    <Button
                      buttonClasses={"!h-8"}
                      buttonLabel={"Exit"}
                      buttonFunction={() => {
                        setIsLeaveModalOpen(true);
                        setStop(true);
                      }}
                    />
                  </div>
                </div>
              </div>
            </>
          )}

          {list && Array.isArray(list) && list.length > 0 ? (
            <div className="flex-1 overflow-auto p-5 md:px-16 bg-gray-100">
              {list.map((data, index) => (
                <div
                  key={index}
                  className={`flex items-start mb-4 ${
                    data?.fromUser?._id === user?._id
                      ? "justify-end"
                      : "justify-start"
                  }`}
                >
                  {data?.fromUser?._id !== user?._id && (
                    <img
                      crossOrigin="anonymous"
                      src={data?.fromUser?.image?.url}
                      className="w-10 h-10 rounded-full object-cover mr-3"
                      alt=""
                    />
                  )}
                  <div
                    className={`p-3 rounded-lg shadow-lg ${
                      data?.fromUser?._id === user?._id
                        ? "bg-blue-100 text-black"
                        : "bg-white text-gray-800"
                    }`}
                    style={{ maxWidth: "70%" }}
                  >
                    {data?.attachment &&
                      data?.attachment?.map((elm, idx) => (
                        <div
                          key={idx}
                          className="mb-2 w-full  rounded-lg overflow-hidden border-b border-slate-900 py-1"
                        >
                          {renderAttachment(elm)}
                        </div>
                      ))}
                    <div className="text-sm break-all">{data?.text}</div>
                    <div className="text-xs mt-2 text-gray-500">
                      {dayjs(data?.createdAt).format("MMM D, YYYY h:mm A")}
                      {data?.status === "sending" && (
                        <i className="fa fa-clock ml-2"></i>
                      )}
                      {data?.status === "sent" && (
                        <i className="fa fa-check ml-2"></i>
                      )}
                    </div>
                  </div>
                  {data?.fromUser?._id === user?._id && (
                    <img
                      crossOrigin="anonymous"
                      src={data?.fromUser?.image?.url}
                      className="w-10 h-10 rounded-full object-cover ml-3"
                      alt=""
                    />
                  )}
                </div>
              ))}
              <div ref={targetRef}></div>
            </div>
          ) : (
            <>
              <div
                className="py-6 px-12 md:space-y-8 space-y-4 flex flex-col items-center justify-center flex-grow overflow-auto border"
                style={{ maxHeight: "90vh" }}
              >
                <p className="text-center md:text-lg text-base text-gray-500">
                  No messages
                </p>
                <p className="text-center md:text-lg text-base text-gray-500">
                  Please Start Your Conversations..
                </p>
              </div>
            </>
          )}

          <div className="bg-white shadow-md rounded-lg p-6 flex flex-col md:px-10 px-4 md:pt-5 pt-0 space-y-3   ">
            <div className="flex flex-wrap space-x-3 mb-3 gap-1">
              {attachments.map((attachment, index) => (
                <div
                  key={index}
                  className="relative bg-gray-100 border p-2 rounded w-32 h-40"
                >
                  <button
                    className="absolute top-0 right-0 m-2 text-red-500"
                    onClick={() => handleRemoveAttachment(index)}
                  >
                    <i className="fa-solid fa-times"></i>
                  </button>
                  <div className="w-full h-full overflow-hidden flex items-center justify-center">
                    {renderPreview(attachment)}
                  </div>
                  <span className="text-gray-700 truncate mt-3 block text-center text-xs">
                    {attachment.name}
                  </span>
                </div>
              ))}
            </div>
            {showPicker && (
              <div
                ref={pickerRef}
                className="absolute bottom-16 right-10 bg-white border shadow-lg rounded p-2"
              >
                <button
                  onClick={() => setShowPicker(false)}
                  className="ml-auto mb-3 block text-red-500"
                >
                  ✖️
                </button>
                <Picker onEmojiClick={onEmojiClick} />
              </div>
            )}
            {!appointmentData?.appointmentStartTime ? (
              <>
                <div className="flex flex-col sm:flex-row items-center justify-between w-full gap-4 p-4 bg-gray-100 rounded-md shadow-lg">
                  <p className="text-lg sm:text-2xl font-extrabold text-gray-800 text-center sm:text-left">
                    {/* Please wait until the other joins... */}
                    {langConfig?.waitingWord?.[language]}
                  </p>
                  <Button
                    buttonLabel="Leave"
                    buttonIcon="fa-solid fa-square-phone-hangup"
                    buttonIconPosition="left"
                    buttonClasses="flex items-center gap-2 bg-red-600 hover:bg-red-500 text-white font-semibold px-6 py-3 rounded-lg shadow-md transition duration-300 uppercase text-sm tracking-wider"
                    buttonFunction={() => {
                      navigate("/appointments");
                    }}
                  />
                </div>
              </>
            ) : (
              <>
                <div
                  className="flex space-x-3 items-center"
                  onKeyDown={handleKeyDown}
                >
                  <textarea
                    className="w-full p-2 focus:outline-none text-l rounded-full flex !resize-none max-h-48 !overflow-hidden items-center h-11"
                    placeholder="Type your message..."
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>

                  <div className="flex items-center md:gap-3 gap-2 flex-shrink-0">
                    <Button
                      buttonClasses={
                        "!flex !bg-gray-200 !text-gray-700 md:!h-12 !h-8 md:!w-12 !w-8 !aspect-square !justify-center !items-center !rounded-full !cursor-pointer"
                      }
                      buttonIcon={"fa-regular fa-face-smile"}
                      buttonIconPosition={"left"}
                      buttonHasLink={""}
                      buttonFunction={() => setShowPicker((val) => !val)}
                    />

                    <label className="flex bg-gray-200 text-gray-700 md:!w-12 !w-8 !aspect-square justify-center items-center rounded-full cursor-pointer !flex-shrink-0">
                      <i className="fa-solid fa-paperclip"></i>
                      <input
                        type="file"
                        className="hidden"
                        multiple
                        onChange={(e) => handleFileUpload(e.target.files)}
                      />
                    </label>
                    <button
                      onClick={handleSendMessage}
                      disabled={
                        sending ||
                        (!message && attachments?.length === 0) ||
                        (message && message.trim().length === 0)
                      }
                      className="flex bg-blue-500 text-slate-100 md:!w-12 !w-8 !aspect-square justify-center items-center rounded-full cursor-pointer !flex-shrink-0 md:text-lg text-base"
                    >
                      {sending ? (
                        <i className="fa fa-clock"></i>
                      ) : (
                        <i className="fa fa-paper-plane-top"></i>
                      )}
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        <SpinLoader />
      )}
      <LeaveChatModal
        isOpen={isLeaveModalOpen}
        setIsOpen={setIsLeaveModalOpen}
        onConfirm={onConfirm}
        loading={loading}
      />
    </>
  );
};

export default TextChat;
