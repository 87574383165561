import React, { useEffect, useState } from "react";
import Image from "../../../Image";
import User from "../../../../../assets/images/user-03.webp";
import Button from "../../../../form/Button";
import InputGroup from "../../../../form/Input";
import Select from "../../../../form/Select";
import { useDispatch, useSelector } from "react-redux";
import {
  commonFormLabels,
  findPsychologistLang,
  profile,
  saveChange,
} from "../../../language";
import {
  fetchCountryList,
  updateProfileService,
} from "../../../../../service/profileService";
import { toast } from "react-toastify";
import UploadImage from "../../../UploadImage";
import { setProfileData } from "../../../../../store/slice/profileSlice";
import { setInitialProfileData } from "../../../../../store/slice/initialProfileSlice";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import InputDate from "../../../../form/InputDate";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const InformazioniPersonall = ({ data, dataLoaded }) => {
  const lang = useSelector((state) => state?.languageSlice?.data);
  const language = localStorage.getItem("language") || lang;
  const profileData = useSelector((state) => state?.profileSlice?.data);
  const [formData, setFormData] = useState({});
  const [countryList, setCountryList] = useState([]);
  const [countryLoaded, setCountryLoaded] = useState(true);
  const [image, setImage] = useState(null);
  const [loaded, setLoaded] = useState(true);
  const [nationalityTemp, setNationalityTemp] = useState({});
  const [imageUrl, setImageUrl] = useState(null);
  const dispatch = useDispatch();
  const role = profileData && profileData?.roleCode;
  const id = profileData && profileData?._id;
  const token = localStorage.getItem("auth_token") || Cookies.get("auth_token");
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate("/");
      localStorage.clear();
      Cookies.remove("auth_token");
    }
  }, [token, navigate, Cookies]);

  const informazioniPersonallData = {
    title: profile?.personalInformation?.[language],
    image: User,
  };
  const nationalityData =
    countryList.length > 0 &&
    countryList.map((elm) => ({
      value: elm._id,
      name: elm.name,
    }));

  const countryCodeData =
    countryList.length > 0 &&
    countryList.map((elm) => ({
      value: elm?.phone_code,
      name: elm?.phone_code,
      image: elm?.iso2,
    }));

  const timeZoneData =
    countryList.length > 0 &&
    countryList
      ?.map((country) =>
        country?.timezones?.map((timezone) => ({
          zoneName: timezone?.zoneName,
          gmtOffsetName: timezone?.gmtOffsetName,
        }))
      )
      .flat();
  const zoneData =
    timeZoneData &&
    timeZoneData.map((elm) => ({
      name: elm?.zoneName,
      value: {
        gmtOffsetName: elm?.gmtOffsetName,
        zoneName: elm?.zoneName,
      },
    }));

  const countryAllData =
    countryList.length > 0 &&
    countryList.map((elm) => ({
      value: elm.name,
      name: elm.name,
    }));

  const genderData = [
    { value: "male", name: "Male" },
    { value: "female", name: "Female" },
    { value: "other", name: "Other" },
  ];

  useEffect(() => {
    fetchCountryList(setCountryList, setCountryLoaded);
  }, []);

  useEffect(() => {
    setFormData((pre) => ({
      ...pre,
      firstName: profileData && profileData?.firstName,
      lastName: profileData && profileData?.lastName,
      "personal[gender]": profileData && profileData?.personal?.gender,
      "personal[addressOfBirth][country]":
        profileData && profileData?.personal?.addressOfBirth?.country,
      "personal[addressOfBirth][zipcode]":
        profileData && profileData?.personal?.addressOfBirth?.zipcode,
      ISDCode: profileData && profileData?.ISDCode,
      phone: profileData && profileData?.phone,
      dateOfBirth: profileData?.dateOfBirth,
      "personal[addressOfBirth][city]":
        profileData && profileData?.personal?.addressOfBirth?.city,
      "personal[addressOfBirth][state]":
        profileData && profileData?.personal?.addressOfBirth?.state,
      "personal[nationality]":
        profileData && profileData?.personal?.nationality?._id,
      "personal[timeZone][zoneName]":
        profileData && profileData?.personal?.timeZone?.zoneName,
      "personal[timeZone][gmtOffsetName]":
        profileData && profileData?.personal?.timeZone?.gmtOffsetName,
    }));
    setNationalityTemp({
      name: profileData && profileData?.personal?.nationality?.nationality,
      value: profileData && profileData?.personal?.nationality?._id,
    });
  }, [profileData]);

  const handleFormData = (e) => {
    setFormData((pre) => ({
      ...pre,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async () => {
    const requiredFields = [
      { field: "firstName", message: "First name is required!" },
      { field: "lastName", message: "Last name is required!" },
      { field: "personal[gender]", message: "Gender is required!" },
      {
        field: "personal[addressOfBirth][country]",
        message: "Country is required!",
      },
      {
        field: "personal[addressOfBirth][zipcode]",
        message: "ZIP code is required!",
      },
      { field: "ISDCode", message: "Phone code is required!" },
      { field: "phone", message: "Phone number is required!" },
      { field: "dateOfBirth", message: "Date of birth is required!" },
      {
        field: "personal[addressOfBirth][city]",
        message: "City name is required!",
      },
      {
        field: "personal[addressOfBirth][state]",
        message: "State name is required!",
      },
      { field: "personal[nationality]", message: "Nationality is required!" },
      {
        field: "personal[timeZone][zoneName]",
        message: "Zone name is required!",
      },
    ];

    // Check required fields
    for (let { field, message } of requiredFields) {
      if (!formData?.[field]) {
        toast.info(message);
        return;
      }
    }

    setLoaded(false);

    try {
      const res = await updateProfileService({
        ...formData,
        roleCode: role,
        id,
        image: image || "",
      });

      setLoaded(true);

      if (res?.data?.status === 200) {
        const info = res?.data?.data;

        const resData = {
          _id: info?._id,
          firstName: info?.firstName,
          lastName: info?.lastName,
          image: info?.image,
          email: info?.email,
          roleCode: info?.roleCode,
          createdAt: info?.createdAt,
        };

        // Update UI
        toast.success(res?.data?.message);
        setImageUrl(info?.image?.url);
        dispatch(setProfileData(info));
        dispatch(setInitialProfileData(resData));
        localStorage.setItem("userDetails", JSON.stringify(resData));
      } else if (res?.status === 500) {
        toast.error("Unsupported image format!", { autoClose: 3000 });
      }
    } catch (error) {
      setLoaded(true);
      console.error("Error updating profile:", error);
      toast.error("An unexpected error occurred. Please try again later.");
    }
  };

  useEffect(() => {
    setImageUrl(profileData && profileData?.image?.url);
  }, [profileData]);

  useEffect(() => {
    if (image) {
      const imageUrl = URL.createObjectURL(image);
      setImageUrl(imageUrl);
    }
  }, [image]);

  return (
    <div className="relative">
      <div className="relative w-full border-t border-dashed border-neutral-300 py-4">
        {informazioniPersonallData?.title && (
          <h2
            className="text-base text-prontopsy-blue font-RobotoSlab font-bold uppercase"
            dangerouslySetInnerHTML={{
              __html: informazioniPersonallData?.title,
            }}
          ></h2>
        )}
      </div>
      <div className="block lg:flex justify-between gap-5 py-10 border-t border-b border-neutral-300 space-y-5 lg:space-y-0">
        <div className="w-full lg:w-60 h-60 relative">
          {!dataLoaded ? (
            <div className="w-full h-full rounded-lg lg:rounded-full overflow-hidden animate-pulse bg-gray-200"></div>
          ) : (
            <>
              <div className="w-full h-full rounded-lg lg:rounded-full overflow-hidden">
                <Image
                  src={imageUrl}
                  alt=""
                  className="object-cover w-full h-full"
                />
              </div>
              <div className="flex items-center justify-center absolute bottom-3 right-3">
                <UploadImage setImage={setImage} />
              </div>
            </>
          )}
        </div>

        <div className="w-full lg:w-[calc(100%-240px)]">
          <div className="grid grid-cols-12 gap-3">
            <div className="md:col-span-4 col-span-12">
              <div className="relative">
                <InputGroup
                  label={`*${commonFormLabels?.firstName?.[language]} :`}
                  inputPlaceholder={commonFormLabels?.firstName?.[language]}
                  inputValue={formData?.firstName}
                  loaded={dataLoaded}
                  inputName={"firstName"}
                  onChange={handleFormData}
                  labelClasses={
                    "!uppercase !text-[#002C6BC9] !font-bold !text-[10px] font-RobotoSlab"
                  }
                  inputClasses={"!bg-transparent !h-12"}
                />
              </div>
            </div>
            <div className="md:col-span-4 col-span-12">
              <div className="relative">
                <InputGroup
                  label={`*${commonFormLabels?.lastName?.[language]} :`}
                  inputPlaceholder={commonFormLabels?.lastName?.[language]}
                  inputValue={formData?.lastName}
                  loaded={dataLoaded}
                  inputName={"lastName"}
                  onChange={handleFormData}
                  labelClasses={
                    "!uppercase !text-[#002C6BC9] !font-bold !text-[10px] font-RobotoSlab"
                  }
                  inputClasses={"!bg-transparent !h-12"}
                />
              </div>
            </div>
            <div className="md:col-span-4 col-span-6">
              <div className="relative">
                <Select
                  label={`*${commonFormLabels?.gender?.[language]} :`}
                  selectedValue={{
                    name: formData?.["personal[gender]"],
                    value: formData?.["personal[gender]"],
                  }}
                  placeholder={`${findPsychologistLang?.select?.[language]} ${commonFormLabels?.gender?.[language]}`}
                  labelClasses={
                    "!uppercase !text-[#002C6BC9] !font-bold !text-[10px] font-RobotoSlab"
                  }
                  dropdownButtonClass={"!h-12 !bg-transparent"}
                  isSearch={true}
                  loaded={dataLoaded}
                  dropdownData={genderData}
                  dropdownClass={"w-full"}
                  handleSelect={(val) =>
                    handleFormData({
                      target: { value: val?.value, name: "personal[gender]" },
                    })
                  }
                />
              </div>
            </div>

            <div className="md:col-span-3 col-span-6">
              <div className="relative">
                <Select
                  selectedValue={formData?.ISDCode}
                  label={"*telephone code:"}
                  placeholder={"Select Phone Code"}
                  labelClasses={
                    "!uppercase !text-[#002C6BC9] !font-bold !text-[10px] font-RobotoSlab !w-full"
                  }
                  dropdownClass={"w-full"}
                  dropdownButtonClass={"!h-12 !bg-transparent !w-full"}
                  isSearch={true}
                  dropdownData={countryCodeData}
                  handleSelect={(val) => {
                    handleFormData({
                      target: {
                        value: val?.value,
                        name: "ISDCode",
                      },
                    });
                  }}
                  isFlag={true}
                />
              </div>
            </div>
            <div className="md:col-span-3 col-span-6">
              <div className="relative">
                <InputGroup
                  label={`*${commonFormLabels?.phone?.[language]} :`}
                  inputPlaceholder={commonFormLabels?.phone?.[language]}
                  inputName={"phone"}
                  loaded={dataLoaded}
                  onChange={handleFormData}
                  inputValue={formData?.phone}
                  labelClasses={
                    "!uppercase !text-[#002C6BC9] !font-bold !text-[10px] font-RobotoSlab"
                  }
                  inputClasses={"!bg-transparent !h-12"}
                />
              </div>
            </div>

            <div className="md:col-span-3 col-span-6">
              <div className="relative">
                <InputGroup
                  label={`*${commonFormLabels?.code?.[language]} :`}
                  inputPlaceholder={commonFormLabels?.code?.[language]}
                  loaded={dataLoaded}
                  inputName={"personal[addressOfBirth][zipcode]"}
                  inputValue={formData?.["personal[addressOfBirth][zipcode]"]}
                  onChange={handleFormData}
                  labelClasses={
                    "!uppercase !text-[#002C6BC9] !font-bold !text-[10px] font-RobotoSlab"
                  }
                  inputClasses={"!bg-transparent !h-12"}
                />
              </div>
            </div>
            <div className="md:col-span-3 col-span-12">
              <div className="relative">
                <Select
                  label={`*${commonFormLabels?.nationality?.[language]} :`}
                  selectedValue={nationalityTemp && { ...nationalityTemp }}
                  loaded={dataLoaded}
                  labelClasses={
                    "!uppercase !text-[#002C6BC9] !font-bold !text-[10px] font-RobotoSlab"
                  }
                  dropdownButtonClass={"!h-12 !bg-transparent"}
                  placeholder={`${findPsychologistLang?.select?.[language]} ${commonFormLabels?.nationality?.[language]}`}
                  isSearch={true}
                  dropdownData={nationalityData}
                  dropdownClass={"w-full"}
                  handleSelect={(val) => {
                    setNationalityTemp(val);
                    handleFormData({
                      target: {
                        value: val?.value,
                        name: "personal[nationality]",
                      },
                    });
                  }}
                />
              </div>
            </div>
            <div className="md:col-span-4 col-span-12">
              <div className="relative">
                <Select
                  label={`*${commonFormLabels?.timeZone?.[language]} :`}
                  selectedValue={{
                    name: formData?.["personal[timeZone][zoneName]"],
                    value: formData?.["personal[timeZone][zoneName]"],
                  }}
                  loaded={dataLoaded}
                  labelClasses={
                    "!uppercase !text-[#002C6BC9] !font-bold !text-[10px] font-RobotoSlab"
                  }
                  placeholder={`${findPsychologistLang?.select?.[language]} ${commonFormLabels?.timeZone?.[language]}`}
                  dropdownButtonClass={"!h-12 !bg-transparent"}
                  isSearch={true}
                  dropdownData={zoneData}
                  dropdownClass={"w-full"}
                  handleSelect={(val) => {
                    handleFormData({
                      target: {
                        value: val?.value?.zoneName,
                        name: "personal[timeZone][zoneName]",
                      },
                    });
                    handleFormData({
                      target: {
                        value: val?.value?.gmtOffsetName,
                        name: "personal[timeZone][gmtOffsetName]",
                      },
                    });
                  }}
                />
              </div>
            </div>
            <div className="md:col-span-4 col-span-6">
              <div className="relative">
                <InputDate
                  label={`*${commonFormLabels?.dateOfBirth?.[language]} :`}
                  inputPlaceholder={"MM/DD/YYYY"}
                  inputName={"dateOfBirth"}
                  loaded={dataLoaded}
                  onChange={(value) => {
                    setFormData((pre) => ({
                      ...pre,
                      dateOfBirth: value,
                    }));
                  }}
                  value={formData?.dateOfBirth}
                  labelClasses={
                    "!uppercase !text-[#002C6BC9] !font-bold !text-[10px] font-RobotoSlab"
                  }
                  inputClasses={"!bg-transparent !h-12"}
                />
              </div>
            </div>
            <div className="md:col-span-4 col-span-6">
              <div className="relative">
                <InputGroup
                  label={`*${commonFormLabels?.city?.[language]} :`}
                  inputPlaceholder={commonFormLabels?.city?.[language]}
                  loaded={dataLoaded}
                  inputName={"personal[addressOfBirth][city]"}
                  inputValue={formData?.["personal[addressOfBirth][city]"]}
                  labelClasses={
                    "!uppercase !text-[#002C6BC9] !font-bold !text-[10px] font-RobotoSlab"
                  }
                  onChange={handleFormData}
                  inputClasses={"!bg-transparent !h-12"}
                />
              </div>
            </div>
            <div className="md:col-span-4 col-span-6">
              <div className="relative">
                <InputGroup
                  label={`*${commonFormLabels?.state?.[language]} :`}
                  inputPlaceholder={commonFormLabels?.state?.[language]}
                  inputName={"personal[addressOfBirth][state]"}
                  loaded={dataLoaded}
                  onChange={handleFormData}
                  inputValue={formData?.["personal[addressOfBirth][state]"]}
                  labelClasses={
                    "!uppercase !text-[#002C6BC9] !font-bold !text-[10px] font-RobotoSlab"
                  }
                  inputClasses={"!bg-transparent !h-12"}
                />
              </div>
            </div>
            <div className="md:col-span-4 col-span-6">
              <div className="relative">
                <Select
                  label={`*${commonFormLabels?.country?.[language]} :`}
                  selectedValue={{
                    name: formData?.["personal[addressOfBirth][country]"],
                    value: formData?.["personal[addressOfBirth][country]"],
                  }}
                  placeholder={`${findPsychologistLang?.select?.[language]} ${commonFormLabels?.country?.[language]}`}
                  loaded={dataLoaded}
                  labelClasses={
                    "!uppercase !text-[#002C6BC9] !font-bold !text-[10px] font-RobotoSlab"
                  }
                  dropdownButtonClass={"!h-12 !bg-transparent"}
                  isSearch={true}
                  dropdownData={countryAllData}
                  dropdownClass={"w-full"}
                  handleSelect={(val) =>
                    handleFormData({
                      target: {
                        value: val?.value,
                        name: "personal[addressOfBirth][country]",
                      },
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="relative mt-3 flex justify-end">
        <Button
          buttonLabel={saveChange?.[language]}
          buttonLabelClasses={
            "!uppercase !text-base !whitespace-nowrap !font-medium"
          }
          buttonClasses={
            "!rounded-xl !px-4 !h-12 !text-prontopsy-pink !text-white !bg-gradient-to-r !from-prontopsy-blue !to-prontopsy-pink !hover:bg-prontopsy-pink !duration-300"
          }
          buttonFunction={handleSubmit}
          isDisable={!loaded}
        />
      </div>
    </div>
  );
};

export default InformazioniPersonall;
