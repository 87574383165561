import React, { useEffect, useState } from "react";
import { setTitle } from "../helpers/MetaTag";
import PageBanner from "../components/sections/PageBanner";
import SmartPortal from "../components/sections/SmartPortal";
import Register from "../components/sections/Register";
import HowCanWeHelp from "../components/sections/HowCanWeHelp";
import { useSelector } from "react-redux";

const Faq = () => {
  const lang = useSelector((state) => state?.languageSlice?.data);
  const language = localStorage.getItem("language") || lang;
  const [bannerInfo, setbannerInfo] = useState({});
  setTitle("Prontopsy | Frequently Asked Questions");
  const data = useSelector((state) => state?.pageSlice?.data);
  useEffect(() => {
    let info = data.length > 0 && data.filter((elm) => elm?.title?.en == "Faq");
    setbannerInfo(info[0]);
  }, [data]);
  useEffect(() => {
    localStorage.setItem("pageName", "faq");
  }, []);

  const bannerData = {
    image:
      bannerInfo &&
      bannerInfo?.banner &&
      bannerInfo?.banner.length > 0 &&
      bannerInfo?.banner[0]?.image?.url,
    title:
      bannerInfo &&
      bannerInfo?.banner &&
      bannerInfo?.banner.length > 0 &&
      bannerInfo?.banner[0]?.title?.[language],
  };
  return (
    <>
      <PageBanner bannerData={bannerData} />
      <HowCanWeHelp />
      <SmartPortal />
      <Register />
    </>
  );
};

export default Faq;
