import React, { useEffect, useState } from "react";
import Checkbox from "../../../../form/Checkbox";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { appointmentDetailsLang } from "../../../language";
import SpinLoader from "../../../table/SpinLoader";

const Dettagli = ({ data, appointmentDetailsData }) => {
  const lang = useSelector((state) => state?.languageSlice?.data);
  const language = localStorage.getItem("language") || lang;
  const [customerData, setCustomerData] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const role = data && data?.roleCode;

  const convertToLocal = (utcDateStr) => {
    const utcDate = new Date(utcDateStr);
    return utcDate.toLocaleString();
  };

  useEffect(() => {
    const customerData = [
      {
        title: `${
          role == "user"
            ? `${appointmentDetailsLang?.clientBusinessTitle?.[language]}`
            : `${appointmentDetailsLang?.consultantBusinessTitle?.[language]}`
        } `,
        fields: [
          {
            label: `${
              role == "user"
                ? `${appointmentDetailsLang?.businessName?.[language]}`
                : `${appointmentDetailsLang?.consultantName?.[language]}`
            }`,
            value: `${data?.firstName} ${data?.lastName}`,
          },
          role == "consultant" && {
            label: `${
              role == "consultant" &&
              `${appointmentDetailsLang?.professionalQualification?.[language]}`
            }`,
            value: `${data?.member?.personalQualification?.title?.en}`,
          },
        ].filter(Boolean),
      },
      {
        title: `${appointmentDetailsLang?.fiscalInformation?.[language]}`,
        fields: [
          {
            label: `${appointmentDetailsLang?.businessAddress?.[language]}`,
            value: `${data?.billing?.address?.address1}, ${data?.billing?.address?.state}, ${data?.billing?.address?.country}`,
          },
          {
            label: `${appointmentDetailsLang?.businessCity?.[language]}`,
            value: `${data?.billing?.address?.address1}`,
          },
          {
            label: `${appointmentDetailsLang?.fiscalCode?.[language]}`,
            value: data?.billing?.fiscalCode,
          },
          {
            label: `${appointmentDetailsLang?.pec?.[language]}`,
            value: data?.billing?.pec,
          },
          {
            label: `${appointmentDetailsLang?.businessProvince?.[language]}`,
            value: "",
          },
          {
            label: `${appointmentDetailsLang?.personalEmail?.[language]}`,
            value: data?.billing?.businessEmail,
          },
          {
            label: `${appointmentDetailsLang?.sdi?.[language]}`,
            value: data?.billing?.sdi,
          },
          {
            label: `${appointmentDetailsLang?.taxIdentificationCode?.[language]}`,
            value: data?.billing?.taxIdentificationCode,
          },
          {
            label: `${appointmentDetailsLang?.businessCountry?.[language]}`,
            value: data?.billing?.address?.country,
          },
          {
            label: `${appointmentDetailsLang?.zipcode?.[language]}`,
            value: data?.billing?.address?.zipcode,
          },
        ].filter(Boolean),
      },
      role &&
        role === "consultant" && {
          title: `${appointmentDetailsLang?.insuranceInformation?.[language]}`,
          fields: [
            {
              label: `${appointmentDetailsLang?.insuranceCompanyName?.[language]}`,
              value: data?.insurance?.companyName,
            },
            {
              label: `${appointmentDetailsLang?.insuranceNumber?.[language]}`,
              value: data?.insurance?.number,
            },
            {
              label: `${appointmentDetailsLang?.insuranceExpirationDate?.[language]}`,
              value: dayjs(
                convertToLocal(data?.insurance?.expirationDate)
              ).format("YYYY-MM-DD"),
            },
          ].filter(Boolean),
        },
      {
        title: `${appointmentDetailsLang?.otherInformation?.[language]}`,
        fields: [
          { label: `${appointmentDetailsLang?.nota?.[language]}`, value: "" },
        ],
      },
    ].filter(Boolean);

    const serviceData = [
      role == "consultant" && {
        title: `${appointmentDetailsLang?.membershipInformation?.[language]}`,
        fields: [
          {
            label: `${appointmentDetailsLang?.city?.[language]}`,
            value: data?.member?.address?.city,
          },
          {
            label: `${appointmentDetailsLang?.membershipName?.[language]}`,
            value: data?.member?.name,
          },
          {
            label: `${appointmentDetailsLang?.region?.[language]}`,
            value: data?.member?.address?.region,
          },
          {
            label: `${appointmentDetailsLang?.membershipRegistrationNo?.[language]}`,
            value: data?.member?.registrationNo,
          },
          {
            label: `${appointmentDetailsLang?.membershipRegistrationDate?.[language]}`,
            value: dayjs(convertToLocal(data?.member?.registrationDate)).format(
              "YYYY-MM-DD"
            ),
          },
        ].filter(Boolean),
      },
      {
        title: `${appointmentDetailsLang?.serviceInformation?.[language]}`,
        fields: [
          {
            label: `${appointmentDetailsLang?.serviceOffered?.[language]}`,
            value: appointmentDetailsData?.service?.service?.title?.[language],
          },
          {
            label: `${appointmentDetailsLang?.mode?.[language]}`,
            value: appointmentDetailsData?.service?.mode?.title?.[language],
          },
          {
            label: `${appointmentDetailsLang?.dateOfAppointMent?.[language]}`,
            value: dayjs(
              convertToLocal(appointmentDetailsData?.details?.appointmentDate)
            ).format("YYYY-MM-DD"),
          },
          {
            label: `${appointmentDetailsLang?.consultantJoinTime?.[language]}`,
            value: dayjs(
              convertToLocal(appointmentDetailsData?.consultantJoinTime)
            ).format("hh:mm:ss A"),
          },
          {
            label: `${appointmentDetailsLang?.startTime?.[language]}`,
            value: dayjs(
              convertToLocal(
                appointmentDetailsData?.details?.appointmentStartTime
              )
            ).format("hh:mm:ss A"),
          },
          {
            label: `${appointmentDetailsLang?.endTime?.[language]}`,
            value: dayjs(
              convertToLocal(
                appointmentDetailsData?.details?.appointmentEndTime
              )
            ).format("hh:mm:ss A"),
          },
        ].filter(Boolean),
      },
    ].filter(Boolean);

    setCustomerData(customerData);
    setServiceData(serviceData);
  }, [data, language, role]);

  return (
    <>
      {data ? (
        <div className="relative">
          <div className="relative w-full py-5 px-10 bg-prontopsy-blue rounded-t-2xl">
            <div className="uppercase text-lg text-white font-UnisonPro font-bold italic">
              {`${data?.firstName} ${data?.lastName}`}
            </div>
          </div>
          <div className="block lg:flex justify-between gap-10">
            <div className="w-full lg:w-1/2">
              <div className="space-y-10 py-4">
                {data !== undefined &&
                  customerData?.map((section, index) => (
                    <div key={index} className="relative space-y-0.5">
                      <div className="text-bas font-medium font-RobotoSlab pb-2 border-b border-dashed border-neutral-400 text-prontopsy-blue">
                        {section.title}
                      </div>
                      <div className="grid grid-cols-2 gap-2 my-2">
                        {section.fields.map((field, idx) => (
                          <div key={idx} className="flex">
                            <div className="">
                              <div className="uppercase text-prontopsy-blue font-medium text-sm font-RobotoSlab">
                                {field.label}
                              </div>
                              <div className="capitalize text-neutral-400 font-medium text-sm font-RobotoSlab">
                                {field.value}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
              </div>
            </div>

            <div className="w-full lg:w-1/2">
              <div className="space-y-10 py-4">
                {data !== undefined &&
                  serviceData?.map((section, index) => (
                    <div key={index} className="relative space-y-0.5">
                      <div className="text-bas font-medium font-RobotoSlab pb-2 border-b border-dashed border-neutral-400 text-prontopsy-blue">
                        {section.title}
                      </div>
                      <div className="grid grid-cols-2 gap-2 my-2">
                        {section.fields.map((field, idx) => (
                          <div key={idx} className="flex">
                            <div className="">
                              <div className="uppercase text-prontopsy-blue font-medium text-sm font-RobotoSlab">
                                {field.label}
                              </div>
                              <div className="capitalize text-neutral-400 font-medium text-sm font-RobotoSlab">
                                {field.value}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}

                <div className="relative space-y-0.5">
                  <div className="flex w-full">
                    <Checkbox
                      checkboxLabel={
                        appointmentDetailsLang?.checkboxLabel1?.[language]
                      }
                      checkboxLabelClass={
                        "!text-sm !text-neutral-400 font-RobotoSlab "
                      }
                      isChecked={true}
                    />
                  </div>
                  <div className="flex w-full">
                    <Checkbox
                      checkboxLabel={
                        appointmentDetailsLang?.checkboxLabel2?.[language]
                      }
                      checkboxLabelClass={
                        "!text-sm !text-neutral-400 font-RobotoSlab "
                      }
                      isChecked={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <SpinLoader />
      )}
    </>
  );
};

export default Dettagli;
