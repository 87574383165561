import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import DefaultHeader from "../shared/DefaultHeader";
import DefaultFooter from "../shared/DefaultFooter";
import { useSocket } from "../components/socket/SocketProvider";
import { getProfileDetails } from "../service/authService";
import Cookies from "js-cookie";

const DefaultLayout = () => {
  const appointmentDataLocal = JSON.parse(
    localStorage.getItem("appointmentData")
  );
  const [appointmentData, setAppointmentData] = useState(appointmentDataLocal);
  const [profile, setProfile] = useState({});
  const socket = useSocket();
  const token = localStorage.getItem("auth_token") || Cookies.get("auth_token");

  // Get profile details
  useEffect(() => {
    token &&
      getProfileDetails(token).then((response) => {
        if (response?.data?.status === 200) {
          if (response?.data?.docs?._id) {
            localStorage.setItem(
              "userDetails",
              JSON.stringify(response?.data?.docs)
            );
          }
          setProfile(response?.data?.docs);
          if (
            response?.data?.docs?.appointmentData?._id &&
            response?.data?.docs?.appointmentData?.status === "inProgress"
          ) {
            const appointment = {
              appointmentId: response?.data?.docs?.appointmentData?._id,
              appointmentStartTime:
                response?.data?.docs?.appointmentData?.consultantJoinTime,
              // ||Date.now(),
              callerData: {
                consultant: {
                  id: response?.data?.docs?.appointmentData?.consultantId,
                  image:
                    response?.data?.docs?.appointmentData?.consultantDetails
                      ?.image,
                  name:
                    response?.data?.docs?.appointmentData?.consultantDetails
                      ?.firstName +
                    " " +
                    response?.data?.docs?.appointmentData?.consultantDetails
                      ?.lastName,
                },
                user: {
                  id: response?.data?.docs?.appointmentData?.clientId,
                  image:
                    response?.data?.docs?.appointmentData?.clientDetails?.image,
                  name:
                    response?.data?.docs?.appointmentData?.clientDetails
                      ?.firstName +
                    " " +
                    response?.data?.docs?.appointmentData?.clientDetails
                      ?.lastName,
                },
              },
              consultantId: response?.data?.docs?.appointmentData?.consultantId,
              mode: response?.data?.docs?.appointmentData?.service?.mode?.slug,
              paymentMode: response?.data?.docs?.appointmentData?.paymentMode,
              sessionId: response?.data?.docs?.appointmentData?.sessionId,
              token: response?.data?.docs?.appointmentData?.sessionToken,
            };

            appointment.appointmentId && setAppointmentData(appointment);
            appointment.appointmentId &&
              localStorage.setItem(
                "appointmentData",
                JSON.stringify(appointment)
              );
          } else {
            socket &&
              socket.emit("consultantBusy", {
                consultantId: appointmentData?.callerData?.consultant?.id || "",
                userId: appointmentData?.callerData?.user?.id,
                isBusy: false,
              });
            localStorage.removeItem("appointmentData");
            localStorage.removeItem("callPageLeaveFlag");
          }
        }
      });
  }, [token]);

  return (
    <>
      <DefaultHeader />
      <Outlet />
      <DefaultFooter />
    </>
  );
};

export default DefaultLayout;
