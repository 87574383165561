import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { classNames } from "../../helpers/classNames";
import flags from "../../helpers/showFlag";
import Button from "../form/Button";
import { useSocket } from "../socket/SocketProvider";
import { getProfileDetails } from "../../service/authService";
import { fetchConsultantDetails } from "../../service/consultantService";
import Image from "../elements/Image";
import { toast } from "react-toastify";
import { consultantDetails } from "../elements/language";
import { useSelector } from "react-redux";
import CallingModal from "../elements/CallingModal";
import AppointmentModal from "../elements/AppointmentModal";
import Cookies from "js-cookie";
const color = {
  online: "bg-prontopsy-green",
  busy: "bg-prontopsy-yellow",
  offline: "bg-prontopsy-red",
  "": "bg-neutral-200",
};

const WishlistItem = ({ elm, handleRemove = () => {}, removeLoaded }) => {
  const token = localStorage.getItem("auth_token") || Cookies.get("auth_token");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const socket = useSocket();
  const roleCode = JSON.parse(localStorage.getItem("userDetails"))?.roleCode;
  const [status, setStatus] = useState(elm?.consultantData?.onlineStatus);
  const lang = useSelector((state) => state?.languageSlice?.data);
  const language = localStorage.getItem("language") || lang;
  const [data, setDetails] = useState(null);
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const [modeData, setmodeData] = useState([]);
  const [modeDataBooking, setmodeDataBooking] = useState([]);
  const [selectedServiceIdBooking, setSelectedServiceIdBooking] =
    useState(null);
  const [isCallingModalOpen, setIsCallingModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    let services = elm?.consultantData?.serviceDataTableMap;
    services.map((item) => {
      if (
        item?.service?.slug === "direct-access-online-psychological-counseling"
      ) {
        setmodeData(item?.mode);
        setSelectedServiceId(item?.service?._id);
      } else {
        setmodeDataBooking(item?.mode);
        setSelectedServiceIdBooking(item?.service?._id);
      }
    });
  }, []);
  useSocket("userStatusResponse", (info) => {
    if (info && info.userId && info?.userId === elm?.consultantData?._id) {
      setStatus(info?.online ? "online" : "offline");
    }
  });

  useSocket(
    "consultantBusyResponse",
    (data) => {
      if (
        data &&
        data?.consultantId &&
        data?.consultantId == elm?.consultantData?._id
      ) {
        data?.isBusy ? setStatus("busy") : setStatus("online");
      }
    },
    [socket]
  );

  useEffect(() => {
    fetchConsultantDetails(
      token,
      { id: elm?.consultantData?._id },
      setDetails
    ).then((res) => {
      if (res?.data?.status === 500) {
        navigate("/");
      }
    });
  }, []);

  const consultantData = {
    _id: data && data?._id,
    name:
      data && data?.firstName
        ? data.firstName + " " + data.lastName
        : "Consultant",
    image: data && data?.image?.url,
    specialization:
      (data && data?.specialization?.inventionArea?.title?.[language]) || "N/A",

    intervention:
      (data && data?.member?.personalQualification?.title[language]) || "N/A",

    language: {
      _id: data && data?.languagesData?._id,
      name: data && data?.languagesData?.name,
      code: data?.languagesData?.code,
    },
    timeZone: data?.personal?.timeZone?.zoneName,
    nationality: {
      name: (data && data?.personal?.nationality?.nationality) || "N/A",
      code: (data && data?.personal?.nationality?.code.toLowerCase()) || "N/A",
    },
    rate: {
      currency: (data && data?.currencyData?.currency) || "N/A",
      value:
        data &&
        data?.serviceDataTableMap &&
        Array.isArray(data?.serviceDataTableMap) &&
        data?.serviceDataTableMap.length > 0 &&
        data?.serviceDataTableMap[0]?.price,
    },
    isOnline: status,
    isFavorite: data?.isFavourite,
    rating: {
      value: data && data?.ratingData?.rating,
      reviews: data && data?.ratingData?.reviewCount,
    },
    biography: {
      title: consultantDetails?.biography?.[language],
      description: (data && data?.biography?.longBioDescription) || "N/A",
    },
    areaofSpecialization: {
      title: consultantDetails?.spelizationArea?.[language],
      content: [
        {
          _id: 1,
          label: consultantDetails?.areaOfIntervention?.[language],
          data:
            data &&
            data.specialization &&
            data.specialization.inventionArea &&
            data.specialization.inventionArea.title &&
            data.specialization.inventionArea.title[language]
              ? `<div>${data.specialization.inventionArea.title[language]}</div>`
              : "N/A",
        },
        {
          _id: 2,
          label: consultantDetails?.mentalHealth?.[language],
          data:
            (data &&
              data?.specialization?.mental?.map((elm) => {
                return `<div key=${elm._id}>${elm?.title?.[language]}</div>`;
              })) ||
            "N/A",
        },
        {
          _id: 3,
          label: consultantDetails?.psyChologicalProblems?.[language],
          data:
            (data &&
              data?.specialization?.psycological?.map((elm) => {
                return `<div key=${elm._id}>${elm?.title?.[language]}</div>`;
              })) ||
            "N/A",
        },
        {
          _id: 4,
          label: consultantDetails?.theraputicProblems?.[language],
          data:
            (data &&
              data?.specialization?.theraputic?.map((elm) => {
                return `<div key=${elm._id}>${elm?.title?.[language]}</div>`;
              })) ||
            "N/A",
        },
        {
          _id: 4,
          label: consultantDetails?.otherTopics?.[language],
          data:
            (data &&
              data?.specialization?.other?.map((elm) => {
                return `<div key=${elm._id}>${elm?.title?.[language]}</div>`;
              })) ||
            "N/A",
        },
      ],
    },
    serviceoffered: {
      title: consultantDetails?.serviceOffered?.[language],
      tableHeadData: [
        {
          _id: 1,
          name: consultantDetails?.serviceName?.[language],
          labelClass: "font-UnisonPro !font-bold italic text-base !text-blue",
          align: "left",
        },
        {
          _id: 2,
          name: consultantDetails?.cost?.[language],
          labelClass: "font-UnisonPro !font-bold italic text-base !text-blue",
          align: "left",
        },
        {
          _id: 3,
          name: consultantDetails?.mode?.[language],
          labelClass: "font-UnisonPro !font-bold italic text-base !text-blue",
          align: "left",
        },
        {
          _id: 4,
          name: consultantDetails?.duration?.[language],
          labelClass: "font-UnisonPro !font-bold italic text-base !text-blue",
          align: "left",
        },
      ],
      tableData:
        data &&
        data?.serviceDataTableMap &&
        Array.isArray(data?.serviceDataTableMap) &&
        data?.serviceDataTableMap.length > 0 &&
        data?.serviceDataTableMap.map((elm, index) => ({
          _id: elm?._id,
          rowData: [
            {
              _id: elm?.service?._id,
              type: "text",
              align: "center",
              cellClass: "!min-w-[100px] !py-6",
              data: elm?.service?.title?.[language] || "N/A",
            },
            {
              _id: index,
              type: "text",
              align: "center",
              cellClass: "!min-w-[100px] !py-6",
              data: `${data && data?.currencyData?.currency} ${
                elm?.price
              } / min`,
            },
            {
              _id: "03",
              type: "text",
              align: "center",
              cellClass: "!min-w-[100px] !py-6",
              data:
                elm?.mode &&
                Array.isArray(elm.mode) &&
                elm.mode.length > 0 &&
                elm.mode.map((item) => item.title?.[language]).join(", "),
            },
            {
              _id: "04",
              type: "text",
              align: "center",
              cellClass: "!min-w-[100px] !py-6",
              data: "60 min (Max)",
            },
          ],
        })),
    },
    reviews: {
      title: consultantDetails?.review?.[language],
    },
    actions: {
      call: {
        isLink: false,
        click: () => {},
        label: consultantDetails?.callNow?.[language],
      },
      booking: {
        isLink: false,
        click: () => {},
        label: consultantDetails?.bookConsult?.[language],
      },
      email: {
        isLink: true,
        link: `/email-details/${consultantDetails?.chatDetail?._id}`,
        label: consultantDetails?.emailMe?.[language],
      },
    },
    serviceDataTableMap: data?.serviceDataTableMap || [],
  };

  return (
    <div>
      <div className="relative flex shadow-md px-5 py-5 mt-3" key={elm?._id}>
        <div className="md:flex block items-start w-full xl:space-x-5 md:space-x-3 md:space-y-0 space-y-2">
          <div className="relative w-24 lg:w-28 xl:w-36 h-24 lg:h-28 xl:h-36 flex-shrink-0 rounded-full">
            <div
              className="w-full h-full overflow-hidden rounded-full cursor-pointer flex"
              onClick={() => navigate(`/consultant-profile/${elm?._id}`)}
            >
              <Image
                src={elm?.consultantData?.image?.url}
                alt={""}
                effect={"blur"}
                className={"w-full h-full object-cover"}
              />
            </div>

            {/*---------------Online Status-----------------*/}
            <div
              className={classNames(
                "absolute bottom-1 right-1 w-4 lg:w-6 xl:w-8 aspect-square rounded-full border-2 border-white",
                color?.[status]
              )}
            ></div>
            {/* -------------------------------------------------- */}
          </div>

          <div className="flex flex-wrap w-full md:justify-between items-center">
            <div>
              <div
                onClick={() => navigate(`/consultant-profile/${elm?._id}`)}
                className="xl:text-xl cursor-pointer text-lg whitespace-nowrap text-prontopsy-blue uppercase font-semibold"
              >
                {elm?.consultantData?.firstName +
                  " " +
                  elm?.consultantData?.lastName}
              </div>

              <div className="xl:text-sm text-xs text-slate-600">
                {elm?.consultantData?.email}
              </div>
              <div>
                <div className="flex items-center space-x-8 md:!mt-4 mt-2">
                  <div className="flex items-center space-x-2">
                    <div className="w-5 aspect-[4/3] overflow-hidden flex-shrink-0 flex">
                      <img
                        src={
                          flags[elm?.consultantData?.nationalityDetail?.iso2]
                        }
                        alt={"selected?.image"}
                        effect={"blur"}
                        className={"object-contain"}
                      />
                    </div>
                    <div className="text-sm !leading-none text-neutral-700">
                      {elm?.consultantData?.nationalityDetail?.iso3 || ""}
                    </div>
                  </div>
                  <div className="flex items-center space-x-2 text-xl !leading-none text-prontopsy-blue">
                    <i className="fa-regular fa-fw fa-language"></i>
                    <div className="text-sm !leading-none text-neutral-700">
                      <span>{elm?.consultantData?.languageDetail?.name}</span>
                    </div>
                  </div>
                </div>
              </div>

              {/* -------------------Calling Buttons--------------------- */}
              <div className="mt-2 flex gap-4">
                {elm?.consultantData?.serviceDataTableMap?.map(
                  (service, index) => {
                    const isDirectAccess =
                      service?.service?.slug ===
                      "direct-access-online-psychological-counseling";
                    const isScheduledCounseling =
                      service?.service?.slug ===
                      "scheduled-online-psychological-counselling";

                    if (isDirectAccess && status === "online") {
                      return (
                        <Button
                          key={`direct-access-${index}`}
                          buttonClasses="min-w-[200px] !rounded-md !bg-prontopsy-blue hover:!bg-prontopsy-green !text-[15px] !px-12 !h-8"
                          buttonLabel="Call Now"
                          buttonIconPosition={"left"}
                          buttonIcon={"fa-solid fa-phone"}
                          buttonFunction={() => {
                            !socket && window.location.reload();

                            setLoading(true);
                            getProfileDetails(token)
                              .then((response) => {
                                if (response?.data?.status === 200) {
                                  localStorage.setItem(
                                    "userDetails",
                                    JSON.stringify(response?.data?.docs)
                                  );
                                  if (
                                    response?.data?.docs?.onlineStatus ===
                                    "busy"
                                  ) {
                                    toast.error(
                                      "You are now busy with another call !"
                                    );
                                  } else {
                                    if (roleCode) {
                                      setIsCallingModalOpen(
                                        !isCallingModalOpen
                                      );
                                    } else {
                                      navigate("/signin");
                                    }
                                  }
                                  setLoading(false);
                                } else {
                                  setLoading(false);
                                  console.error(
                                    "Failed to retrieve profile details or invalid response status."
                                  );
                                }
                              })
                              .catch((error) => {
                                setLoading(false);
                                console.error(
                                  "An error occurred while fetching profile details:",
                                  error
                                );
                              });
                          }}
                          isDisable={loading}
                          loading={loading}
                        />
                      );
                    }

                    if (isScheduledCounseling) {
                      return (
                        <Button
                          key={`scheduled-counseling-${index}`}
                          buttonClasses="min-w-[200px] !rounded-md !bg-prontopsy-pink hover:!bg-prontopsy-blue !text-[15px] !px-12 !h-8"
                          buttonLabel="Book a Consult"
                          buttonFunction={() => {
                            if (roleCode) {
                              setIsOpen(!isOpen);
                            } else {
                              navigate("/signin");
                            }
                          }}
                        />
                      );
                    }
                    return null;
                  }
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="ml-auto text-sm flex justify-center items-center absolute top-0 right-0">
          <Button
            buttonIcon={"fa-solid fa-heart"}
            buttonClasses={"!text-red-600 !bg-transparent"}
            buttonIconPosition={"left"}
            buttonFunction={() => handleRemove(elm?._id)}
            isDisable={!removeLoaded}
          />
        </div>
      </div>
      <CallingModal
        isOpen={isCallingModalOpen}
        setIsOpen={setIsCallingModalOpen}
        data={consultantData}
        serviceId={selectedServiceId}
        setServiceId={setSelectedServiceId}
        modes={modeData}
      />
      <AppointmentModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        data={consultantData}
        serviceId={selectedServiceIdBooking}
        setServiceId={setSelectedServiceIdBooking}
        modes={modeDataBooking}
      />
    </div>
  );
};

export default WishlistItem;
