import React from "react";
import Button from "../../form/Button";

const PrivacyPolicy = ({ termsData }) => {
  return (
    <>
      <div className="relative w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto items-center text-2xl lg:pt-16 lg:pb-28 py-5">
        <div className="text-base text-slate-600 leading-snug pt-5 scroll-py-4 content">
          <div className="privacy-policy">
            <div
              dangerouslySetInnerHTML={{ __html: termsData?.description || "" }}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
