import React, { useEffect, useState } from "react";
import Image from "../../elements/Image";
import InputGroup from "../../form/Input";
import Checkbox from "../../form/Checkbox";
import Button from "../../form/Button";
import { toast } from "react-toastify";
import { signUpRequest, verifyOtpService } from "../../../service/authService";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import OtpField from "./OtpField";
import { resendOtp } from "../../../service/otpService";
import FacebookLoginComponent from "../../elements/FacebookLoginComponent";
import GoogleLoginComponent from "../../elements/GoogleLoginComponent";

const langConfig = {
  terms: {
    en: "I have read, understood and agree to the Prontopsy Terms and Conditons",
    es: "He leído, comprendido y acepto los Términos y condiciones de Prontopsy",
    it: "Ho letto, compreso e accetto i Termini e Condizioni di Prontopsy",
  },
  policy: {
    en: "I have read, understood and agree to the Prontopsy Data and Privacy Policy",
    es: "He leído, comprendido y acepto la Política de Privacidad y Datos de Prontopsy",
    it: "Ho letto, compreso e accetto i dati e l'informativa sulla privacy di Prontopsy",
  },
  already: {
    en: "Already have an account?",
    es: "Ya tengo una cuenta?",
    it: "Ho già un account?",
  },
  facebook: {
    en: "Continue with facebook",
    es: "Continúa con facebook",
    it: "Continua con facebook",
  },
  google: {
    en: "Continue with google",
    es: "Continúa con google",
    it: "Continua con google",
  },
};

const Register = ({ image, title, rolecode }) => {
  const regex =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{8,}$/;
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(true);
  const [timeZoneData, setTimeZoneData] = useState({
    zoneName: "",
    gmtOffsetName: "",
  });
  const [signupData, setSignupData] = useState({
    roleCode: rolecode,
  });
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [clicked, setClicked] = useState(false);
  const [isSuccess, setIsSucess] = useState(false);
  const lang = useSelector((state) => state?.languageSlice?.data);
  const language = localStorage.getItem("language") || lang;
  const [emailData, setEmailData] = useState({});
  const [otp, setOtp] = useState("");
  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  let validate = isValidEmail(signupData?.email);
  const [showResendButton, setShowResendButton] = useState(false);
  const [timer, setTimer] = useState(60);
  const [termsAndCondition, setTermsAndCondition] = useState(false);
  const [privecyPolicy, setPrivecyPolicy] = useState(false);

  useEffect(() => {
    const timeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const currentDate = new Date();
    const offsetMinutes = currentDate.getTimezoneOffset();
    const offsetHours = Math.floor(Math.abs(offsetMinutes) / 60);
    const offsetMinutesRemaining = Math.abs(offsetMinutes) % 60;
    const gmtOffsetName = `UTC${offsetMinutes <= 0 ? "+" : "-"}${String(
      offsetHours
    ).padStart(2, "0")}:${String(offsetMinutesRemaining).padStart(2, "0")}`;

    setTimeZoneData({
      zoneName: timeZoneName,
      gmtOffsetName: gmtOffsetName,
    });
  }, []);

  useEffect(() => {
    setSignupData((pre) => ({
      ...pre,
      timeZone: timeZoneData,
    }));
  }, [timeZoneData]);

  const handleData = (e) => {
    setSignupData((pre) => ({
      ...pre,
      [e.target.name]: e.target.value,
    }));
    setClicked(false);
  };

  const isBlank = (str) => {
    return str.trim() === "";
  };

  const handleSignup = () => {
    setClicked(true);

    if (!signupData.firstName || isBlank(signupData.firstName) === true) {
      toast.warning("Please enter your first name!", { autoClose: 3000 });
      return;
    }
    if (!signupData.lastName || isBlank(signupData.lastName) === true) {
      toast.warning("Please enter your last name!", { autoClose: 3000 });
      return;
    }
    if (!signupData.email || isValidEmail(signupData.email) === false) {
      toast.warning("Please enter your email!", { autoClose: 3000 });
      return;
    }
    if (!signupData.password || !regex.test(password)) {
      toast.warning("Please enter password!", { autoClose: 3000 });
      return;
    }
    if (
      (signupData.password && !signupData.confirmPassword) ||
      (confirmPassword && password !== confirmPassword)
    ) {
      toast.warning("Confirm Password is Required!", { autoClose: 3000 });
      return;
    }
    if (!termsAndCondition) {
      toast.warning("Please accept the terms and condition!", {
        autoClose: 3000,
      });
      return;
    }
    if (!privecyPolicy) {
      toast.warning("Please accept the privecy policy!", { autoClose: 3000 });
      return;
    }

    if (
      signupData.roleCode &&
      signupData.email &&
      signupData.password &&
      signupData.confirmPassword &&
      signupData.firstName &&
      signupData.lastName &&
      timeZoneData &&
      validate == true
    ) {
      signUpRequest(signupData, setLoaded).then((res) => {
        if (res.data.status === 200) {
          toast.success(res.data.message, { autoClose: 3000 });
          setEmailData({ email: signupData.email });
          setIsSucess(true);
          setShowResendButton(true);
        } else {
          toast.error(res.data.message, { autoClose: 4000 });
        }
      });
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSignup();
    }
  };

  const isPasswordInvalid = password && !regex.test(password);
  const isSignupDataPasswordEmpty = clicked && !signupData.password;

  const verifyOtpTitle = {
    en: "Verify OTP",
    es: "Verificar OTP",
    it: "Verificare l'OTP",
  };
  const verifyOtpSubTitle = {
    en: `Enter the six digit code send to ${signupData.email}`,
    es: `Ingrese el código de seis dígitos enviado a ${signupData.email}`,
    it: `Inserisci il codice a sei cifre per l'invio ${signupData.email}`,
  };

  useEffect(() => {
    let interval;
    if (showResendButton && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer === 1) {
            clearInterval(interval);
            setShowResendButton(false);
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [showResendButton, timer]);

  const onVerifyOtp = async (values) => {
    setLoaded(false);
    try {
      await verifyOtpService({
        emailOtp: otp,
        email: emailData?.email,
        requestType: "signup",
      }).then((res) => {
        if (res?.status === 200) {
          toast.success("OTP Verified Successfull", { autoClose: 3000 });
          navigate("/signin");
          setTimer(60);
        } else {
          toast.error(res?.data?.message, { autoClose: 3000 });
          setTimer(60);
          setOtp(null);
        }
      });
      setLoaded(true);
    } catch (error) {
      setLoaded(true);
      toast.error(error, { autoClose: 3000 });
    }
  };

  const onResendOtp = async (values) => {
    setLoaded(false);
    try {
      await resendOtp({
        email: emailData?.email,
      }).then((res) => {
        if (res?.status === 200) {
          toast.success("OTP Code Sent To Your Email Id.", { autoClose: 3000 });
          setEmailData({ email: signupData.email });
          setIsSucess(true);
          setShowResendButton(true);
          setOtp("");
          setTimer(60);
        } else {
          toast.error(res?.data?.message, { autoClose: 3000 });
        }
      });
      setLoaded(true);
    } catch (error) {
      setLoaded(true);
      toast.error(error, { autoClose: 3000 });
    }
  };

  return (
    <div className="relative py-10 md:py-12 lg:py-16 xl:py-20 ">
      <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto">
        <div className="block lg:flex shadow-2xl">
          <div className="w-full lg:w-1/2 p-10 hidden md:block">
            <div className="flex flex-col justify-center w-full aspect-[4/5] overflow-hidden">
              <Image
                src={image || ""}
                alt="Image"
                effect="blur"
                className="object-cover w-full h-full"
              />
            </div>
          </div>
          <div className="w-full lg:w-1/2">
            <div
              className={
                isSuccess
                  ? "bg-white px-5 py-5 h-full flex flex-col items-center justify-center"
                  : "bg-prontopsy-yellow px-5 py-5 h-full flex flex-col items-center justify-center"
              }
            >
              {isSuccess ? (
                <>
                  <h2
                    className="!mb-6 sm:!mb-8 md:!mb-10 lg:!mb-12 xl:!mb-16 text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl text-prontopsy-blue font-UnisonPro italic font-bold !leading-[1.1]"
                    dangerouslySetInnerHTML={{
                      __html: verifyOtpTitle?.[language],
                    }}
                  ></h2>
                  <h2
                    className="!mb-6 sm:!mb-8 md:!mb-10 lg:!mb-12 xl:!mb-16 text-md lg:text-lg xl:text-xl 2xl:text-sm text-prontopsy-yellow font-UnisonPro italic font-bold !leading-[1.1]"
                    dangerouslySetInnerHTML={{
                      __html: verifyOtpSubTitle?.[language],
                    }}
                  ></h2>
                  <div className="mt-2">
                    <OtpField length={6} handleOtpChange={(e) => setOtp(e)} />

                    <Button
                      buttonFunction={onVerifyOtp}
                      buttonLabel={" Submit"}
                      buttonClasses="w-full mt-4 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 "
                    ></Button>
                    <br />
                    <Button
                      buttonFunction={onResendOtp}
                      isDisable={!loaded ? true : showResendButton}
                      type="button"
                      buttonLabel={"Resend Otp"}
                      buttonClasses="w-full mt-1 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-prontopsy-green text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 "
                    ></Button>
                  </div>
                  {showResendButton && (
                    <div className="text-center text-red-700 mt-2">
                      Resend OTP in {timer} seconds
                    </div>
                  )}
                </>
              ) : (
                <>
                  {" "}
                  <div
                    className="w-full mx-auto max-w-lg text-center space-y-4"
                    onKeyDown={handleKeyDown}
                  >
                    <h2
                      className="!mb-6 sm:!mb-8 md:!mb-10 lg:!mb-12 xl:!mb-16 text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl text-prontopsy-blue font-UnisonPro italic font-bold !leading-[1.1]"
                      dangerouslySetInnerHTML={{ __html: title?.[language] }}
                    ></h2>

                    <div className="relative">
                      <InputGroup
                        onChange={handleData}
                        errorType={
                          clicked == true &&
                          (!signupData?.firstName ? "warning" : "")
                        }
                        errorText={
                          signupData?.firstName
                            ? ""
                            : "Enter a your first name!"
                        }
                        inputValue={signupData?.firstName}
                        inputName="firstName"
                        labelClasses="font-RobotoSlab"
                        inputPlaceholder="First Name"
                        inputType="firstName"
                        inputClasses="!text-black border-none placeholder:text-black h-10 md:h-12 lg:h-14 xl:h-16 rounded-none !bg-black/20"
                      />
                    </div>
                    <div className="relative">
                      <InputGroup
                        onChange={handleData}
                        errorType={
                          clicked == true &&
                          (!signupData?.lastName ? "warning" : "")
                        }
                        errorText={
                          signupData?.lastName ? "" : "Enter a your last name!"
                        }
                        inputValue={signupData?.lastName}
                        inputName="lastName"
                        labelClasses="font-RobotoSlab"
                        inputPlaceholder="Last Name"
                        inputType="lastName"
                        inputClasses="!text-black border-none placeholder:text-black h-10 md:h-12 lg:h-14 xl:h-16 rounded-none !bg-black/20"
                      />
                    </div>
                    <div className="relative">
                      <InputGroup
                        onChange={handleData}
                        errorType={
                          clicked == true &&
                          (!signupData.email
                            ? "warning"
                            : isValidEmail(signupData.email)
                            ? ""
                            : "danger")
                        }
                        errorText={
                          signupData.email && isValidEmail(signupData.email)
                            ? ""
                            : "Enter a valid email id!"
                        }
                        inputValue={signupData.email}
                        inputName="email"
                        labelClasses="font-RobotoSlab"
                        inputPlaceholder="Email"
                        inputType="email"
                        inputClasses="!text-black border-none placeholder:text-black h-10 md:h-12 lg:h-14 xl:h-16 rounded-none !bg-black/20"
                      />
                    </div>
                    <div className="relative">
                      <InputGroup
                        onChange={(e) => {
                          handleData(e);
                          setPassword(e.target.value);
                        }}
                        inputValue={signupData.password}
                        inputName="password"
                        labelClasses="font-RobotoSlab"
                        inputPlaceholder="Password"
                        inputType="password"
                        divClass="!border !border-black !bg-transparent"
                        inputClasses="!text-black border-none placeholder:text-black h-10 md:h-12 lg:h-14 xl:h-16 rounded-none !bg-black/20"
                        errorType={
                          isSignupDataPasswordEmpty
                            ? "warning"
                            : isPasswordInvalid
                            ? "warning"
                            : ""
                        }
                        errorText={
                          password && !regex.test(password)
                            ? "Minimum eight characters, at least one letter, one uppercase, one lowercase, one number and one special character"
                            : "Password is required!"
                        }
                      />
                    </div>
                    <div className="relative">
                      <InputGroup
                        inputName="confirmPassword"
                        onChange={(e) => {
                          handleData(e);
                          setConfirmPassword(e.target.value);
                        }}
                        inputValue={signupData.confirmPassword}
                        labelClasses="font-RobotoSlab"
                        inputPlaceholder="Confirm Password"
                        inputType="password"
                        divClass="!border !border-black !bg-transparent"
                        inputClasses="!text-black border-none placeholder:text-black h-10 md:h-12 lg:h-14 xl:h-16 rounded-none !bg-black/20"
                        errorType={
                          confirmPassword && password !== confirmPassword
                            ? "danger"
                            : ""
                        }
                        errorText={
                          (confirmPassword &&
                            password !== confirmPassword &&
                            "Confirm Password Does Not Match With Password!") ||
                          (clicked &&
                            !signupData.confirmPassword &&
                            "Confirm password is required!")
                        }
                        isDisabled={!signupData.password}
                      />
                    </div>
                    <div className="relative">
                      <Checkbox
                        checkboxLabel={langConfig?.terms?.[language]}
                        checkboxLabelClass="font-RobotoSlab !text-left !text-black !text-xs !capitalize"
                        onChange={(e) => setTermsAndCondition(e.target.checked)}
                      />
                    </div>
                    <div className="relative">
                      <Checkbox
                        checkboxLabel={langConfig?.policy?.[language]}
                        checkboxLabelClass="font-RobotoSlab !text-left !text-black !text-xs !capitalize"
                        onChange={(e) => setPrivecyPolicy(e.target.checked)}
                      />
                    </div>
                    <div className="flex justify-center">
                      <div className="relative">
                        <Button
                          buttonLabel="sign up"
                          buttonLabelClasses="!capitalize font-RobotoSlab"
                          buttonClasses="!h-10 lg:!h-12 !bg-prontopsy-blue !px-16"
                          buttonFunction={handleSignup}
                          isDisable={!loaded}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 py-5 border-t border-b border-neutral-400">
                      <div className="relative w-full flex justify-center">
                        <FacebookLoginComponent />
                      </div>
                      <div className="relative w-full flex justify-center">
                        <GoogleLoginComponent role={rolecode} />
                      </div>
                    </div>
                    <div className="flex justify-center">
                      <div className="text-xs text-black font-RobotoSlab capitalize">
                        {langConfig?.already?.[language]}{" "}
                        <Link
                          to="/signin"
                          className="text-xs text-black font-RobotoSlab capitalize underline"
                        >
                          Signin Now
                        </Link>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
