import React, { useState, useRef, useEffect, Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { usePopper } from "react-popper";
import { bottom, top, left, right } from "@popperjs/core";
import { classNames } from "../../helpers/classNames";
import Search from "../form/Search";
import flags from "../../helpers/showFlag";

const SkeletonLoader = ({ className, isVisible }) => (
  <div className={`animate-pulse bg-gray-200 ${className}`}></div>
);

const Select = ({
  xPlacement,
  placeholder,
  dropdownData = [],
  dropdownClass,
  dropdownButtonClass,
  selectedValue,
  label,
  labelClasses,
  errorType,
  errorText,
  isSearch,
  isEditList,
  setOptionsEditModal,
  setEditDataOption,
  handleSelect = () => {},
  loaded = true,
  isFlag = false,
  ...props
}) => {
  const [filteredData, setFilteredData] = useState(dropdownData || []);
  const [selected, setSelected] = useState(null);
  const popperElRef = useRef(null);
  const [targetElement, setTargetElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    if (!dropdownData || !Array.isArray(dropdownData)) {
      return;
    }

    if (typeof searchInput !== "string") {
      setFilteredData(dropdownData);
      return;
    }
    if (!searchInput) {
      setFilteredData(dropdownData);
      return;
    }
    const filtered =
      searchInput &&
      dropdownData.length > 0 &&
      dropdownData.filter(
        (elm) =>
          elm.name &&
          elm?.name
            .replace(/[\/_]/g, "")
            .toLowerCase()
            .includes(searchInput.trim().toLowerCase())
      );
    setFilteredData(filtered);
  }, [searchInput, dropdownData?.length]);

  useEffect(() => {
    selectedValue ? setSelected(selectedValue) : setSelected(null);
  }, [selectedValue, dropdownData]);

  useEffect(() => {
    setFilteredData(dropdownData);
  }, [dropdownData?.length]);

  const placements = {
    bottom: bottom,
    bottomLeft: "bottom-start",
    bottomRight: "bottom-end",
    top: top,
    topLeft: "top-start",
    topRight: "top-end",
    right: right,
    left: left,
    "": bottom,
  };

  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: placements[xPlacement],
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 2],
        },
      },
    ],
  });
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (selectedValue) {
      const index =
        dropdownData &&
        dropdownData.findIndex(
          (item) => item._id === selectedValue || item.value === selectedValue
        );
      if (index !== -1) {
        setSelected(dropdownData[index]);
      }
    }
  }, [dropdownData, selected, selectedValue]);

  return (
    <>
      <div className="relative space-y-1 w-full">
        {label && (
          <div
            className={classNames(
              "text-sm font-semibold text-primary-gray tracking-none",
              labelClasses
            )}
          >
            {label}
          </div>
        )}
        {loaded ? (
          <Listbox
            value={selected}
            placeholder={placeholder}
            onChange={(value) => {
              handleSelect(value);
              setSelected(value);
            }}
            as={"div"}
            className="relative"
          >
            {({ open }) => (
              <>
                <Listbox.Button
                  ref={setTargetElement}
                  className="flex items-center w-full"
                >
                  <div
                    className={classNames(
                      "relative w-full h-10 flex items-center justify-between focus:ring-0 transition-all duration-200 gap-1 pr-2 pl-3 bg-white border text-sm font-medium",
                      dropdownButtonClass,
                      open ? "border-primary-graySky" : "border-primary-border"
                    )}
                  >
                    {selected?.name ? (
                      <div className="flex items-cent rounded-mder w-11/12 justify-between">
                        {isFlag && (
                          <span className="flag">
                            <img
                              src={flags[selected?.image]}
                              alt={selected?.image}
                              className={
                                "d-none !w-8 !aspect-square overflow-hidden"
                              }
                            />
                          </span>
                        )}
                        <div className="text-primary-gray whitespace-nowrap truncate">
                          {selected?.name}
                        </div>

                        {selected?.name && (
                          <>
                            <button
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent triggering the Listbox
                                setSelected(null);
                                handleSelect(null);
                              }}
                              className="ml-2 text-gray-400 hover:text-gray-600"
                            >
                              <i className="fa-light fa-fw fa-times"></i>
                            </button>
                          </>
                        )}
                      </div>
                    ) : (
                      <div className="text-gray-950 !text-[14px] !font-normal">
                        {placeholder}
                      </div>
                    )}
                    <div
                      className={classNames(
                        "text-sm text-primary-graySky transition-all duration-200",
                        open ? "-rotate-180" : ""
                      )}
                    >
                      <i className={"fa-light fa-fw fa-chevron-down"}></i>
                    </div>
                  </div>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  className="z-50"
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                  beforeEnter={() => setPopperElement(popperElRef.current)}
                  afterLeave={() => setPopperElement(null)}
                  ref={popperElRef}
                  style={styles.popper}
                  {...attributes.popper}
                >
                  <Listbox.Options
                    className={classNames(
                      "absolute mt-1 border border-primary-border w-40 origin-top-right rounded-md bg-white shadow-lg focus:outline-none py-1",
                      dropdownClass
                    )}
                  >
                    {isSearch && (
                      <div className="py-2 px-3">
                        <Search
                          placeholder={"Search"}
                          onChange={(e) => setSearchInput(e.target.value)}
                        />
                      </div>
                    )}
                    <div className="py-1 max-h-80 overflow-auto scrollbar">
                      {filteredData &&
                        filteredData.map((item, index) => (
                          <Listbox.Option
                            key={index}
                            className={({ active }) =>
                              classNames(
                                "relative group flex w-full items-center px-3 py-1.5 pl-8 text-sm gap-2 transition-all duration-200 cursor-pointer",
                                active
                                  ? "bg-primary-veryLightBlue text-primary-blue"
                                  : "text-primary-grayB",
                                item.isDisabled
                                  ? "!bg-primary-silver !cursor-not-allowed"
                                  : "",
                                item.class
                              )
                            }
                            value={item}
                            disabled={item.isDisabled}
                          >
                            {({ selected }) => (
                              <>
                                {item.icon && (
                                  <i
                                    className={classNames("fa-fw", item.icon)}
                                  ></i>
                                )}
                                {isFlag && (
                                  <span className="flag">
                                    <img
                                      src={flags[item?.image]}
                                      alt={item?.image}
                                      className={
                                        "d-none !w-5 !aspect-square !overflow-hidden"
                                      }
                                    />
                                  </span>
                                )}

                                <div>{item.name}</div>
                                {selected && (
                                  <div className="absolute inset-y-0 left-0 flex items-center pl-2 text-primary-blue text-base">
                                    <i className="fa-regular fa-fw fa-check"></i>
                                  </div>
                                )}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                    </div>
                  </Listbox.Options>
                </Transition>
              </>
            )}
          </Listbox>
        ) : (
          <SkeletonLoader className="w-full h-10 rounded-md" />
        )}

        {errorType && errorType === "error" && (
          <div className="text-xs text-red-600 mt-1">
            <i className="fa-regular fa-fw fa-square-exclamation text-sm mr-1"></i>
            {errorText}
          </div>
        )}
        {errorType && errorType === "warning" && (
          <div className="text-xs text-amber-600 mt-1">
            <i className="fa-regular fa-fw fa-triangle-exclamation text-sm mr-1"></i>
            {errorText}
          </div>
        )}
        {errorType && errorType === "success" && (
          <div className="text-xs text-green-600 mt-1">
            <i className="fa-regular fa-fw fa-circle-check text-sm mr-1"></i>
            {errorText}
          </div>
        )}
        {errorType && errorType === "info" && (
          <div className="text-xs text-sky-600 mt-1">
            <i className="fa-regular fa-fw fa-circle-info text-sm mr-1"></i>
            {errorText}
          </div>
        )}
      </div>
    </>
  );
};

export default Select;
