import React, { useEffect, useState } from "react";
import GetStartedItem from "../../single/GetStartedItem";
import { fetchStartedData } from "../../../service/startedService";
import { useSelector } from "react-redux";

const GetStarted = () => {
  const [data, setData] = useState([]);
  const [loaded, setLoaded] = useState(true);
  const [startedData, setStartedData] = useState({});
  const lang = useSelector((state) => state?.languageSlice?.data);
  const language = localStorage.getItem("language") || lang;
  const pages = useSelector((state) => state?.pageSlice?.data);
  useEffect(() => {
    let info =
      pages.length > 0 &&
      pages.filter((elm) => elm?.title?.en == "Home_started");
    setStartedData(info[0]);
  }, [pages]);

  useEffect(() => {
    fetchStartedData(setData, setLoaded);
  }, []);

  let info =
    data.length > 0 &&
    data.map((elm, index) => ({
      _id: elm?._id,
      icon: elm?.image?.url,
      styleType: index + 1,
      name: elm?.title?.[language],
      description: elm?.description?.[language],
    }));

  const getStartedData = {
    title: startedData && startedData?.subTitle?.[language],
    subtitle: startedData && startedData?.description?.[language],
    getStarted: info,
  };
  return (
    <>
      <section className="relative py-10 md:py-12 lg:py-16 xl:py-20 2xl:py-24 flex items-center">
        <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto">
          <div className="w-full mx-auto max-w-2xl text-center">
            {getStartedData?.title && (
              <h2
                className="text-2xl lg:text-3xl xl:text-4xl 2xl:text-[45px] text-prontopsy-blue font-UnisonPro italic font-bold !leading-[1.1]"
                dangerouslySetInnerHTML={{ __html: getStartedData?.title }}
              ></h2>
            )}
            {getStartedData?.subtitle && (
              <div
                className="text-base lg:text-[17px] text-neutral-700 space-y-4 mt-2 md:mt-4 lg:mt-6 max-w-2xl mx-auto"
                dangerouslySetInnerHTML={{ __html: getStartedData?.subtitle }}
              ></div>
            )}
          </div>
          {getStartedData?.getStarted?.length > 0 && (
            <div className="grid grid-cols-1 xl:grid-cols-3 gap-2 mt-16 pb-12">
              {getStartedData?.getStarted?.map((item, index) => (
                <GetStartedItem key={index} data={item} />
              ))}
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default GetStarted;
