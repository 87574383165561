import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSocket } from "../socket/SocketProvider";
import Modal from "./Modal";
import { appointmentCancel } from "../../service/videoCallService";

const JoinCallConfirmationModal = ({ isOpen, setIsOpen, callerData }) => {
  const profileData = JSON.parse(localStorage.getItem("userDetails"));
  const [consultantJoined, setConsultantJoined] = useState(false);
  const navigate = useNavigate();
  const socket = useSocket();
  const appointmentData = JSON.parse(localStorage.getItem("appointmentData"));

  useSocket("consultantJoinResponse", async (data) => {
    if (
      profileData &&
      data?.joined === true &&
      data?.data?.user?.id === profileData?._id &&
      data?.appointmentId
    ) {
      // localStorage.setItem("joined", true);
      setConsultantJoined(true);
      setIsOpen(false);
      navigate(`/chats/${data?.appointmentId}`);
    }
  });

  const handleJoinLater = useCallback(() => {
    setIsOpen(false);

    appointmentCancel({ appointmentId: appointmentData?.appointmentId });

    if (socket) {
      socket.emit("vonageSessionReject", {
        disconnect: true,
        appointmentId: appointmentData?.appointmentId,
        role: profileData?.roleCode,
        consultantId: appointmentData?.consultantId,
        userId: appointmentData?.callerData?.user?.id,
      });

      socket.emit("consultantBusy", {
        consultantId: appointmentData?.consultantId,
        userId: appointmentData?.callerData?.user?.id,
        isBusy: false,
      });
    }

    localStorage.removeItem("appointmentData");
    localStorage.removeItem("joined");
  }, [appointmentData]);

  // const handleJoinLater = () => {
  //   setIsOpen(false);
  //   appointmentCancel({ appointmentId: appointmentData?.appointmentId });
  //   socket &&
  //     socket.emit("vonageSessionReject", {
  //       disconnect: true,
  //       appointmentId: appointmentData?.appointmentId,
  //       role: profileData?.roleCode,
  //       consultantId: appointmentData?.consultantId,
  //       userId: appointmentData?.callerData?.user?.id,
  //     });
  //   socket &&
  //     socket.emit("consultantBusy", {
  //       consultantId: appointmentData?.consultantId,
  //       userId: appointmentData?.callerData?.user?.id,
  //       isBusy: false,
  //     });
  //   localStorage.removeItem("appointmentData");
  //   localStorage.removeItem("joined");
  // };

  useSocket("vonageSessionRejectResponse", async (data) => {
    if (data?.disconnect === true) {
      setIsOpen(false);
      localStorage.removeItem("joined");
      localStorage.removeItem("appointmentData");
    }
  });

  return (
    <Modal
      bodyClass={"!bg-white"}
      isOpen={isOpen}
      titleShow={false}
      size={"lg"}
    >
      <div className="flex flex-col justify-center items-center h-full p-6 ">
        <div className="flex items-center justify-center space-x-3  px-8 py-3 rounded-lg">
          <div className="flex items-center justify-center space-x-8 ">
            <div className="flex flex-col items-center justify-center">
              <img
                crossOrigin="anonymous"
                src={appointmentData?.callerData?.consultant?.image}
                // src={profileData?.image?.url}
                alt={""}
                effect={"blur"}
                className={
                  "!w-32 !h-32 object-cover rounded-full mb-2 border p-1"
                }
              />

              <div className="text-xl font-semibold  text-prontopsy-pink uppercase font-RobotoSlab">
                {appointmentData?.callerData?.consultant?.name}
                {/* {profileData?.firstName + " " + profileData?.lastName} */}
              </div>
            </div>

            {/* {consultantJoined == true && (
              <div className="flex flex-col items-center justify-center">
                <img
                  crossOrigin="anonymous"
                  src={callerData?.consultant?.image}
                  alt={""}
                  effect={"blur"}
                  className={
                    "!w-32 !h-32 object-cover rounded-full mb-2 border p-1"
                  }
                />
                <div className="text-2xl font-semibold  text-prontopsy-pink font-UnisonPro">
                  hsdjcfhuj
                </div>
              </div>
            )} */}
          </div>
        </div>
        {appointmentData?.appointmentId && (
          <div className="text-center pb-3">
            <p className="text-lg text-center">
              Your{" "}
              <span className="text-xl text-prontopsy-dodgerBlue font-bold capitalize">
                {appointmentData?.mode}
              </span>{" "}
              consult with{" "}
              <span className="text-xl font-bold text-prontopsy-pink">
                {appointmentData?.callerData?.consultant?.name}
              </span>{" "}
              is about to begin. Please hold on while he connects to the call.
            </p>
          </div>
        )}
        <div className="flex space-x-6 text-xl">
          <button
            onClick={handleJoinLater}
            className="bg-red-500 flex gap-4 items-center  text-white px-12 py-4 rounded-lg shadow-md hover:bg-red-600 transition duration-300 ease-in-out"
          >
            <i class="fa-solid fa-xmark"></i>
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default JoinCallConfirmationModal;
