import React, { useCallback, useEffect, useState } from "react";
import Button from "../form/Button";
import { NavLink, useNavigate } from "react-router-dom";
import Modal from "../elements/Modal";
import { JoinCallModal } from "../elements/JoinCallModal";
import TextAreaAutoSize from "../form/TextareaAutoSize";
import { useDispatch, useSelector } from "react-redux";
import { JoinChatModal } from "../elements/JoinChatModal";
import { setSelectedAppointment } from "../../store/slice/appointmentSlice";
import { getProfileDetails } from "../../service/authService";
import { toast } from "react-toastify";
import { generateVonageToken } from "../../service/appointmentService";
import { useSocket } from "../socket/SocketProvider";
import JoinCallConfirmationModal from "../elements/JoinCallConfirmationModal";
import { apiRequest } from "../../util/async/apiUtils";
import Cookies from "js-cookie";
import moment from "moment";

const languageConfig = {
  placeHolder: {
    en: "Write your note here ..",
    es: "Escribe tu nota aquí..",
    it: "Scrivi la tua nota qui..",
  },
  titleLang: {
    en: "Write Note",
    it: "Scrivi nota",
    es: "Escribir nota",
  },
  save: {
    en: "Save Note",
    it: "Salva nota",
    es: "Guardar nota",
  },
  errorMsg: {
    en: "You cannot delete this appointment now. The appointment start time must be more than 24 hours ahead of the current time.",
    it: "Non puoi eliminare questo appuntamento adesso. L'ora di inizio dell'appuntamento deve essere più di 24 ore avanti rispetto all'ora corrente.",
    es: "No puede eliminar esta cita ahora. La hora de inicio de la cita debe ser más de 24 horas anterior a la hora actual.",
  },
};

const AppointmentItem = ({
  data,
  noteInput,
  setNoteInput = () => {},
  handleSubmit = () => {},
  isClose,
  setIsClose = () => {},
  loaded = true,
  setIsDeleteModalOpen = () => {},
  setSelectedId,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [joinModal, setJoinModal] = useState(false);
  const [isChatModalOpen, setIsChatModalOpen] = useState(false);
  const lang = useSelector((state) => state?.languageSlice?.data);
  const language = localStorage.getItem("language") || lang;
  const callData = useSelector((state) => state?.callingSlice?.data);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const token = localStorage.getItem("auth_token") || Cookies.get("auth_token");
  const [loading, setLoading] = useState(false);
  const [generateTokenLoading, setGenerateTokenLoading] = useState(false);
  const socket = useSocket();
  const [callConfirmModal, setCallConfirmModal] = useState(false);
  const [perticipants, setPerticipants] = useState(null);

  const callerData = {
    ...callData,
    mode: data?.mode,
    appointmentId: data?.id,
    consultantId: data?.clientData?._id,
    appointmentStartTime: data?.details?.appointmentStartTime,
  };

  useEffect(() => {
    isClose && setIsModalOpen(false);
  }, [isClose]);

  const openModal = (data) => {
    localStorage.setItem("selectedAppointment", data?.id);
    dispatch(setSelectedAppointment(data));
    setNoteInput(data?.notes?.message);
    setIsModalOpen(true);
    setIsClose(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    localStorage.removeItem("selectedAppointment");
    setIsClose(true);
    dispatch(setSelectedAppointment(null));
  };

  const handleGenerateToken = (id) => {
    setGenerateTokenLoading(true);
    generateVonageToken({ id: id })
      .then((res) => {
        if (res?.data?.status === 200) {
          setGenerateTokenLoading(false);
          if (res?.data?.data?._id) {
            setPerticipants({
              clientId: res?.data?.data?.clientId,
              consultantId: res?.data?.data?.consultantId,
            });

            localStorage.setItem(
              "appointmentData",
              JSON.stringify(res?.data?.data)
            );

            if (res?.data?.data?.waitingArray?.length > 0) {
              socket &&
                socket.emit("indirectAppointmentInt", {
                  userId: res?.data?.data?.clientId,
                  consultantId: res?.data?.data?.consultantId,
                  appointmentId: res?.data?.data?._id,
                });

              if (res?.data?.data?.service?.mode?.title?.en === "chat") {
                socket &&
                  socket.emit("indirectAppointmentConfirmJoin", {
                    userId: userDetails?._id,
                    appointmentId: res?.data?.data?._id,
                    mode: res?.data?.data?.service?.mode?.title?.en,
                    data: {
                      clientId: res?.data?.data?.clientId,
                      consultantId: res?.data?.data?.consultantId,
                    },
                  });
                navigate(`/text-chat/${res?.data?.data?._id}`);
              } else {
                socket &&
                  socket.emit("indirectAppointmentConfirmJoin", {
                    userId: userDetails?._id,
                    appointmentId: res?.data?.data?._id,
                    mode: res?.data?.data?.service?.mode?.title?.en,
                    data: {
                      clientId: res?.data?.data?.clientId,
                      consultantId: res?.data?.data?.consultantId,
                    },
                  });
                navigate(`/chats/${res?.data?.data?._id}`);
              }
            } else {
              socket &&
                socket.emit("waitingLobby", {
                  userId: userDetails?._id,
                  appointmentId: res?.data?.data?._id,
                  mode: res?.data?.data?.service?.mode?.title?.en,
                });
              setTimeout(() => {
                navigate(`/waiting/${res?.data?.data?._id}`);
              }, 1000);
            }
          } else {
            toast.info("This appointment already is in progress!", {
              autoClose: 2000,
            });
            navigate("/appointments");
          }
        }
        if (res?.data?.status === 403) {
          setGenerateTokenLoading(false);
          toast.error(res?.data?.message, { autoClose: 4000 });
        }
      })
      .catch((err) => {
        setGenerateTokenLoading(false);
        console.log(err);
      });
  };

  const authenticateAppointment = useCallback(
    async (id) => {
      setGenerateTokenLoading(true);
      try {
        const res = await apiRequest(
          "post",
          "appointment/authenticate-appointment",
          {
            body: { appointmentId: id },
          }
        );

        if (
          [401, 403, 404].includes(res?.status) ||
          res?.data?.data?.appointmentStatus === "close"
        ) {
          toast.error("Appointment closed or not found.");
        } else {
          handleGenerateToken(id);
        }
      } catch (error) {
        console.error("Error verifying token:", error);
        toast.error(error?.data?.message, { autoClose: 3000 });
      } finally {
        setGenerateTokenLoading(false);
      }
    },
    [data]
  );

  const user = data?.appointmentData?.clientData;
  const consultant = data?.appointmentData?.consultantData;
  const callerDataa = {
    user: {
      name: user?.firstName + " " + user?.lastName,
      email: user?.email,
      image: user?.image?.url,
      id: user?._id,
    },
    consultant: {
      name: consultant?.firstName + " " + consultant?.lastName,
      email: consultant?.email,
      image: consultant?.image?.url,
      id: consultant?._id,
    },
    mode: data?.appointmenData?.service?.mode?.slug,
    appointmentId: data?.id,
  };

  const isAppointmentDue = (appointmentDate, appointmentStartTime) => {
    const currentDate = moment();
    const appointmentDay = moment(appointmentDate);
    const appointmentTime = moment(appointmentStartTime);

    if (appointmentDay.isAfter(currentDate, "day")) {
      return true;
    }
    if (appointmentDay.isSame(currentDate, "day")) {
      return appointmentTime.isSameOrAfter(currentDate);
    }
    return false;
  };

  const isTimeCome =
    data &&
    isAppointmentDue(
      data?.appointmentDate,
      data?.appointmentData?.details?.appointmentStartTime
    );

  return (
    <div className="relative">
      <div className="block lg:flex justify-between gap-5 md:py-1 space-y-5 lg:space-y-0 md:border-0 border border-slate-200 md:px-0 px-4 py-4 rounded-md">
        <NavLink
          className="md:flex block items-center gap-5 flex-shrink md:space-y-0 space-y-4"
          to={data.status == "close" && `/appointments-details/${data?.id}`}
        >
          <div className="rounded-2xl bg-prontopsy-blue py-2 flex flex-col items-center justify-center md:w-32 w-24 flex-shrink-0">
            <div className="text-white md:text-[32px] text-[22px] font-bold font-RobotoSlab">
              {data.date}
            </div>
            <div className="text-white md:text-base text-sm font-bold font-RobotoSlab">
              {data.month}
            </div>
            <div className="text-white md:text-sm text-xs font-normal font-RobotoSlab">
              {data.time}
            </div>
            <div className="text-white md:text-sm text-xs font-normal font-RobotoSlab">
              {data.timeZone}
            </div>
          </div>
          <div className="flex-shrink space-y-2">
            <div className="text-xs !text-prontopsy-blue font-bold italic font-UnisonPro uppercase">
              {data.note}
            </div>
            <div
              className="text-slate-600 font-bold font-RobotoSlab text-xs uppercase !leading-relaxed"
              dangerouslySetInnerHTML={{ __html: data.paymentInfo }}
            ></div>
          </div>
        </NavLink>
        {/* Add Note */}

        <div className="flex lg:justify-between items-center gap-3 flex-shrink-0 justify-end">
          {(data?.status == "active" || data?.status == "close") && (
            <>
              <div className="relative">
                <Button
                  buttonLabel={
                    data?.notes?.message ? "NOTE" : data.buttonTextPink
                  }
                  buttonLabelClasses={
                    "!uppercase !text-" +
                    data.buttonColor +
                    " !text-base !whitespace-nowrap !font-medium"
                  }
                  buttonIconPosition={"left"}
                  buttonIcon={data.buttonIcon}
                  buttonClasses={
                    data?.notes?.message
                      ? "!bg-prontopsy-pink !rounded-xl !px-6 !h-12 !text-white"
                      : "!bg-transparent !rounded-xl !px-2 !h-12 !text-prontopsy-pink !border-2 !border-prontopsy-pink"
                  }
                  buttonFunction={() => {
                    openModal(data);
                  }}
                />
              </div>
            </>
          )}
          {data?.status == "active" && (
            <>
              <div className="relative">
                <Button
                  buttonLabel={data.buttonTextBlue}
                  buttonLabelClasses={
                    "!uppercase !text-base !whitespace-nowrap !font-medium"
                  }
                  buttonClasses={
                    isTimeCome
                      ? "!bg-transparent !rounded-xl !px-2 !h-12 !text-prontopsy-pink !border-2 !border-prontopsy-pink"
                      : "!bg-prontopsy-pink !rounded-xl !px-2 !h-12 !text-white"
                  }
                  buttonFunction={() => authenticateAppointment(data?.id)}
                  loading={generateTokenLoading}
                  isDisable={generateTokenLoading}
                  buttonIconPosition={"left"}
                  buttonIcon={"fa-solid fa-phone"}
                />
              </div>
            </>
          )}
          {data?.status == "inProgress" && data.isTrue && (
            <div className="relative">
              <Button
                buttonLabel={data.buttonTextBlue}
                buttonLabelClasses={
                  "!uppercase !text-white !text-base !whitespace-nowrap !font-medium"
                }
                buttonClasses={
                  "!bg-prontopsy-green !rounded-xl !px-6 !h-12 !text-white animate-pulse"
                }
                buttonFunction={async () => {
                  try {
                    setLoading(true);

                    const response = await getProfileDetails(token);
                    const { status, docs } = response?.data || {};
                    const appointmentData = docs?.appointmentData;
                    const userId = userDetails?._id;
                    const profileData = response?.data?.docs;

                    if (status !== 200 || !appointmentData) {
                      throw new Error(
                        "Invalid response status or appointment data."
                      );
                    }

                    const appointmentId = appointmentData?._id;
                    const mode = appointmentData?.service?.mode?.slug;
                    const targetPath =
                      mode === "chat"
                        ? `/text-chat/${appointmentId}`
                        : `/chats/${appointmentId}`;

                    if (!appointmentId) {
                      toast.error("Invalid appointment ID.");
                      return navigate("/");
                    } else {
                      const appointment = {
                        appointmentId: profileData?.appointmentData?._id,
                        appointmentStartTime:
                          profileData?.appointmentData?.consultantJoinTime ||
                          Date.now(),
                        callerData: {
                          consultant: {
                            id: profileData?.appointmentData?.consultantId,
                            image:
                              profileData?.appointmentData?.consultantDetails
                                ?.image,
                            name: `${profileData?.appointmentData?.consultantDetails?.firstName} ${profileData?.appointmentData?.consultantDetails?.lastName}`,
                          },
                          user: {
                            id: profileData?.appointmentData?.clientId,
                            image:
                              profileData?.appointmentData?.clientDetails
                                ?.image,
                            name: `${profileData?.appointmentData?.clientDetails?.firstName} ${profileData?.appointmentData?.clientDetails?.lastName}`,
                          },
                        },
                        mode: profileData?.appointmentData?.service?.mode?.slug,
                        paymentMode: profileData?.appointmentData?.paymentMode,
                        sessionId: profileData?.appointmentData?.sessionId,
                        token: profileData?.appointmentData?.sessionToken,
                      };

                      localStorage.setItem(
                        "appointmentData",
                        JSON.stringify(appointment)
                      );
                    }

                    socket &&
                      socket.emit("indirectAppointmentConfirmJoin", {
                        userId: userDetails?._id,
                        appointmentId: appointmentId,
                        mode: mode,
                        joined: true,
                        data: {
                          clientId: perticipants?.clientId,
                          consultantId: perticipants?.consultantId,
                        },
                      });

                    if (
                      appointmentData?.service?.service?.slug !==
                      "direct-access-online-psychological-counseling"
                    ) {
                      socket &&
                        socket.emit("indirectAppointmentConfirmJoin", {
                          userId: userDetails?._id,
                          appointmentId: appointmentId,
                          mode: mode,
                          joined: true,
                          data: {
                            clientId: perticipants?.clientId,
                            consultantId: perticipants?.consultantId,
                          },
                        });
                    }

                    window.location.href = targetPath;
                  } catch (error) {
                    console.error("An error occurred:", error.message || error);
                    toast.error(
                      "An error occurred while fetching profile details."
                    );
                  } finally {
                    setLoading(false);
                  }
                }}
                isDisable={loading}
                loading={loading}
              />
            </div>
          )}
          {/* Delete button */}
          {data?.status == "active" && (
            <>
              <div className="relative">
                <Button
                  buttonLabelClasses={
                    "!uppercase !text-" +
                    data.buttonColor +
                    " !text-base !whitespace-nowrap !font-medium"
                  }
                  buttonIconPosition={"left"}
                  buttonIcon={"fa-solid fa-trash"}
                  buttonClasses={
                    data?.notes?.message
                      ? "!bg-prontopsy-pink !rounded-xl !px-6 !h-12 !text-white"
                      : "!bg-transparent !rounded-xl !px-2 !h-12 !text-prontopsy-red !border-2 !border-prontopsy-pink"
                  }
                  buttonFunction={() => {
                    setIsDeleteModalOpen(true);
                    setSelectedId(data?.id);
                    // if (
                    //   data?.paymentData?.authorization_response === null ||
                    //   data?.sessionId === null
                    // ) {
                    //   setIsDeleteModalOpen(true);
                    // } else {
                    //   toast.error(languageConfig?.errorMsg?.[language], {
                    //     autoClose: 6000,
                    //   });
                    // }
                  }}
                />
              </div>
            </>
          )}
        </div>
      </div>

      <Modal isOpen={isModalOpen} titleShow={false}>
        <div className="relative mt-2">
          <div className="absolute right-0 -top-6">
            <Button
              buttonLabel={""}
              buttonLabelClasses={
                "!uppercase !text-" + data &&
                data?.buttonColor +
                  " !text-base !whitespace-nowrap !font-medium"
              }
              buttonIconPosition={"left"}
              buttonIcon={"fa-regular fa-xmark"}
              buttonClasses={
                "!bg-transparent  !px-0 !py-0 !h-4 !text-prontopsy-pink  !text-2xl mt-2  "
              }
              buttonFunction={closeModal}
            />
          </div>

          <div className="col-span-12 lg:col-span-6">
            <div className="relative">
              <TextAreaAutoSize
                label={`*${languageConfig?.titleLang?.[language]}`}
                iconClasses={"hidden"}
                inputPlaceholder={languageConfig?.placeHolder?.[language]}
                inputType={Text}
                divClasses={"h-12 bg-transparent !border-black rounded-none"}
                labelClasses={
                  "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab"
                }
                inputClasses={"!bg-transparent !h-12"}
                inputName={"name"}
                onChange={(e) => setNoteInput(e.target.value)}
                inputValue={noteInput}
              />
            </div>

            <div className="relative w-full mt-3">
              <Button
                buttonLabel={languageConfig?.save?.[language]}
                buttonLabelClasses={
                  "!uppercase !text-" + data &&
                  data?.buttonColor +
                    " !text-base !whitespace-nowrap !font-medium"
                }
                buttonClasses={
                  "!py-5 !px-6 !h-5 !text-white  !text-xl  !bg-prontopsy-pink text-white "
                }
                buttonFunction={() => handleSubmit()}
                isDisable={!loaded}
              />
            </div>
          </div>
        </div>
      </Modal>

      <JoinCallModal
        data={{
          ...data?.clientData,
          name: data?.clientData?.firstName + " " + data?.clientData?.lastName,
        }}
        sessionId={data?.sessionId}
        sessionToken={data?.sessionToken}
        callerData={callerData}
        isOpen={joinModal}
        setIsOpen={setJoinModal}
        modalTitle={"Join call"}
      />
      <JoinChatModal
        isOpen={isChatModalOpen}
        setIsOpen={setIsChatModalOpen}
        data={data?.clientData}
        callerData={callerData}
        from="appointment"
      />
      <JoinCallConfirmationModal
        isOpen={callConfirmModal}
        setIsOpen={setCallConfirmModal}
        callerData={callerDataa}
      />
      {/* <DeleteModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onDelete={() => handleDelete(selectedId)}
        loaded={deleteLoaded}
      /> */}
    </div>
  );
};

export default AppointmentItem;
