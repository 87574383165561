import React, { useEffect, useRef, useState } from "react";
import {
  fetchWishlistedData,
  mankeFavoriteService,
} from "../../service/consultantService";
import { useSelector } from "react-redux";
import SpinLoader from "../../components/elements/table/SpinLoader";
import { toast } from "react-toastify";
import NoDataFound from "../../components/elements/NoDataFound";
import WishlistItem from "../../components/single/WishlistItem";
import Cookies from "js-cookie";

const WishList = () => {
  const [list, setList] = useState([]);
  const [loaded, setLoaded] = useState(true);
  const [removeLoaded, setRemoveLoaded] = useState(true);
  const token = localStorage.getItem("auth_token") || Cookies.get("auth_token");
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const lang = useSelector((state) => state?.languageSlice?.data);

  //Pagination
  const [limit, setLimit] = useState(15);
  const [totalDocs, setTotalDocs] = useState(null);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [page, setPage] = useState(1);
  const [allList, setAllList] = useState([]);

  const [scrolledToBottom, setScrolledToBottom] = useState(false);
  const scrollableRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [isCallingModalOpen, setIsCallingModalOpen] = useState(false);
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const [modeData, setmodeData] = useState([]);
  const [data, setDetails] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollableRef.current) {
        const { scrollHeight, scrollTop, clientHeight } = scrollableRef.current;
        const scrolledToBottom = scrollTop + clientHeight >= scrollHeight;

        setScrolledToBottom(scrolledToBottom);
      }
    };

    const scrollableElement = scrollableRef.current;
    if (scrollableElement) {
      scrollableElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollableElement) {
        scrollableElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [allList]);

  useEffect(() => {
    fetchWishlistedData(
      token,
      { userId: userDetails?._id, limit: limit, page: page },
      setList,
      setLoaded,
      setHasNextPage
    ).then((res) => {
      if (res?.data?.docs?.status === 200) {
        let metaData = res?.data?.docs?.metadata;
        setTotalDocs(metaData?.totalDocs);
        page !== 1
          ? setAllList((prevList) => [...prevList, res.data.docs.data])
          : setAllList(res.data.docs.data);
      }
    });
  }, [page]);
  useEffect(() => {
    if (scrolledToBottom && hasNextPage && loaded) {
      setPage((pre) => pre + 1);
    }
  }, [scrolledToBottom, hasNextPage, loaded]);

  const handleRemove = (id) => {
    mankeFavoriteService({ consultantId: id }, token, setRemoveLoaded).then(
      (res) => {
        if (res?.data?.status == 200) {
          toast.success("Item removed successfully!");
          let data = allList.filter((elm) => elm?._id !== id);
          setAllList(data);
        }
      }
    );
  };

  return (
    <div>
      {allList.length === 0 && !loaded ? (
        <SpinLoader />
      ) : (
        <div
          ref={scrollableRef}
          className="h-[80vh] xl:p-10 p-5 shadow-md overflow-auto"
        >
          {allList &&
            Array.isArray(allList) &&
            allList?.length > 0 &&
            allList?.map((elm, section, index) => (
              <WishlistItem
                key={elm?._id}
                elm={elm}
                handleRemove={handleRemove}
                removeLoaded={removeLoaded}
                setmodeData={setmodeData}
                setSelectedServiceId={setSelectedServiceId}
                setIsCallingModalOpen={setIsCallingModalOpen}
                isCallingModalOpen={isCallingModalOpen}
                setDetails={setDetails}
                setLoaded={setLoaded}
              />
            ))}
          {allList.length == 0 && <NoDataFound />}
          {!loaded && (
            <div className="flex justify-center mt-4">
              <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-prontopsy-blue"></div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default WishList;
