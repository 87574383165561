import React, { useEffect, useState } from "react";
import User from "../../../../../assets/images/user-03.webp";
import Button from "../../../../form/Button";
import InputGroup from "../../../../form/Input";
import Select from "../../../../form/Select";
import { commonFormLabels } from "../../../language";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCountryList,
  updateProfileService,
} from "../../../../../service/profileService";
import { toast } from "react-toastify";
import { setProfileData } from "../../../../../store/slice/profileSlice";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const BillingInfo = ({ data, dataLoaded, setData = () => {} }) => {
  const [formData, setFormData] = useState({});
  const lang = useSelector((state) => state?.languageSlice?.data);
  const language = localStorage.getItem("language") || lang;
  const profileData = useSelector((state) => state?.profileSlice?.data);
  const [countryList, setCountryList] = useState([]);
  const [countryLoaded, setCountryLoaded] = useState(true);
  const [loaded, setLoaded] = useState(true);
  const token = localStorage.getItem("auth_token") || Cookies.get("auth_token");
  const BillingInfo = {
    title: {
      en: "Billing Information",
      es: "Información De Facturación",
      it: "Informazioni Di Fatturazione",
    },
    image: User,
    saveChanges: {
      en: "Save Changes",
      es: "Guardar Cambios",
      it: "Salva Cambiamenti",
    },
  };

  const role = profileData && profileData?.roleCode;
  const id = profileData && profileData?._id;
  const dispatch = useDispatch();
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate("/");
      localStorage.clear();
      Cookies.remove("auth_token");
    }
  }, [token, navigate, Cookies]);

  useEffect(() => {
    setFormData((pre) => ({
      ...pre,
      "billing[firstName]": profileData && profileData?.billing?.firstName,
      "billing[lastName]": profileData && profileData?.billing?.lastName,
      "billing[fiscalCode]": profileData && profileData?.billing?.fiscalCode,
      "billing[address][country]":
        profileData && profileData?.billing?.address?.country,
      "billing[address][state]":
        profileData && profileData?.billing?.address?.state,
      "billing[address][city]":
        profileData && profileData?.billing?.address?.city,
      "billing[address][zipcode]":
        profileData && profileData?.billing?.address?.zipcode,
      "billing[address][address1]":
        profileData && profileData?.billing?.address?.address1,
      "billing[taxIdentificationCode]":
        profileData && profileData?.billing?.taxIdentificationCode,
      "billing[pec]": profileData && profileData?.billing?.pec,

      "billing[businessEmail]":
        profileData && profileData?.billing?.businessEmail,
      "billing[sdi]": profileData && profileData?.billing?.sdi,
    }));
  }, [profileData]);

  const handleSubmit = () => {
    if (!formData?.["billing[firstName]"]) {
      toast.info("First name is required!");
      return;
    }
    if (!formData?.["billing[lastName]"]) {
      toast.info("Last name is required!");
      return;
    }
    if (!formData?.["billing[fiscalCode]"]) {
      toast.info("Fiscal Code is required!");
      return;
    }
    if (!formData?.["billing[address][country]"]) {
      toast.info("Country name is required!");
      return;
    }
    if (!formData?.["billing[address][state]"]) {
      toast.info("State name is required!");
      return;
    }
    if (!formData?.["billing[address][city]"]) {
      toast.info("City name is required!");
      return;
    }
    if (!formData?.["billing[address][zipcode]"]) {
      toast.info("ZIP code is required!");
      return;
    }
    if (!formData?.["billing[address][address1]"]) {
      toast.info("Address is required!");
      return;
    }
    if (!formData?.["billing[taxIdentificationCode]"]) {
      toast.info("Tax identification code is required!");
      return;
    }
    if (!formData?.["billing[pec]"]) {
      toast.info("PEC is required!");
      return;
    }
    if (!formData?.["billing[businessEmail]"]) {
      toast.info("Business email is required!");
      return;
    }
    if (!emailRegex.test(formData?.["billing[businessEmail]"])) {
      toast.warning("Invalid business email id!");
      return;
    }
    if (!formData?.["billing[sdi]"]) {
      toast.info("SDI is required!");
      return;
    }

    setLoaded(false);
    updateProfileService({
      ...formData,
      roleCode: role,
      id: id,
    }).then((res) => {
      setLoaded(true);
      if (res.data.status == 200) {
        toast.success(res?.data?.message);
        dispatch(setProfileData(res.data.data));
      }
    });
  };

  const countryAllData =
    countryList.length > 0 &&
    countryList.map((elm) => ({
      value: elm.name,
      name: elm.name,
    }));
  useEffect(() => {
    fetchCountryList(setCountryList, setCountryLoaded);
  }, []);

  const handleFormData = (e) => {
    setFormData((pre) => ({
      ...pre,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <div className="relative">
      <div className="relative w-full border-t border-dashed border-neutral-300 py-4">
        {BillingInfo?.title && (
          <h2
            className="text-base text-prontopsy-blue font-RobotoSlab font-bold"
            dangerouslySetInnerHTML={{ __html: BillingInfo?.title?.[language] }}
          ></h2>
        )}
      </div>
      <div className="flex  justify-between gap-5 py-10 border-t border-neutral-300">
        <div className="w-full">
          <div className="grid grid-cols-12 gap-3">
            <div className="md:col-span-4 col-span-12">
              <div className="relative">
                <InputGroup
                  label={`*${commonFormLabels?.firstName?.[language]} :`}
                  inputPlaceholder={commonFormLabels?.firstName?.[language]}
                  inputValue={formData?.["billing[firstName]"]}
                  inputName={"billing[firstName]"}
                  onChange={handleFormData}
                  labelClasses={
                    "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab"
                  }
                  inputClasses={"!bg-transparent !h-12"}
                  loaded={dataLoaded}
                />
              </div>
            </div>
            <div className="md:col-span-4 col-span-12">
              <div className="relative">
                <InputGroup
                  label={`*${commonFormLabels?.lastName?.[language]} :`}
                  inputPlaceholder={commonFormLabels?.lastName?.[language]}
                  inputValue={formData?.["billing[lastName]"]}
                  inputName={"billing[lastName]"}
                  onChange={handleFormData}
                  labelClasses={
                    "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab"
                  }
                  inputClasses={"!bg-transparent !h-12"}
                  loaded={dataLoaded}
                />
              </div>
            </div>
            <div className="md:col-span-4 col-span-12">
              <div className="relative">
                <InputGroup
                  label={`*${commonFormLabels?.fiscalCode?.[language]} :`}
                  inputPlaceholder={commonFormLabels?.fiscalCode?.[language]}
                  inputValue={formData?.["billing[fiscalCode]"]}
                  inputName={"billing[fiscalCode]"}
                  onChange={handleFormData}
                  labelClasses={
                    "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab"
                  }
                  inputClasses={"!bg-transparent !h-12"}
                  loaded={dataLoaded}
                />
              </div>
            </div>
            <div className="md:col-span-4 col-span-12">
              <div className="relative">
                <Select
                  label={`*${commonFormLabels?.country?.[language]} :`}
                  selectedValue={{
                    name: formData?.["billing[address][country]"],
                    value: formData?.["billing[address][country]"],
                  }}
                  placeholder={"Select a Country"}
                  labelClasses={
                    "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab"
                  }
                  dropdownButtonClass={"!h-12 !bg-transparent"}
                  isSearch={true}
                  dropdownData={countryAllData}
                  dropdownClass={"w-full"}
                  loaded={dataLoaded}
                  handleSelect={(val) =>
                    handleFormData({
                      target: {
                        value: val?.value,
                        name: "billing[address][country]",
                      },
                    })
                  }
                />
              </div>
            </div>
            <div className="md:col-span-4 col-span-12">
              <div className="relative">
                <InputGroup
                  label={`*${commonFormLabels?.state?.[language]} :`}
                  inputPlaceholder={commonFormLabels?.state?.[language]}
                  inputValue={formData?.["billing[address][state]"]}
                  inputName={"billing[address][state]"}
                  onChange={handleFormData}
                  labelClasses={
                    "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab"
                  }
                  inputClasses={"!bg-transparent !h-12"}
                  loaded={dataLoaded}
                />
              </div>
            </div>
            <div className="md:col-span-4 col-span-12">
              <div className="relative">
                <InputGroup
                  label={`*${commonFormLabels?.city?.[language]} :`}
                  inputPlaceholder={commonFormLabels?.city?.[language]}
                  inputValue={formData?.["billing[address][city]"]}
                  inputName={"billing[address][city]"}
                  onChange={handleFormData}
                  labelClasses={
                    "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab"
                  }
                  inputClasses={"!bg-transparent !h-12"}
                  loaded={dataLoaded}
                />
              </div>
            </div>
            <div className="md:col-span-4 col-span-12">
              <div className="relative">
                <InputGroup
                  label={`*${commonFormLabels?.code?.[language]} :`}
                  inputPlaceholder={commonFormLabels?.code?.[language]}
                  inputValue={formData?.["billing[address][zipcode]"]}
                  inputName={"billing[address][zipcode]"}
                  onChange={handleFormData}
                  labelClasses={
                    "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab"
                  }
                  inputClasses={"!bg-transparent !h-12"}
                  loaded={dataLoaded}
                />
              </div>
            </div>
            <div className="md:col-span-4 col-span-12">
              <div className="relative">
                <InputGroup
                  label={`*${commonFormLabels?.address?.[language]} :`}
                  inputPlaceholder={commonFormLabels?.address?.[language]}
                  inputValue={formData?.["billing[address][address1]"]}
                  inputName={"billing[address][address1]"}
                  onChange={handleFormData}
                  labelClasses={
                    "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab"
                  }
                  inputClasses={"!bg-transparent !h-12"}
                  loaded={dataLoaded}
                />
              </div>
            </div>
            <div className="md:col-span-4 col-span-12">
              <div className="relative">
                <InputGroup
                  label={`*${commonFormLabels?.taxIdentificationCode?.[language]} :`}
                  inputPlaceholder={
                    commonFormLabels?.taxIdentificationCode?.[language]
                  }
                  inputValue={formData?.["billing[taxIdentificationCode]"]}
                  inputName={"billing[taxIdentificationCode]"}
                  onChange={handleFormData}
                  labelClasses={
                    "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab"
                  }
                  inputClasses={"!bg-transparent !h-12"}
                  loaded={dataLoaded}
                />
              </div>
            </div>
            <div className="md:col-span-4 col-span-12">
              <div className="relative">
                <InputGroup
                  label={`*${commonFormLabels?.pec?.[language]} :`}
                  inputPlaceholder={commonFormLabels?.pec?.[language]}
                  inputValue={formData?.["billing[pec]"]}
                  inputName={"billing[pec]"}
                  onChange={handleFormData}
                  labelClasses={
                    "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab"
                  }
                  inputClasses={"!bg-transparent !h-12"}
                  loaded={dataLoaded}
                />
              </div>
            </div>
            <div className="md:col-span-4 col-span-12">
              <div className="relative">
                <InputGroup
                  label={`*${commonFormLabels?.businessEmail?.[language]} :`}
                  inputPlaceholder={commonFormLabels?.businessEmail?.[language]}
                  inputValue={formData?.["billing[businessEmail]"]}
                  inputName={"billing[businessEmail]"}
                  onChange={handleFormData}
                  labelClasses={
                    "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab"
                  }
                  inputClasses={"!bg-transparent !h-12"}
                  loaded={dataLoaded}
                />
              </div>
            </div>
            <div className="md:col-span-4 col-span-12">
              <div className="relative">
                <InputGroup
                  label={`*SDI :`}
                  inputPlaceholder={"SDI"}
                  inputValue={formData?.["billing[sdi]"]}
                  inputName={"billing[sdi]"}
                  onChange={handleFormData}
                  labelClasses={
                    "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab"
                  }
                  inputClasses={"!bg-transparent !h-12"}
                  loaded={dataLoaded}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="!flex !justify-end">
        <Button
          buttonLabel={BillingInfo?.saveChanges?.[language]}
          buttonLabelClasses={
            "!uppercase !text-base !whitespace-nowrap !font-medium"
          }
          buttonClasses={
            "!rounded-xl !px-4 !h-12 !text-prontopsy-pink !text-white !bg-gradient-to-r !from-prontopsy-blue !to-prontopsy-pink !hover:bg-prontopsy-pink !duration-300 "
          }
          buttonFunction={handleSubmit}
          isDisable={!loaded}
        />
      </div>
    </div>
  );
};

export default BillingInfo;
