import React, { useEffect, useState } from "react";
import User from "../../../../../assets/images/user-03.webp";
import Button from "../../../../form/Button";
import InputGroup from "../../../../form/Input";
import Checkbox from "../../../../form/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { commonFormLabels, profile } from "../../../language";
import dayjs from "dayjs";
import { updateProfileService } from "../../../../../service/profileService";
import { setProfileData } from "../../../../../store/slice/profileSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import InputDate from "../../../../form/InputDate";

const InsuranceInfo = ({ data, dataLoaded }) => {
  const lang = useSelector((state) => state?.languageSlice?.data);
  const language = localStorage.getItem("language") || lang;
  const profileData = useSelector((state) => state?.profileSlice?.data);
  const role = JSON.parse(localStorage.getItem("userDetails"))?.roleCode;
  const id = JSON.parse(localStorage.getItem("userDetails"))?._id;
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(true);
  const [formData, setFormData] = useState({});
  const token = localStorage.getItem("auth_token") || Cookies.get("auth_token");
  const navigate = useNavigate();
  const InsuranceInfo = {
    title: profile?.insuranceInformation?.[language],
    titleAlt: "Change Password",
    image: User,
  };
  const checkBoxTitle = {
    en: "I do not have insurance",
    es: "No tengo seguro",
    it: "Non sono sicuro",
  };

  useEffect(() => {
    if (!token) {
      navigate("/");
      localStorage.clear();
      Cookies.remove("auth_token");
    }
  }, [token, navigate, Cookies]);

  useEffect(() => {
    setFormData((pre) => ({
      ...pre,
      "insurance[companyName]":
        profileData && profileData?.insurance?.companyName,
      "insurance[expirationDate]":
        profileData &&
        profileData?.insurance?.expirationDate &&
        dayjs(profileData?.insurance?.expirationDate).format("YYYY-MM-DD"),
      "insurance[number]": profileData && profileData?.insurance?.number,
      "insurance[hasInsurance]":
        profileData && profileData?.insurance?.hasInsurance,
    }));
  }, [profileData]);

  const handleFormData = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = () => {
    if (
      formData?.["insurance[hasInsurance]"] === undefined ||
      formData?.["insurance[hasInsurance]"] == false
    ) {
      if (!formData?.["insurance[companyName]"]) {
        toast.info("Company name is required!");
        return;
      }
      if (!formData?.["insurance[expirationDate]"]) {
        toast.info("Expiration date is required!");
        return;
      }
      if (!formData?.["insurance[expirationDate]"]) {
        toast.warning("Enter a valid expiration date!");
        return;
      }
      if (!formData?.["insurance[number]"]) {
        toast.info("Insurance number is required!");
        return;
      }
    } else {
      delete formData?.["insurance[companyName]"];
      delete formData?.["insurance[expirationDate]"];
      delete formData?.["insurance[expirationDate]"];
      delete formData?.["insurance[number]"];
    }

    setLoaded(false);
    updateProfileService({
      ...formData,
      roleCode: role,
      id: id,
    }).then((res) => {
      setLoaded(true);
      if (res.data.status == 200) {
        toast.success(res?.data?.message);
        dispatch(setProfileData(res.data.data));
      }
    });
  };

  return (
    <div className="relative">
      <div className="relative w-full border-t border-dashed border-neutral-300 py-4">
        {InsuranceInfo?.title && (
          <h2
            className="text-base text-prontopsy-blue font-RobotoSlab font-bold"
            dangerouslySetInnerHTML={{ __html: InsuranceInfo?.title }}
          ></h2>
        )}
      </div>

      <div className="flex  justify-between gap-5 pt-5 pb-6 border-t border-neutral-300">
        <div className="w-full">
          <div className="grid grid-cols-12 gap-3">
            <div className="md:col-span-4  col-span-12">
              <div className="relative">
                <InputGroup
                  label={`*${commonFormLabels?.insuranceCompaneyName?.[language]} :`}
                  inputName={"insurance[companyName]"}
                  inputValue={formData?.["insurance[companyName]"]}
                  onChange={handleFormData}
                  labelClasses={
                    "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab"
                  }
                  inputClasses={"!bg-transparent !h-12"}
                  inputPlaceholder={"Company Name"}
                />
              </div>
              <div className="relative mt-2">
                <Checkbox
                  checkboxName={"insurance[hasInsurance]"}
                  checkboxLabel={checkBoxTitle?.[language]}
                  onChange={(e) => handleFormData(e)}
                  isChecked={formData?.["insurance[hasInsurance]"]}
                />
              </div>
            </div>
            <div className="md:col-span-4 col-span-12">
              <div className="relative">
                <InputGroup
                  label={`*${commonFormLabels?.insuranceNumber?.[language]} :`}
                  inputName={"insurance[number]"}
                  inputValue={formData?.["insurance[number]"]}
                  onChange={handleFormData}
                  labelClasses={
                    "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab"
                  }
                  inputClasses={"!bg-transparent !h-12"}
                  inputPlaceholder={"Insurance Number"}
                />
              </div>
            </div>
            <div className="md:col-span-4 col-span-12">
              <div className="relative">
                <InputDate
                  label={`*${commonFormLabels?.insuranceExpirationDate?.[language]}} :`}
                  inputPlaceholder={"MM/DD/YYYY"}
                  inputName={"insurance[expirationDate]"}
                  // loaded={dataLoaded}
                  onChange={(value) => {
                    setFormData((pre) => ({
                      ...pre,
                      "insurance[expirationDate]": dayjs(
                        new Date(value)
                      ).format("YYYY/MM/DD"),
                    }));
                  }}
                  value={formData?.["insurance[expirationDate]"]}
                  labelClasses={
                    "!uppercase !text-[#002C6BC9] !font-bold !text-[10px] font-RobotoSlab"
                  }
                  inputClasses={"!bg-transparent !h-12"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="relative flex items-center gap-2">
        <Button
          buttonLabel={"Save Changes"}
          buttonLabelClasses={
            "!uppercase !text-base !whitespace-nowrap !font-medium"
          }
          buttonClasses={
            "!rounded-xl !px-4 !h-12 !text-prontopsy-pink !text-white !bg-gradient-to-r !from-prontopsy-blue !to-prontopsy-pink !hover:bg-prontopsy-pink !duration-300"
          }
          buttonFunction={handleSubmit}
          isDisable={!loaded}
        />
      </div>
    </div>
  );
};

export default InsuranceInfo;
