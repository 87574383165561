import React, { useState, useEffect } from "react";
import dayjs from "dayjs";

// Function to calculate remaining time
const calculateRemainingTime = (targetTime) => {
  const now = new Date().getTime();
  const target = new Date(targetTime).getTime();
  const difference = target - now;

  if (difference <= 0) {
    return { isNear: false, timeLeft: "00:00" };
  } else if (difference <= 600000) {
    // 10 minutes = 600000ms
    const minutes = Math.floor(difference / 60000);
    const seconds = Math.floor((difference % 60000) / 1000);
    const formattedTime = `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
    return { isNear: true, timeLeft: formattedTime };
  } else {
    return { isNear: false, timeLeft: "00:00" };
  }
};

const TimeCountdown = ({ targetDate }) => {
  const [timeLeft, setTimeLeft] = useState("");
  const [isNear, setIsNear] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      const { isNear, timeLeft } = calculateRemainingTime(targetDate);
      setIsNear(isNear);
      setTimeLeft(timeLeft);
    }, 1000);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [targetDate]);

  // Convert targetDate to local time using dayjs
  const localTargetDate = dayjs(targetDate).format("MMMM D, YYYY h:mm A");

  return (
    <div className="bg-prontopsy-red">
      <p className="text-center animate-bounce text-white fontsemi">
        {isNear
          ? `Time is running out! You have a new appointment at ${localTargetDate}.`
          : ""}
      </p>
      <p className="text-center">{isNear ? `Time left: ${timeLeft}` : ""}</p>
    </div>
  );
};

export default TimeCountdown;
