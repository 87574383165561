import React, { useState, useEffect, useContext } from "react";
import Image from "../../elements/Image";
import Loginimage from "../../../assets/images/loginimage.webp";
import InputGroup from "../../form/Input";
import Checkbox from "../../form/Checkbox";
import Button from "../../form/Button";
import {
  getProfileDetails,
  loginRequest,
  resetPasswordService,
  sendOtpService,
  verifyOtpService,
} from "../../../service/authService";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import EmailModal from "../signup/EmailModal";
import OtpModal from "../signup/OtpModal";
import ResetPasswordModal from "../signup/ResetPasswordModal";
import { useDispatch, useSelector } from "react-redux";
import { setAuthData } from "../../../store/slice/authSlice";
import { setInitialProfileData } from "../../../store/slice/initialProfileSlice";
import SocketContext, { useSocket } from "../../socket/SocketProvider";
import { setTokenData } from "../../../store/slice/tokenSlice";
import GoogleLoginComponent from "../../elements/GoogleLoginComponent";
import FacebookLoginComponent from "../../elements/FacebookLoginComponent";
import Cookies from "js-cookie";
import SpinLoader from "../../elements/table/SpinLoader";

const langConfig = {
  dont: {
    en: "Dont have an account?",
    es: "¿No tienes una cuenta?",
    it: "Non hai un account?",
  },
  facebook: {
    en: "Continue with facebook",
    es: "Continúa con facebook",
    it: "Continua con facebook",
  },
  google: {
    en: "Continue with google",
    es: "Continúa con google",
    it: "Continua con google",
  },
  signup: {
    en: "Signup now",
    es: "registrarse ahora",
    it: "Iscriviti ora",
  },
  forgot: {
    en: "Forgot password?",
    es: "¿Has olvidado tu contraseña?",
    it: "Ha dimenticato la password?",
  },
  remember: {
    en: "Remember me",
    es: "Acuérdate de mí",
    it: "Ricordati di me",
  },
};

const Login = () => {
  const { connectSocket } = useContext(SocketContext);
  const [loaded, setLoaded] = useState(true);
  const [isClicked, setIsClicked] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [clicked, setClicked] = useState(false);
  const [timeZoneData, setTimeZoneData] = useState({
    zoneName: "",
    gmtOffsetName: "",
  });
  const [signinData, setSigninData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
  const [emailLoader, setEmailLoader] = useState(true);
  const [otp, setOtp] = useState("");
  const [emailForOtp, setEmailForOtp] = useState("");
  const [verifyOtpLoaded, setVerifyOtpLoaded] = useState(true);
  const [isResetModal, setIsResetModal] = useState(false);
  const [passwordInfo, setPasswordInfo] = useState({});
  const [resetPasswordLoaded, setResetPasswordLoaded] = useState(true);
  const [detailsData, setDetailsData] = useState({});
  const socket = useSocket();
  const lang = useSelector((state) => state?.languageSlice?.data);
  const language = localStorage.getItem("language") || lang;
  const authToken = localStorage.getItem("auth_token");

  useEffect(() => {
    const timeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const currentDate = new Date();

    const offsetMinutes = currentDate.getTimezoneOffset();

    const offsetHours = Math.floor(Math.abs(offsetMinutes) / 60);
    const offsetMinutesRemaining = Math.abs(offsetMinutes) % 60;

    const gmtOffsetName = `UTC${offsetMinutes <= 0 ? "+" : "-"}${String(
      offsetHours
    ).padStart(2, "0")}:${String(offsetMinutesRemaining).padStart(2, "0")}`;

    setTimeZoneData({
      zoneName: timeZoneName,
      gmtOffsetName: gmtOffsetName,
    });
  }, []);

  useEffect(() => {
    setSigninData((pre) => ({
      ...pre,
      timeZone: timeZoneData,
    }));
  }, [timeZoneData]);

  const dispatch = useDispatch();
  const toggleModal = () => {
    setIsModalOpen((prevState) => !prevState);
  };

  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  let validate = isValidEmail(signinData?.email);

  const handleData = (e) => {
    setSigninData((pre) => ({
      ...pre,
      [e.target.name]: e.target.value,
    }));
    setClicked(false);
  };

  const handleLogin = () => {
    setClicked(true);
    signinData.email &&
      signinData.password &&
      validate == true &&
      timeZoneData &&
      // loginRequest({ ...signinData }, setLoaded).then((res) => {
      loginRequest({ ...signinData }, setLoaded).then((res) => {
        setClicked(false);
        if (res.data?.status == 200) {
          setDetailsData(res?.data?.userData);
          dispatch(setAuthData(res?.data));
          dispatch(setTokenData(res?.data?.accessToken));
          localStorage.setItem("auth_token", res?.data?.accessToken);
          localStorage.setItem("refreshToken", res?.data?.refreshToken);

          Cookies.set("auth_token", res?.data?.accessToken);

          if (!socket || !socket?.connected) {
            connectSocket();
          }
          toast.success(res?.data?.message, { autoClose: 4000 });
          socket && socket.emit("userOnline", res?.data?.userData?._id);
          getProfileDetails(res?.data?.accessToken).then((response) => {
            if (response?.data?.status === 200) {
              localStorage.setItem(
                "userDetails",
                JSON.stringify(response?.data?.docs)
              );
              dispatch(setInitialProfileData(response?.data?.docs));
              navigate("/profile");
            }
          });
          setEmail("");
          setPassword("");
        } else {
          toast.error(res.data.message, { autoClose: 5000 });
        }
      });
  };

  useEffect(() => {
    if (detailsData?._id) {
      socket && socket.emit("userOnline", detailsData?._id);
    }
  }, [detailsData, socket]);

  const loginData = {
    image: Loginimage,
    title: "login",
  };

  const handleGoBack = () => {
    setIsModalOpen(true);
    setIsOtpModalOpen(false);
    setIsClicked(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  return (
    <>
      {loaded ? (
        <>
          <div className="relative py-10 md:py-12 lg:py-16 xl:py-20 ">
            <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto">
              <div className="block md:flex shadow-2xl">
                <div className="w-full md:w-1/2 p-10 hidden md:block">
                  <div className="flex flex-col justify-center w-full aspect-[4/5] overflow-hidden">
                    <Image
                      src={loginData?.image}
                      alt={""}
                      effect={"blur"}
                      className={"object-cover w-full h-full"}
                    />
                  </div>
                </div>
                <div className="w-full md:w-1/2">
                  <div className="bg-prontopsy-yellow px-5 py-5 h-full flex flex-col items-center justify-center">
                    <div
                      className="w-full mx-auto max-w-lg text-center space-y-4"
                      onKeyDown={handleKeyDown}
                    >
                      {loginData?.title && (
                        <h2
                          className="!mb-6 sm:!mb-8 md:!mb-10 lg:!mb-12 xl:!mb-16 text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl text-prontopsy-blue font-UnisonPro italic font-bold !leading-[1.1]"
                          dangerouslySetInnerHTML={{ __html: loginData?.title }}
                        ></h2>
                      )}
                      <div className="relative">
                        <InputGroup
                          onChange={handleData}
                          inputName={"email"}
                          labelClasses={"font-RobotoSlab"}
                          inputPlaceholder={"Email"}
                          inputType={"email"}
                          inputClasses={
                            "!text-black border-none placeholder:text-black h-10 md:h-12 lg:h-14 xl:h-16 rounded-none !bg-black/20"
                          }
                          inputValue={signinData.email}
                          errorType={
                            clicked == true &&
                            (!signinData.email
                              ? "warning"
                              : isValidEmail(signinData.email)
                              ? ""
                              : "danger")
                          }
                          errorText={
                            signinData.email && isValidEmail(signinData.email)
                              ? ""
                              : "Enter a valid email id!"
                          }
                        />
                      </div>
                      <div className="relative">
                        <InputGroup
                          onChange={handleData}
                          labelClasses={"font-RobotoSlab"}
                          inputPlaceholder={"Password"}
                          inputType={"password"}
                          inputName={"password"}
                          inputValue={signinData?.password}
                          divClass={"!border !border-black !bg-transparent"}
                          inputClasses={
                            " !text-black border-none placeholder:text-black h-10 md:h-12 lg:h-14 xl:h-16 rounded-none !bg-black/20"
                          }
                          errorType={
                            clicked == true && !signinData.password && "warning"
                          }
                          errorText={
                            signinData.password ? "" : "Enter correct password!"
                          }
                        />
                      </div>

                      <div className="flex items-center justify-between">
                        <div className="relative">
                          <Checkbox
                            checkboxLabel={`${langConfig?.remember?.[language]}`}
                            labelClasses={"font-RobotoSlab"}
                            checkboxLabelClass={
                              "!text-black !text-xs !capitalize"
                            }
                          />
                        </div>
                        <div className="relative">
                          <Link
                            onClick={toggleModal}
                            className="text-xs text-black font-RobotoSlab capitalize"
                          >
                            {langConfig?.forgot?.[language]}
                          </Link>
                        </div>
                      </div>
                      <div className="flex justify-center">
                        <div className="relative">
                          <Button
                            buttonFunction={handleLogin}
                            buttonLabel={"login"}
                            buttonLabelClasses={"!capitalize font-RobotoSlab"}
                            buttonClasses={
                              "!h-10 lg:!h-12 !bg-prontopsy-blue !px-16"
                            }
                            isDisable={!loaded}
                          />
                        </div>
                      </div>

                      {/*------------------ Social login---------------  */}
                      <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 py-5 border-t border-b border-neutral-400">
                        <div className="relative w-full flex justify-center">
                          <FacebookLoginComponent />
                        </div>
                        <div className="relative w-full flex justify-center">
                          <GoogleLoginComponent role="user" />
                        </div>
                      </div>
                      <div className="flex justify-center">
                        <div className="text-xs text-black font-RobotoSlab capitalize">
                          {langConfig?.dont?.[language]}
                          <Link
                            to="/signup"
                            className="text-xs text-black font-RobotoSlab capitalize underline"
                          >
                            {langConfig?.signup?.[language]}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <EmailModal
            isOpen={isModalOpen}
            onClose={() => {
              setIsModalOpen(false);
              setIsClicked(false);
            }}
            isValidEmail={isValidEmail}
            onSubmit={(data) => {
              setIsClicked(true);
              if ((emailForOtp, isValidEmail(emailForOtp))) {
                sendOtpService({ email: emailForOtp }, setEmailLoader).then(
                  (res) => {
                    if (res.data.status == 200) {
                      toast.success(res.data?.message, { autoClose: 4000 });
                      setIsModalOpen(false);
                      setIsOtpModalOpen(true);
                      setIsClicked(false);
                    } else {
                      toast.error(res?.data?.message, { autoClose: 4000 });
                    }
                  }
                );
              }
            }}
            email={emailForOtp}
            setEmail={setEmailForOtp}
            loaded={emailLoader}
            isClicked={isClicked}
            setIsClicked={setIsClicked}
          />
          <OtpModal
            isOpen={isOtpModalOpen}
            handleGoBack={handleGoBack}
            onClose={() => {
              setIsOtpModalOpen(false);
              setIsClicked(false);
            }}
            onSubmit={() => {
              if (otp && emailForOtp) {
                verifyOtpService(
                  { emailOtp: otp, requestType: "forgot", email: emailForOtp },
                  setVerifyOtpLoaded
                ).then((res) => {
                  if (res.data.status === 200) {
                    toast.success(res.data.message, { autoClose: 4000 });
                    setIsResetModal(true);
                    setIsClicked(false);
                  } else {
                    toast.error(res.data?.message, { autoClose: 4000 });
                  }
                });
              } else if (!otp) {
                toast.warning("OTP is required!", { autoClose: 4000 });
              } else if (!emailForOtp) {
                toast.warning("Email is missing!", { autoClose: 4000 });
              }
            }}
            otp={otp}
            setOtp={setOtp}
            loaded={verifyOtpLoaded}
          />
          <ResetPasswordModal
            isOpen={isResetModal}
            onClose={() => {
              setIsResetModal(false);
              setIsClicked(false);
            }}
            handleGoBack={() => {
              setIsResetModal(false);
              setIsOtpModalOpen(true);
              setIsClicked(false);
            }}
            signupData={passwordInfo}
            setSignupData={setPasswordInfo}
            onSubmit={() => {
              setIsClicked(true);
              if (passwordInfo.newPassword && passwordInfo.confirmPassword) {
                resetPasswordService(
                  { ...passwordInfo, email: emailForOtp, otp: otp },
                  setResetPasswordLoaded
                ).then((res) => {
                  if (res.data.status === 200) {
                    toast.success(res.data.message, { autoClose: 4000 });
                    setIsResetModal(false);
                    setIsOtpModalOpen(false);
                    setIsClicked(false);
                    setPasswordInfo({});
                    setOtp("");
                    setEmailForOtp("");
                  } else {
                    toast.error(res.data.message, { autoClose: 4000 });
                  }
                });
              }
            }}
            isClicked={isClicked}
            setIsClicked={setIsClicked}
            loaded={resetPasswordLoaded}
          />
        </>
      ) : (
        <>
          <SpinLoader />
        </>
      )}
    </>
  );
};

export default Login;
